import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { InvoiceService } from '../../../shared/services/invoice.service';
import { DataService } from '../../../shared/services/data.service';
import { TutorialService } from '../../../tutorial/tutorial.service';

@Component({
  selector: 'submit-hours',
  templateUrl: './submit-hours.component.html',
  styleUrls: ['./submit-hours.component.scss']
})
export class SubmitHoursComponent implements OnInit, OnDestroy {
  allocationsList;
  dataService;
  invoiceService;
  private readonly PER_PAGE = 10;
  page = 1;
  showInvoiceNumberPopup = false;
  invoiceNumber = '';
  showInvoiceSubmitBtn = false;
  toasterData: any = {};
  showToaster = false;

  constructor(invoiceService: InvoiceService, dataService: DataService, private tutorialService: TutorialService) {
    this.dataService = dataService;
    this.invoiceService = invoiceService;
  }

  ngOnInit() {
    this.getAllocationsList();
  }

 ngOnDestroy() {
  this.invoiceService.showSubmitHours = false;
  this.invoiceService.initializeSubmitHours = false;
 }

  closeToaster(){
    this.showToaster = false;
  }

  closeSubmitHours(): void {
    this.invoiceService.showSubmitHours = false;
    setTimeout(() => {
      this.invoiceService.initializeSubmitHours = false;
    }, 300);
      this.invoiceService.getInvoices(this.page, this.PER_PAGE)
        .subscribe(res => {
            this.invoiceService.submitButtonData = res.meta;
        }, err => {
          console.log(err);
        });
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const keyPressed = event.which || event.keyCode;
    if (keyPressed === 27) {
      this.closeSubmitHours();
    }
  }

  getAllocationsList() {
    this.dataService.showHideLoader(true);
    this.invoiceService.getAllocations()
      .subscribe(res => {
        this.allocationsList = res;
        if (!(this.allocationsList.meta.current_invoice && this.allocationsList.meta.current_invoice.invoice_number)) {
          this.showInvoiceNumberPopup = true;
        } else {
          this.invoiceNumber = this.allocationsList.meta.current_invoice.invoice_number;
          this.showInvoiceSubmitBtn = false;
        }
        let resData = res.data;
        for (let i = 0; i < resData.length; i++) {
          if (resData[i].activity) {
            resData[i].hasActivity = true;
          } else {
            resData[i].hasActivity = false;
          }
          resData[i].requestOngoing = false;
          resData[i].timeValidation = {
            hours: false,
            minutes: false
          };
        }
        this.dataService.showHideLoader(false);
      }, err => {
        console.log(err);
        this.dataService.showHideLoader(false);
      });
  }

  onInvoiceNumberFocus(): void {
    this.showInvoiceSubmitBtn = true;
  }

  // onInvoiceNumberFocusout(): void {
  //   this.showInvoiceSubmitBtn = false;
  // }

  saveInvoiceNumber(event: any): void {
    let invoiceNumber = event.target.value;
    invoiceNumber = invoiceNumber.trim();
    if (!invoiceNumber) return;
    this.dataService.showHideLoader(true);
    this.invoiceService.postInvoiceNumber(invoiceNumber)
    .subscribe(res => {
      this.showInvoiceNumberPopup = false;
      this.dataService.showHideLoader(false);
      this.getAllocationsList();
    }, err => {
      this.dataService.showHideLoader(false);
    });
  }

  updateInvoiceNumber(): void {
    this.invoiceNumber = this.invoiceNumber.trim();
    if (!this.invoiceNumber) return;
    this.dataService.showHideLoader(true);
    this.invoiceService.putInvoiceNumber(this.invoiceNumber, this.allocationsList.meta.current_invoice.id)
    .subscribe(res => {
      this.showInvoiceSubmitBtn = false;
      this.dataService.showHideLoader(false);
    }, err => {
      this.dataService.showHideLoader(false);
    });
  }

  editActivity(event) {
    event.hasActivity = false;
  }

  vendorActivityTimeHr(d) {
    let h = Math.floor(Number(d) / 3600);
    let finalTime;
    if (h < 1) {
      finalTime = 0;
    } else {
      finalTime = h;
    }
    return finalTime;
  }
  vendorActivityTimeMin(d) {
    return Math.floor(Number(d) % 3600 / 60);
  }

  hoursChange(hoursValue, minutsValue, data){
    var vendorTotalSeconds = (hoursValue * 3600) + (minutsValue * 60);
    if(vendorTotalSeconds >= 0){
      data.timeValidation.hours = false;
      if(minutsValue <= 59 && minutsValue >=0){
        data.timeValidation.minutes = false;
      }
    }else{
      data.timeValidation.hours = true;
      data.timeValidation.minutes = true;
    }
  }
  minutsChange(minutsValue, hoursValue, data){

    var vendorTotalSeconds = (hoursValue * 3600) + (minutsValue * 60);
    if(vendorTotalSeconds >= 0 && minutsValue >=0){
      data.timeValidation.hours = false;
      if(minutsValue <= 59){
        data.timeValidation.minutes = false;
      }else{
        data.timeValidation.minutes = true;
      }
    }else{
      data.timeValidation.hours = true;
      data.timeValidation.minutes = true;
    }
  }

  updateToaster(status, toastMsg){
    this.showToaster = true;
    this.toasterData.status = status;
    this.toasterData.text = toastMsg;
    // setTimeout(() => {
    //   this.toasterData.showToastMsg = false;
    // }, 3000);
  }

  vendorActivityTimeSubmit(data, activityHours, activityMinuts) {
    var vendorTotalSeconds = (activityHours * 3600) + (activityMinuts * 60);
    if(vendorTotalSeconds < 0 || activityHours == '' || activityMinuts == '' || activityMinuts < 0){
      data.timeValidation.hours = true;
      data.timeValidation.minutes = true;
      return;
    }
    if (vendorTotalSeconds < 0 || activityMinuts > 59) {
      return;
    }
    data.requestOngoing = true;
    if (data.activity) {
      this.invoiceService.updateAllocationActivity(data.id, vendorTotalSeconds.toString(), data.activity.id)
        .subscribe(res => {
          data.requestOngoing = false;
          data.hasActivity = true;
          this.updateToaster('success', res.message);
        }, err => {
          data.requestOngoing = false;
          this.updateToaster('error', err.error.message);
        });
    } else {
      this.invoiceService.createAllocationActivity(data.id, vendorTotalSeconds)
        .subscribe(res => {
          data.requestOngoing = false;
          data.hasActivity = true;
          data.activity.id = res.data.id;
          this.updateToaster('success', res.message);
        }, err => {
          data.requestOngoing = false;
          this.updateToaster('error', err.error.message);
        });
    }
  }

  updateTutorials(tutorialScreen: string): void {
    const user = this.dataService.getUser();
    if (user.tutorials.indexOf(tutorialScreen) >= 0) return;
    user.tutorials.push(tutorialScreen);
    this.tutorialService.gotIt(user.tutorials)
    .subscribe(res => {
        this.dataService.setUser(res.data);
    }, err => {
        console.log(err);
    });
  }


}
