import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../shared/services/data.service';

@Component({
    selector: 'confirming-email',
    templateUrl: 'confirming-email.component.html',
    styleUrls: ['confirming-email.component.scss']
})

export class ConfirmingEmailComponent implements OnInit {
    showLoader = false;
    popupType = 'checkEmailPopup';
    dataService;
    
    constructor(
        dataService: DataService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.dataService = dataService;
    }

    ngOnInit(): void {
        if (this.dataService.emailConfirmationToken) {
            this.confirmEmail();
        }
    }

    confirmEmail(): void {
        this.showLoader = true;
        this.dataService.confirmEmail(this.dataService.emailConfirmationToken)
        .subscribe(res => {
            this.showLoader = false;
            this.router.navigate(['login']);
            this.dataService.showSuccessfulRegistrationMessage = true;
            this.dataService.successfulRegistrationMessage = 'Your Account is now verified!';
            setTimeout(x => {
                this.dataService.showSuccessfulRegistrationMessage = false;
            }, 4000);
        }, error => {
            this.showLoader = false;
            if (error.error.errors && error.error.errors.confirmation_token) {
                this.popupType = 'linkExpiredPopup';
            } else if (error.error.errors && error.error.errors.email) {
                this.router.navigate(['login']);
                this.dataService.showSuccessfulRegistrationMessage = true;
                this.dataService.successfulRegistrationMessage = 'Your Account is already verified!';
                setTimeout(x => {
                    this.dataService.showSuccessfulRegistrationMessage = false;
                }, 4000);
            }
            if(error.status === 503 || error.status === 502 || error.status === 500){
                this.dataService.sharedServiceType = 'generalError';
                this.dataService.areServicesRunning = false;
                setTimeout(x => {
                    this.dataService.areServicesRunning = true;
                }, 10000);
            }
        });
    }

    onSignUpWithEmail(): void {
        this.router.navigate(['register']);
    }

    resendLink(): void {
        // if (!this.signUpEmail) return;
        // this.showLoader = true;
        // this.dataService.resendConfirmationEmail(this.signUpEmail)
        // .subscribe(res => {
        //     this.showLoader = false;
        //     this.popupType = 'checkEmailPopup';
        // }, err => {
        //     this.showLoader = false;
        // });
        this.router.navigate(['/resend-confirmation-email']);
    }

}
