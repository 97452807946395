import { Component, OnInit, HostListener  } from '@angular/core';
import { Router } from "@angular/router";
import { Location } from '@angular/common';

import { DataService } from '../shared/services/data.service';
import { HttpService } from '../shared/services/http.service';

@Component({
    selector: 'nda-contract',
    templateUrl: './nda-contract.component.html',
    styleUrls: ['./nda-contract.component.scss']
})


export class NdaContractComponent implements OnInit {
    dataService: any;
    buttonDisabled:boolean = true;
    timeTaken = 0;
    timeInterval:any;
    mxp_triggered = true;
    vendorUser: any;

    constructor(dataService: DataService, private httpService: HttpService, private router: Router, private _location: Location) {
        this.dataService = dataService;
        this.vendorUser = this.dataService.getUser();
    }

    @HostListener('scroll', ['$event'])
        onScroll(event: any) {
            if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 100)) {
                if(this.mxp_triggered){
                    this.dataService.mixpanel_events_track('event', 'Contract read', {'time taken': this.timeTaken});
                    clearInterval(this.timeInterval);
                    this.mxp_triggered = false;
                }
            }
        }

    ngOnInit() {
        const vendorUser = this.dataService.getUser();
        let counter = 0;
        this.timeInterval = setInterval(() => {
            
            counter++;
            this.timeTaken = counter;
          }, 1000);
        this.dataService.mixpanel_events_track('event', 'Contract view');
        if(vendorUser && !vendorUser.new_contract){
            this.getContractPdf();
        } else if(!vendorUser){
            this.router.navigate(['login']);
        }
    }

    goToBack(){
        this._location.back();
    }

    agreeCheck(agreeCheckbox){
        if(agreeCheckbox.checked){
            this.buttonDisabled = false;
            this.dataService.mixpanel_events_track('event', 'Contract signed');
        }else{
            this.buttonDisabled = true;
        }

    }

    getContractPdf(){
        this.dataService.showHideLoader(true);
        this.dataService.getContractPdf()
        .subscribe(response => {
            this.dataService.renderPDF(response.data, document.getElementById('holder'));
        }, error => {
            console.log(error);
            this.dataService.showHideLoader(false);
        });
    }

    goToDashboard(): void {
      const vendorUser = this.dataService.getUser();
      this.dataService.showContractCopy = false;
      const API = this.dataService.getServerUrl() + this.dataService.apiEndPoints.vendorprofile;
      this.buttonDisabled = true;
      this.httpService.putRequest(API, {vendor: {
          nda_signed: true,
          present_state: 'dashboard'
        }})
        .subscribe((res: any) => {
          this.dataService.setUser(res.data);
          this.dataService.analyticsSegment.eventTrack('Signed up', {
            email: vendorUser ? vendorUser.email : '',
            name: vendorUser ? vendorUser.name : '',
            address: vendorUser ? vendorUser.address.address : '',
            userId: vendorUser ? vendorUser.id : '',
            phone: vendorUser ? vendorUser.phone : '',
            logo: vendorUser && vendorUser.logo ? vendorUser.logo.file_url : ''
          });
          this.dataService.analyticsSegment.setUserProperties({
            email: vendorUser ? vendorUser.email : '',
            name: vendorUser ? vendorUser.name : '',
            address: vendorUser ? vendorUser.address.address : '',
            userId: vendorUser ? vendorUser.id : '',
            phone: vendorUser ? vendorUser.phone : '',
            logo: vendorUser && vendorUser.logo ? vendorUser.logo.file_url : ''
          });
          this.buttonDisabled = false;
          this.dataService.isNewRateCardsReady = false;
          this.router.navigate(['vendor/dashboard']);
        });
    }

}