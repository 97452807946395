
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DataService } from './data.service';
import { HttpService } from './http.service';

@Injectable()
export class InvoiceService {
    public isShowInvoiceInfo = false;
    public showSubmitHours = false;
    public initializeSubmitHours = false;
    submitButtonData:any;
    
    constructor(
        private dataService: DataService,
        private httpService: HttpService
    ) {}

    getInvoices(page: number, perPage: number): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_invoice}?per_page=${perPage}&page=${page}`;
        return this.httpService.getRequest(url);
    }

    getInvoiceDetail(invoiceId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_invoice}/${invoiceId}`;
        return this.httpService.getRequest(url);
    }

    postQuery(invoiceId: string, query: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_invoice}/${invoiceId}/send_query`;
        return this.httpService.postRequest(url, {query: query});
    }

    postInvoice(invoiceId: string, salesTax: any, totalBeforeTax: any, invoiceNumber: any, file: File): Observable<any> {
        const url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_invoice}/${invoiceId}/upload_invoice`;
        const postJson = new FormData();
        postJson.append('invoice[sales_tax]', salesTax);
        postJson.append('invoice[total_amount]', totalBeforeTax);
        postJson.append('invoice[file]', file);
        postJson.append('invoice[invoice_number]', invoiceNumber);
        return this.httpService.postRequest(url, postJson);
    }

    getConcerns(): Observable<any> {
        let url = `${this.dataService.getServerUrl()}invoice_concerns`;
        return this.httpService.getRequest(url);
    }

    submitConcern(invoiceId: string, concern: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_invoice}/${invoiceId}`;
        const body = {
            invoice: {
                query: concern,
                status: 'pending'
            }
        };
        return this.httpService.putRequest(url, body);
    }

    approveInvoice(invoiceId: string, invoiceNumber: string): Observable<any> {
        const url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_invoice}/${invoiceId}`;
        const postJson = {
            invoice: {
                invoice_number: invoiceNumber,
                status: 'submitted'
            }
        }
        return this.httpService.putRequest(url, postJson);
    }

    getAllocations(): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_allocations}`;
        return this.httpService.getRequest(url);
    }

    createAllocationActivity(allocationId: string, vendorTotalSeconds: any): Observable<any> {
        const url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_allocations}/${allocationId}/activities`;
        const postJson = new FormData();
        postJson.append('activity[vendor_total_seconds]', vendorTotalSeconds);
        return this.httpService.postRequest(url, postJson);
    }

    updateAllocationActivity(allocationId: string, vendorTotalSeconds: string, activityId: string): Observable<any> {
        const url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_allocations}/${allocationId}/activities/${activityId}`;
        const postJson = new FormData();
        postJson.append('activity[vendor_total_seconds]', vendorTotalSeconds);
        postJson.append('activity[id]', activityId);
        return this.httpService.putRequest(url, postJson);
    }

    getSubmitHoursInfo(startDate: string, endDate: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_allocations_allowed}?start_date=${startDate}&end_date=${endDate}`;
        return this.httpService.getRequest(url);
    }

    postInvoiceNumber(invoiceNumber: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_invoice}`;
        const body = {
                invoice: {
                    invoice_number: invoiceNumber
                }
        }
        return this.httpService.postRequest(url, body);
    }

    putInvoiceNumber(invoiceNumber: string, invoiceId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_invoice}/${invoiceId}`;
        const body = {
                invoice: {
                    invoice_number: invoiceNumber
                }
        }
        return this.httpService.putRequest(url, body);
    }

}
