import { Component, ViewChild, Renderer, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PhaseService } from '../shared/services/phase.service';
import { DataService } from '../shared/services/data.service';
import { RateCardService } from "./rate-card/rate-card.service";

@Component({
    selector: 'core',
    templateUrl: 'core.component.html'
})

export class CoreComponent {
    dataService: any;
    rateCardService;
    constructor(dataService: DataService, private router: Router, private phaseService: PhaseService, rateCardService: RateCardService) {
        this.dataService = dataService;
        this.rateCardService = rateCardService;
    }
    public closeDropDown() {
        this.dataService.removeDropDown.emit();
    }
}
