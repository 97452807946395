import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../shared/services/data.service';
import { PhaseService } from '../shared/services/phase.service';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit {
    dataService;
    @ViewChild('emailEl', {static: true}) emailEl: any;
    email = '';
    password = '';
    passwordError = '';
    emailError = '';
    postApiError = '';
    inputType = 'password';
    showVerifyLink = false;
    private readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    constructor(
        dataService: DataService,
        private router: Router,
        private phaseService: PhaseService,
        private analyticsSegment: Angulartics2Segment
    ) {
        this.dataService = dataService;
        this.dataService.getUser();
    }

    ngOnInit(): void {
        window['analytics'].reset();
        this.emailEl.nativeElement.focus();
    }

    goToRegister() {
        this.router.navigate(['register']);
    }

    isEmailValid(email: string): boolean {
        return this.EMAIL_REGEX.test(email.toLowerCase());
    }

    checkForError(type: string, e: any): void {
        if (e.which === 13) return;
        if (type === 'email') {
            if (!this.email) {
                this.emailError = 'Please enter email address';
            } else if (!this.EMAIL_REGEX.test(this.email.toLowerCase())) {
                this.emailError = 'Please enter valid email address';
            } else {
                this.emailError = '';
            }
        } else if (type === 'password') {
            if (!this.password) {
                this.passwordError = 'Please enter password';
            } else if (!this.isValidPassword()) {
                this.passwordError = 'Please enter atleast 8 characters';
            } else {
                this.passwordError = '';
            }
        }
    }

    private isValidPassword(): boolean {
        return this.password.length >= 8;
    }

    login(): void {
        if (this.formHasError()) return;
        this.dataService.showHideLoader(true);
        this.postApiError = '';
        this.dataService.login(this.email, this.password)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            this.dataService.setAuthToken(res.data.authtoken);
            delete res.data.authtoken;
            this.dataService.setUser(res.data);
            if (!this.dataService.isOneSignalInitialized) {
                this.dataService.initializeOneSignalNotification();
            }
            this.setMixPanelEvents();
            this.getRateCardCount();
            const vendorUser = this.dataService.getUser();
            if (vendorUser.present_state && vendorUser.present_state === 'organisation') {
                this.router.navigate(['register/confirmed/1']);
            } else if (vendorUser.present_state && vendorUser.present_state === 'signatory_spoc') {
                this.router.navigate(['register/confirmed/2']);
            } else if (vendorUser.present_state && vendorUser.present_state === 'business_spoc') {
                this.router.navigate(['register/confirmed/3']);
            } else if (vendorUser.present_state && vendorUser.present_state === 'team_structure') {
                this.router.navigate(['register/confirmed/4']);
            } else if (vendorUser.present_state && vendorUser.present_state === 'documents') {
                this.router.navigate(['contract']);
            } else if (vendorUser.present_state && vendorUser.present_state === 'nda') {
                this.router.navigate(['contract']);
            } else if (this.dataService.afterLoginRedirectTo) {
                this.router.navigate([this.dataService.afterLoginRedirectTo]);
                this.dataService.afterLoginRedirectTo = '';
            } else {
                if (vendorUser.tutorials.indexOf("migratePopup") < 0){
                    this.dataService.isNewRateCardsReady = false;
                    this.dataService.firstMigrationPopup = true;
                } else if (vendorUser.tutorials.indexOf("migrationDone") < 0){
                    // call migrate api(if review later + have some card to be migrate pending)
                    this.dataService.showRCMigrateStrip();
                } else{
                    this.dataService.isNewRateCardsReady = false;
                }
                this.router.navigate(['vendor/dashboard']);
            }
        }, error => {
            this.dataService.showHideLoader(false);
            this.postApiError = error.error.message;
            if (this.postApiError.indexOf('confirm your account') >= 0) {
                this.showVerifyLink = true;
            } else {
                this.showVerifyLink = false;
            }
        });
    }

    setMixPanelEvents(): void {
        const vendorUser = this.dataService.getUser();
        this.dataService.getStatisticsForMixpanel()
        .subscribe(res => {
            this.analyticsSegment.eventTrack('Signed in', {
                userId: vendorUser ? vendorUser.id : '',
                login_count: vendorUser.sign_in_count,
                dev_count: res.developer_count,
                approved_skills: res.approved_skills,
                multiskill_devs: res.multiskill_devs,
                email: vendorUser ? vendorUser.email : '',
                name: vendorUser ? vendorUser.name : '',
                phone: vendorUser ? vendorUser.phone : '',
                address: vendorUser ? vendorUser.address.address : '',
                logo: vendorUser && vendorUser.logo ? vendorUser.logo.file_url : ''
            });
            this.analyticsSegment.setUserProperties({
                userId: vendorUser ? vendorUser.id : '',
                login_count: vendorUser.sign_in_count,
                dev_count: res.developer_count,
                approved_skills: res.approved_skills,
                multiskill_devs: res.multiskill_devs,
                email: vendorUser ? vendorUser.email : '',
                name: vendorUser ? vendorUser.name : '',
                phone: vendorUser ? vendorUser.phone : '',
                address: vendorUser ? vendorUser.address.address : '',
                logo: vendorUser && vendorUser.logo ? vendorUser.logo.file_url : ''
            });
        });
    }

    formHasError(): boolean {
        let formHasError = false;
        this.emailError = '';
        this.passwordError = '';
        if (!this.email) {
            this.emailError = 'Please enter email address';
            formHasError = true;
        } else if (!this.EMAIL_REGEX.test(this.email.toLowerCase())) {
            this.emailError = 'Please enter valid email address';
            formHasError = true;
        }
        if (!this.password) {
            this.passwordError = 'Please enter password';
            formHasError = true;
        } else if (!this.isValidPassword()) {
            this.passwordError = 'Please enter atleast 8 characters';
            formHasError = true;
        }
        return formHasError;
    }

    private getRateCardCount(): void {
        this.dataService.getRateCards()
        .subscribe((data) => {
            this.phaseService.updateRateCardCount(data);
        },err => this.dataService.showPageLoader = false);
    }

    verifyAccount(): void {
        this.router.navigate(['/resend-confirmation-email']);
    }

    gotoForgotPassword() {
        this.router.navigate(['forgot-password']);
    }
    
}
