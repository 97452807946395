import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "./../../../shared/services/data.service";
import { RateCardService } from "../../rate-card/rate-card.service";

@Component({
  selector: 'create-rate-card',
  templateUrl: './create-rate-card.component.html',
  styleUrls: ['./create-rate-card.component.scss']
})
export class CreateRateCardComponent implements OnInit, OnDestroy {

  rateCards = [];
  raiseRequestCards = [];
  currentRateCardIndex=0;
  progressBar = 0;
  rateSuggested = false;
  suggestedRates = {
    spot_price: 0,
    on_demand:0
  };
  router;
  spotRate = null;
  demandRate = null;
  pricesError = false;
  disableCreateRateCardBtn = true;
  is_last_rate_card = false;
  customRateCardPopup:boolean = false;
  customRateCardPopupAnimate:boolean = false;
  customSpotRate = null;
  customDemandRate = null;
  disableCreateCustomRateCardBtn:boolean = true;
  customPricesError:boolean = false;
  customCurrentDeveloperSkills;
  isCustomRateCard:boolean = false;
  rateCardService;
  developerSkill;
  showIncreaseRateCancelConfirmation = false;
  higherPriceError = true;
  dataService: any;

  constructor(dataService: DataService, rateCardService: RateCardService, private route: ActivatedRoute, router: Router) {
    this.dataService = dataService;
    this.router = router;
    this.rateCardService = rateCardService;
   }

  ngOnInit() {
    if(this.rateCardService.clickedScreen === 'migrate' || this.rateCardService.clickedScreen === 'setupRatesForDev'){
      this.getRateCardsMigrate(this.rateCardService.setupRatesForSkillExpertise);
    }
  }

  ngOnDestroy(){
    this.rateCardService.currentRateCard = '';
    this.rateCardService.clickedScreen = '';
    this.rateCardService.setupRatesForSkillExpertise = [];
  }

  rateCardDetails(){
    this.dataService.showHideLoader(true);
        this.route.params
        .subscribe(params => {
            this.getRateCardDetails(params.id);
        });
  }

  getRateCardDetails(id){
    this.rateCardService.getRateCardDetails(id)
    .subscribe(res => {
      this.dataService.showHideLoader(false);
      this.rateCardService.currentRateCard = res.data;
      this.spotRate = this.rateCardService.currentRateCard.bench_price;
      this.demandRate = this.rateCardService.currentRateCard.on_demand_price;
    });
  }

  editNewRateCard(){
    this.rateCardService.clickedScreen = "newRateCardEdit";
    this.spotRate = this.rateCardService.currentRateCard.bench_price;
    this.demandRate = this.rateCardService.currentRateCard.on_demand_price;
  }

  cancelEditRateCard(){
    this.rateCardService.clickedScreen = "newRateCardView";
    this.pricesError = false;
    this.higherPriceError = false;
  }

  
  showCancelIncreaseRateRequestPopup(){
    this.showIncreaseRateCancelConfirmation = true;
  }
  closeCancelIncreaseRateRequestPopup(){
    this.showIncreaseRateCancelConfirmation = false;
  }
  cancelIncreaseRateRequest(){
    this.cancelIncreasedRateCard();
    this.showIncreaseRateCancelConfirmation = false;
  }

  cancelIncreasedRateCard(){
    this.dataService.showHideLoader(true);
    this.rateCardService.cancelIncreasedRateCard(this.rateCardService.currentRateCard)
    .subscribe(res => {
      this.sendMixPanelEvent("existing", this.rateCardService.currentRateCard);
      this.dataService.showHideLoader(false);
      this.updatePriceValues(res);
    }, error => {
      console.log(error);
      if(error.status == 404){
        this.dataService.sharedServiceType = 'increaseRateCardAlreadyHandled';
        this.dataService.areServicesRunning = false;
        this.updatePriceValues(error.error);
        setTimeout(x => {
          this.dataService.areServicesRunning = true;
        }, 10000);
      }
      this.dataService.showHideLoader(false);
    })
  }

  updateRateCard(){
    if(this.spotRate > this.demandRate){return};
    this.dataService.showHideLoader(true);
    this.rateCardService.updateRateCard(this.rateCardService.currentRateCard, this.spotRate, this.demandRate, this.suggestedRates)
    .subscribe(res => {
      this.sendMixPanelEvent("existing", this.rateCardService.currentRateCard);
      this.dataService.showHideLoader(false);
      if(!res.data.increased_on_demand_price){
        this.rateCardService.rateCardavAilabilityToastData = {
            showToaster: true,
            status: 'success',
            text: res.message
        };
        this.router.navigate(['/vendor/billing/rate-cards']);
      }else{
        this.updatePriceValues(res);
        this.rateCardService.clickedScreen = "newRateCardView";
      }
    }, error => {
      console.log(error);
      this.dataService.showHideLoader(false);
    })
  }

  updatePriceValues(res){
    this.rateCardService.currentRateCard.increased_on_demand_price = res.data.increased_on_demand_price;
    this.rateCardService.currentRateCard.increased_bench_price = res.data.increased_bench_price;
    this.rateCardService.currentRateCard.on_demand_price = res.data.on_demand_price;
    this.rateCardService.currentRateCard.bench_price = res.data.bench_price;
  }

  sendMixPanelEvent(ratecard_type, rateCardData): void{
    const memberLocations = [];
    let multiskillMemberCount = 0;
    if(rateCardData.developer_skills && rateCardData.developer_skills.length > 0){
      for(let devSkillData of rateCardData.developer_skills){
        if(devSkillData.developer && devSkillData.developer.country && (memberLocations.indexOf(devSkillData.developer.country) === -1)){
          memberLocations.push(devSkillData.developer.country);
        }
        if(devSkillData.developer && devSkillData.developer.is_multi_skilled_dev){
          multiskillMemberCount++;
        }
      }
    }
    let mixpanelData = {
      ratecard_type: ratecard_type,
      onSpot: this.spotRate,
      onDemand: this.demandRate,
      member_count: rateCardData.developer_skills.length,
      skill: rateCardData.skill.title,
      member_location: memberLocations,
      expertise: rateCardData.expertise,
      multiskill_members: multiskillMemberCount
    };
    this.dataService.mixpanel_events_track('event', 'Rate save', mixpanelData);
  }

  removeRateCard(devSkill, currentRateCard){
    this.customRateCardPopup = true;
    this.developerSkill = devSkill;
    setTimeout(() => {
      this.customRateCardPopupAnimate = true;
    }, 100);
    this.customCurrentDeveloperSkills = devSkill.id;
  }

  closeCustomRateCardPopup(){
    this.customRateCardPopupAnimate = false;
    setTimeout(() => {
      this.customRateCardPopup = false;
      this.customSpotRate = '';
      this.customDemandRate = '';
      this.disableCreateCustomRateCardBtn = true;
      this.customPricesError = false;
    }, 1000);
  }

  onCustomRateChange(){
    if (this.customSpotRate > 0 && this.customDemandRate > 0) {
        this.customPricesError = false;
        this.disableCreateCustomRateCardBtn = false;
    } else {
        this.customPricesError = true;
        this.disableCreateCustomRateCardBtn = true;
    }
  }

  createNewCustomRateCard(){
    if(this.customSpotRate > this.customDemandRate){return};
    this.dataService.showHideLoader(true);
    this.isCustomRateCard = true;
    this.rateCardService.createRateCard(this.rateCardService.currentRateCard, this.customSpotRate, this.customDemandRate, this.suggestedRates, this.customCurrentDeveloperSkills, this.is_last_rate_card, this.isCustomRateCard)
      .subscribe(res => {
          this.closeCustomRateCardPopup();
          this.dataService.showHideLoader(false);
          this.isCustomRateCard = false;
          this.developerSkill.temp_rate_card_id = res.data.id;
          this.customSpotRate = '';
          this.customDemandRate = '';
          this.dataService.mixpanel_events_track('event', 'Custom request');
          this.raiseRequestCards.push(this.developerSkill);
      }, error => {
        console.log(error);
        this.dataService.showHideLoader(false);
        this.isCustomRateCard = false;
      });
  }

  cancelRaisedRequest(developer_skill){
    this.dataService.showHideLoader(true);
    this.rateCardService.deleteRateCard(developer_skill.temp_rate_card_id)
      .subscribe(res => {
        developer_skill.temp_rate_card_id = null;
        this.raiseRequestCards = this.raiseRequestCards.filter(rateCard => rateCard.id != developer_skill.id);
        this.dataService.showHideLoader(false);
      }, error => {
        this.dataService.showHideLoader(false);
        if(error.status === 404 || error.status === 406){
          this.raiseRequestCards = this.raiseRequestCards.filter(rateCard => rateCard.id != developer_skill.id);
          this.dataService.sharedServiceType = 'raisedRequestForCustomRateCardHandled';
          this.dataService.areServicesRunning = false;
          developer_skill.temp_rate_card_id = null;
          if(error.status === 406){
            this.rateCardService.currentRateCard.developer_skills = this.rateCardService.currentRateCard.developer_skills.filter(data => data.id != developer_skill.id);
          }
          setTimeout(x => {
              this.dataService.areServicesRunning = true;
          }, 10000);
        }
      });
  }

  getRateCardsMigrate(skillExpertiseData): void{
    this.dataService.showLoader = true;
    this.dataService.getRateCardsMigrate(skillExpertiseData)
    .subscribe(res => {
      this.rateCards = res.data;
      this.currentRateCardIndex = 0;
      this.rateCardService.currentRateCard = this.rateCards[this.currentRateCardIndex];
      this.progressBar = 0;
      this.dataService.showLoader = false;
    }, error => {
      this.dataService.showLoader = false;
      console.log(error);
    });
  }

  saveRateCard(): void{
      if(this.spotRate > this.demandRate){return};
      if(this.rateCards.length === (this.currentRateCardIndex+1)){
        this.is_last_rate_card = true;
      }
      this.createNewRateCard();
  }

  createNewRateCard(): void{
    let isLastRateCard = false;
    this.pricesError = false;
    this.disableCreateRateCardBtn = true;
    const dev_skills = [];
    if(this.rateCardService.currentRateCard.developer_skills){
      for(let dev_skill of this.rateCardService.currentRateCard.developer_skills){
        dev_skills.push(dev_skill.id);
      }
    }
    this.dataService.showHideLoader(true);
    if(this.rateCardService.clickedScreen === 'migrate' && this.is_last_rate_card){
      isLastRateCard = true;
    }
    this.rateCardService.createRateCard(this.rateCardService.currentRateCard,
                                        this.spotRate, this.demandRate,
                                        this.suggestedRates, dev_skills,
                                        isLastRateCard, this.isCustomRateCard)
      .subscribe(res => {
        this.raiseRequestCards = [];
        this.sendMixPanelEvent("new", this.rateCardService.currentRateCard);
        if(this.rateCardService.clickedScreen === 'migrate' || this.rateCardService.clickedScreen === 'setupRatesForDev'){
            if(this.is_last_rate_card){
              this.dataService.migrationAlSetPopupData = res;
              this.dataService.rateCardPopup = false;
              this.dataService.isNewRateCardsReady = false;
              this.dataService.setupRatesCardsCount = (this.rateCardService.clickedScreen === 'setupRatesForDev') ? this.rateCards.length : 0;
              this.dataService.migrationAlSetPopup = true;
              this.is_last_rate_card = false;
              if(this.rateCardService.clickedScreen === 'migrate'){
                this.rateCardService.updateTutorials('migrationDone');
              }
            } else{
              this.currentRateCardIndex = this.currentRateCardIndex+1;
              this.rateCardService.currentRateCard = this.rateCards[this.currentRateCardIndex];
              this.progressBar = (this.currentRateCardIndex/this.rateCards.length * 100);
            }
        } else if(this.rateCardService.clickedScreen === 'setupRates'){
              this.dataService.rateCardPopup = false;
              this.dataService.isNewRateCardCreated.next();
              this.rateCardService.rateCardavAilabilityToastData = {
                showToaster: true,
                status: 'success',
                text: res.message
            };
        }
        this.spotRate = null;
        this.demandRate = null;
        this.dataService.showHideLoader(false);
      }, error => {
        console.log(error);
        this.dataService.showHideLoader(false);
      });
  }

  closeRateCardPopup(): void{
    this.dataService.rateCardPopup = false;
    if(this.rateCardService.clickedScreen === 'migrate'){
      this.rateCardService.reviewLater();
      this.dataService.isNewRateCardCreated.next();
    } else if(this.rateCardService.clickedScreen === 'setupRatesForDev' && this.router.url === '/vendor/schedule'){
      this.dataService.isNewRateCardCreated.next();
    }
  }

onRateChange(e){
  if (this.spotRate && this.spotRate > 0 && this.demandRate && this.demandRate > 0) {
      this.pricesError = false;
      this.disableCreateRateCardBtn = false;
  } else {
      this.pricesError = true;
      this.disableCreateRateCardBtn = true;
  }
  this.higherPriceError = true;
}

}
