import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreComponent } from './core.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { InvoiceModule } from './invoices/invoice.module';
import { NeedHelpModule } from './need-help/need-help.module';
import { RateCardModule } from './rate-card/rate-card.module';
import { LeftMenuComponent } from '../left-menu/left-menu.component';
import { LoaderModule } from '../loader/loader.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'angular2-moment';
import { RequestsComponent } from './requests/requests.component';
import { RequestsService } from './requests/requests.service';
import { ScheduleModule } from './schedule/schedule.module';
import { BillingModule } from './billing/billing.module';
import { InboxModule } from './inbox/inbox.module';
import { SettingsModule } from './settings/settings.module';
import { PopupModule } from './popup/popup.module';
import { NotificationStripModule } from '../notification-strip/notification-strip.module';
import { NotificationLessMoreComponent } from './notifications/notification-less-more/notification-less-more.component';
import { InviteMembersComponent } from './invite-members/invite-members.component';
import { ToasterModule } from '../toaster/toaster.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        DashboardModule,
        InvoiceModule,
        NeedHelpModule,
        RateCardModule,
        LoaderModule,
        InfiniteScrollModule,
        MomentModule,
        ScheduleModule,
        BillingModule,
        InboxModule,
        SettingsModule,
        PopupModule,
        NotificationStripModule,
        ToasterModule
    ],
    declarations: [
        CoreComponent,
        LeftMenuComponent,
        NotificationsComponent,
        RequestsComponent,
        InviteMembersComponent,
        NotificationLessMoreComponent
    ],
    providers: [RequestsService]
})
export class CoreModule { }
