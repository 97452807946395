import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PartnerAgreementComponent } from './partner-agreement.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [PartnerAgreementComponent],
    declarations: [PartnerAgreementComponent]
})

export class PartnerAgreementModule { }
