import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { DataService } from '../../shared/services/data.service';
import { RequestsService } from './requests.service';
import { PhaseService } from '../../shared/services/phase.service';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
  selector: 'requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
    dataService;
    allocations: any[] = [];
    selectedAllocation;
    serverTime = '';
    showRequestDeclinePopup: boolean = false;
    declineAllocation: any;
    delineStatus: any;
    selectedListItem: any;
    concerns = ["The member is not available", "The duration of the project is too short", "The member doesn't have the required experience in the tools/technology.", "This project is outside our domain "];
    otherReason = '';
    concernSuccess = false;

    constructor(
        dataService: DataService,
        private router: Router,
        private requestsService: RequestsService,
        private phaseService: PhaseService,
        private analyticsSegment: Angulartics2Segment
    ) {
        this.dataService = dataService;
        this.phaseService.leftMenuActive = 'inbox';
        if (!this.dataService.getUser()) this.router.navigate(['login']);
    }

    ngOnInit() {
        if (!this.dataService.getUser()) return;
        this.dataService.afterLoginRedirectTo = '';
        this.getAndRenderRequests();
    }

    getAndRenderRequests(): void {
        this.dataService.showHideLoader(true);
        this.requestsService.getRequests(this.dataService.getUser().id)
        .subscribe(res => {
            this.allocations = this.getFormattedAllocations(res.data.allocations);
            this.serverTime = res.data.server_time;
            for (let allocation of this.allocations) {
              this.setRemainingTimeForRequestExpiry(allocation);
            }

            if (this.dataService.allocationId) {
              const allocation = this.allocations.find(x => x.id == this.dataService.allocationId && x.status === 'pending');
              if (!allocation) {
                this.dataService.areServicesRunning = false;
                this.dataService.sharedServiceType = 'allocationError';
                setTimeout(x => {
                  this.dataService.areServicesRunning = true;
                }, 5000);
                this.dataService.allocationId = '';
              }
            }
            this.dataService.showHideLoader(false);
        }, err => {
            this.dataService.showHideLoader(false);
        });
    }

    setRemainingTimeForRequestExpiry(allocation): void {
      //allocation.time = new Date(0, 0, 0, 0, 0, 0);
      let diffInSeconds = moment.utc(this.serverTime).diff(moment.utc(allocation.created_at), 'seconds');
      let hms = this.secondsToHms(diffInSeconds);
      const expiryTime = this.secondsToHms(allocation.expiry_time);
      let finalDays = expiryTime.days - hms.days;
      let finalHours = expiryTime.h - hms.h;
      let finalMinutes = expiryTime.m - hms.m;
      let finalSeconds = expiryTime.s - hms.s;

      if (diffInSeconds <= 0) {
          finalDays = expiryTime.days;
          finalHours = expiryTime.h;
          finalMinutes = expiryTime.m;
          finalSeconds = expiryTime.s;
      }
      if (finalDays <=0 && finalHours <= 0 && finalMinutes <= 0 && finalSeconds <= 0) {
          allocation.time = new Date(0, 0, 0, 0, 0, 0);
          this.removeExpiredAllocation(allocation);
      } else {
          // let dateObj = new Date(0, 0, 0, finalHours, finalMinutes, finalSeconds, 0);
          // allocation.time = dateObj;
          let momentObj = moment();
          momentObj.days(0);
          momentObj.hours(finalHours);
          momentObj.minutes(finalMinutes);
          momentObj.seconds(finalSeconds);
          allocation.time = momentObj.toDate();
          // if (this.refreshIntervalId) {
          //   clearInterval(this.refreshIntervalId);
          // }
          this.timerTick(allocation);
      }
      if (finalDays <= 0) {
        if (allocation.expiry_time <= 3600) {
          allocation.timeRemainingPercentage = (100/(allocation.expiry_time/60))*allocation.time.getMinutes();
        } else {
          allocation.timeRemainingPercentage = (100/(allocation.expiry_time/3600))*allocation.time.getHours();
        }
      } else {
        allocation.timeRemainingPercentage = (100/(allocation.expiry_time/3600))*(allocation.time.getHours()*finalDays);
      }
    }

    private timerTick(allocation) {
      // this.refreshIntervalId
      setInterval(() => {
        allocation.time.setSeconds(allocation.time.getSeconds(), -1);
        if (allocation.time.getDay() === 0 && allocation.time.getHours() === 0 && allocation.time.getMinutes() === 0 && allocation.time.getSeconds() === 0) {
            // clearInterval(this.refreshIntervalId);
            this.removeExpiredAllocation(allocation);
        }
      }, 1000);
    }

    removeExpiredAllocation(allocation): void {
      const allocationIndex = this.allocations.findIndex(x => x.id === allocation.id);
      this.allocations.splice(allocationIndex, 1);
    }

    private secondsToHms(d) {
        d = Number(d);
        let days = Math.floor(d / (3600*24));
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);
        return {h, m, s, days};
    }

    private getFormattedAllocations(res: any[]): any[] {
        return res.map(x => {
            x.start_date = moment(x.start_date, 'YYYY-MM-DD').format('D MMM YYYY');
            x.end_date = moment(x.end_date, 'YYYY-MM-DD').format('D MMM YYYY');
            x.allocationAction = '';
            x.slideToRemove = false;
            x.timeRemainingPercentage = 0;
            x.time = new Date(0, 0, 0, 0, 0, 0);
            return x;
        }).filter(x => x.status === 'pending');
    }

    redirectTo(page: string): void {
        this.router.navigate([page]);
    }

    openProjectDetail(allocation: any): void {
        this.selectedAllocation = allocation;
        this.selectedAllocation.attachments = this.selectedAllocation.attachments.map(x => {
            const fileUrlSplitted = x.file.url.split('/');
            x.file.name = fileUrlSplitted[fileUrlSplitted.length - 1];
            return x;
        });
    }

    downloadFile(attachment: any): void {
        let anchor = document.createElement('a');
        anchor.setAttribute('href', attachment.file.url);
        anchor.setAttribute('target', '_blank');
        anchor.setAttribute('download', attachment.file.name);
        anchor.click();
    }

    onRequestDecline(allocation: any, status: string): void {
      this.showRequestDeclinePopup = true;
      this.declineAllocation = allocation;
      this.delineStatus = status;
    }

    submitDeclineReason(): void{
      if (!this.selectedListItem) return;
        let rejectedReason = '';
        if (this.selectedListItem === 'other' && !this.otherReason) return;
        if (this.selectedListItem === 'other' && this.otherReason) {
          rejectedReason = this.otherReason;
        } else {
          rejectedReason = this.concerns.find((data, index) => index === (this.selectedListItem-1));
        }
        this.handleRequest(this.declineAllocation, this.delineStatus, rejectedReason);

    }

    cancelDeclineReason(): void {
        this.selectedListItem = undefined;
        this.otherReason = '';
        this.showRequestDeclinePopup = false;
    }

    onOptionClick(option){
      this.selectedListItem = option;
    }

    handleRequest(allocation: any, status: string, rejectedReason = ''): void {
        if (allocation.allocationAction != '') return;
        allocation.allocationAction = status === 'accepted' ? 'approve' : 'decline'
        const vendorUser = this.dataService.getUser();
        this.requestsService.handleRequest(allocation.id, status, allocation.allocationAction, rejectedReason, '', '', '')
        .subscribe(res => {
            allocation.slideToRemove = true;
            this.analyticsSegment.eventTrack('Request feedback', {
                feedback: allocation.allocationAction,
                email: vendorUser ? vendorUser.email : ''
            });
            setTimeout(() => {
                allocation.allocationAction = '';
                const allocationIndex = this.allocations.findIndex(x => x.id === allocation.id);
                this.allocations.splice(allocationIndex, 1);
            }, 500);
            this.showRequestDeclinePopup = false;
        }, err => {
            allocation.allocationAction = '';
            if (err._body) {
              const error = JSON.parse(err._body);
              if (error.error) {
                const expiredAllocationIndex = this.allocations.findIndex(x => x.id === allocation.id);
                this.allocations.splice(expiredAllocationIndex, 1);
                this.dataService.areServicesRunning = false;
                this.dataService.sharedServiceType = 'allocationError';
                setTimeout(x => {
                  this.dataService.areServicesRunning = true;
                }, 5000);
                this.dataService.allocationId = '';
              }
            }
            this.showRequestDeclinePopup = false;
        });
    }

}
