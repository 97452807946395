import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { mergeMap } from 'rxjs/operators';

import { DataService } from '../../../shared/services/data.service';
import { RegisterService } from '../../register.service';

@Component({
    selector: 'app-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss']
})

export class OrganizationComponent implements OnInit {
    @ViewChild('nameOfOrganizationEl', {static: true}) nameOfOrganizationEl: any;
    @ViewChild('hiddenInput', {static: false}) hiddenInput;
    validators = [this.domainNameValidator.bind(this)];
    errorMessages = {
        'incorrectDomainName': 'Incorrect domain name',
        'alreadyAdded': 'This domain is already added',
        'publicDomainName': 'Public domains are not allowed'
    };
    logoError = '';
    nameOfOrganization = '';
    nameOfOrganizationErr = '';
    phoneNumber = '';
    phoneNumberErr = '';
    companyDomains = [];
    companyDomainErr = '';
    companyRegistrationNumber = '';
    logo: File;
    logoUrl: any;
    address = '';
    addressId = '';
    addressErr = '';
    postalCode = '';
    postalCodeErr = ''
    city = '';
    cityErr = '';
    state = '';
    stateErr = '';
    country = '';
    countryErr = '';
    countries: any;
    countryCode = '1';
    countryCodes: any;
    user;
    dataService;
    dropDownSelected = false;
    vendorUser: any;
    blockedDomains = [];

    constructor(
        dataService: DataService,
        private router: Router,
        private registerService: RegisterService
    ) {
        this.dataService = dataService;
        this.vendorUser = this.dataService.getUser();
    }

    ngOnInit(): void {
        this.setDataForms();
        this.getBlockedDomains();
        this.dataService.mixpanel_events_track('people', '', {userId: this.vendorUser ? this.vendorUser.id : ''});
        this.dataService.mixpanel_events_track('event', 'Org info view');
    }

    getBlockedDomains(): void {
        this.dataService.showHideLoader(true);
        this.dataService.getBlockedDomains(this.vendorUser.email).subscribe(res => {
          this.blockedDomains = res.data;
          this.nameOfOrganizationEl.nativeElement.focus();
        }, err => {}, () => this.dataService.showHideLoader(false));
    }

    setDataForms() {
        this.dataService.getCountryList()
        .subscribe(countries => {
            this.countries = countries;
            this.getUserProfile();
        });
      }

    getUserProfile(): void {
        this.user = this.dataService.getUser();
        if (this.user.address) {
            this.preFillData();
        }
    }

    preFillData(): void {
        this.nameOfOrganization = this.user.name;
        if(this.user.phone){
            const phoneInfo = this.dataService.splitPhoneNumber(this.user.phone);
            this.phoneNumber = phoneInfo.phoneNumber;
            this.countryCode = phoneInfo.countryCode;
        }
        this.companyRegistrationNumber = this.user.company_reg_number || '';
        this.companyDomains = this.user.domain_names.map(x => {
            return {
                display: x,
                value: x
            };
        });
        if (this.user.logo) {
            this.logoUrl = this.user.logo.file_url;
            this.logo = this.user.logo;
        }
        this.address = this.user.address.address;
        this.addressId = this.user.address.id;
        this.postalCode = this.user.address.pincode;
        this.city = this.user.address.city;
        this.state = this.user.address.state;
        this.country = this.user.address.country;
    }

    private domainNameValidator(control: FormControl) {
        if (!control.value) return;
        if (this.isDomainNameValid(control.value)) {
            if (this.blockedDomains.indexOf(control.value) >= 0) {
                return {
                  'publicDomainName': true
                };
            } else if (this.companyDomains.indexOf(control.value) >= 0) {
                return {
                    'alreadyAdded': true
                };
            }
        } else {
            return {
                'incorrectDomainName': true
            };
        }
        return null;
    }

    private isDomainNameValid(companyDomain: string): boolean {
        if (companyDomain.indexOf('.') > 0 && companyDomain.indexOf('.') < companyDomain.length - 1) {
            return true;
        }
        return false;
    }

    onLogoChange(event: any): void {
        this.dataService.mixpanel_events_track('event', 'Logo');
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.logoError = '';
            for (const file of Array.from(fileList)) {
                this.logo = file;
                let reader = new FileReader();

                reader.onload = (event: ProgressEvent) => {
                    this.logoUrl = (<FileReader>event.target).result;
                }
            
                reader.readAsDataURL(event.target.files[0]);
            }
        }
    }

    getAddress(place: any): void {
        this.dropDownSelected = true;
        if (place != null && place.address_components != null) {
            for (const placeAddressComponent of place.address_components) {
                const addressType = placeAddressComponent.types[0];
                if (addressType === 'administrative_area_level_1') {
                    this.state = placeAddressComponent['long_name'];
                } else if (addressType === 'administrative_area_level_2') {
                    this.city = placeAddressComponent['long_name'];
                } else if (addressType === 'country') {
                    this.country = placeAddressComponent['long_name'];
                } else if (addressType === 'postal_code') {
                    this.postalCode = placeAddressComponent['long_name'];
                }
            }
        }
        this.address = place.formatted_address;
        if(this.dropDownSelected){
            this.setMxpAddress(this.address);
        }
        this.checkForAddressErrors();
        this.hiddenInput.nativeElement.click();
    }

    mxp_address(){
        setTimeout(() => {
            if(!this.dropDownSelected){
                this.setMxpAddress(this.address);
            } else{
                this.dropDownSelected = false;
            }
          }, 1000);
    }

    setMxpAddress(address: string){
        this.dataService.mixpanel_events_track('event', 'Address', {'Country': address});
        this.dataService.mixpanel_events_track('super_property', '', {'Country': address});
    }

    submitForm(): void {
        if (this.formHasErrors()) return;
        this.dataService.showHideLoader(true);
        this.registerService.updateUserWithOrganization(
            this.nameOfOrganization, this.phoneNumber, this.companyDomains.map(x => x.display),
            this.companyRegistrationNumber, this.address, this.city, this.state, this.country,
            this.postalCode, this.logo, this.countryCode, this.addressId
        )
        .subscribe(res => {
            this.dataService.showHideLoader(false); 
            this.dataService.setUser(res.data);
            this.router.navigate(['register/confirmed/2']);
        }, err => {
            this.dataService.showHideLoader(false);
        });
    }

    formHasErrors(): boolean {
        let formHasError = false;
        this.logoError = '';
        this.nameOfOrganizationErr = '';
        this.phoneNumberErr = '';
        this.companyDomainErr = '';
        this.addressErr = '';
        this.postalCodeErr = '';
        this.cityErr = '';
        this.stateErr = '';
        this.countryErr = '';
        if (!this.logo) {
            this.logoError = 'Please enter logo';
            formHasError = true;
        }
        if (!this.nameOfOrganization) {
            this.nameOfOrganizationErr = 'Please enter name of the organization';
            formHasError = true;
        }
        if (!this.phoneNumber) {
            this.phoneNumberErr = 'Please enter phone number';
            formHasError = true;
        } else if (this.phoneNumber && !this.isPhoneNumberValid(this.phoneNumber) ) {
            this.phoneNumberErr = 'Please enter valid phone number';
            formHasError = true;
        }
        if (this.companyDomains.length === 0) {
            this.companyDomainErr = 'Please enter at least one company domain';
            formHasError = true;
        }
        if (!this.address) {
            this.addressErr = 'Please enter address';
            formHasError = true;
        }
        if (!this.postalCode) {
            this.postalCodeErr = 'Please enter postal code';
            formHasError = true;
        }
        if (!this.city) {
            this.cityErr = 'Please enter city';
            formHasError = true;
        }
        if (!this.state) {
            this.stateErr = 'Please enter state';
            formHasError = true;
        }
        if (!this.country) {
            this.countryErr = 'Please enter country';
            formHasError = true;
        }
        return formHasError;
    }

    private isPhoneNumberValid(phoneNumber: string): boolean {
        const phoneNoRegex = /^(\d+-?)+\d+$/;
        const isValid =  phoneNumber.match(phoneNoRegex) ? true : false;
        const numberWithoutHyphens = phoneNumber.split('-').join('');
        return (isValid && numberWithoutHyphens.length >= 5 && numberWithoutHyphens.length <= 10) ? true : false;
    }
    
    checkForError(type: string, e: any): void {
        if (e.which === 13) return;
        if (type === 'name') {
            if (!this.nameOfOrganization) {
                this.nameOfOrganizationErr = 'Please enter name of the organization';
            } else {
                this.nameOfOrganizationErr = '';
            }
        } else if (type === 'phone') {
            if (!this.phoneNumber) {
                this.phoneNumberErr = 'Please enter phone number';
            } else if (this.phoneNumber && !this.isPhoneNumberValid(this.phoneNumber) ) {
                this.phoneNumberErr = 'Please enter valid phone number';
            } else {
                this.phoneNumberErr = '';
            }
        } else if (type === 'address') {
            if (!this.address) {
                this.addressErr = 'Please enter address';
            } else {
                this.addressErr = '';
            }
        } else if (type === 'pincode') {
            if (!this.postalCode) {
                this.postalCodeErr = 'Please enter postal code';
            } else {
                this.postalCodeErr = '';
            }
        } else if (type === 'city') {
            if (!this.city) {
                this.cityErr = 'Please enter city';
            } else {
                this.cityErr = '';
            }
        } else if (type === 'state') {
            if (!this.state) {
                this.stateErr = 'Please enter state';
            } else {
                this.stateErr = '';
            }
        } else if (type === 'country') {
            if (!this.country) {
                this.countryErr = 'Please enter country';
            } else {
                this.countryErr = '';
            }
        }
    }

    private checkForAddressErrors(): void {
        if (!this.postalCode) {
            this.postalCodeErr = 'Please enter postal code';
        } else {
            this.postalCodeErr = '';
        }
        if (!this.city) {
            this.cityErr = 'Please enter city';
        } else {
            this.cityErr = '';
        }
        if (!this.state) {
            this.stateErr = 'Please enter state';
        } else {
            this.stateErr = '';
        }
        if (!this.country) {
            this.countryErr = 'Please enter country';
        } else {
            this.countryErr = '';
        }
    }

    checkForDomainError(): void {
        if (this.companyDomains.length === 0) {
            this.companyDomainErr = 'Please enter at least one company domain';
        } else {
            this.companyDomainErr = '';
        }
    }

}
