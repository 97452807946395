
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DataService } from '../../shared/services/data.service';
import { HttpService } from '../../shared/services/http.service';

@Injectable()
export class NeedHelpService {
    
    constructor(private dataService: DataService, private httpService: HttpService) { }

    getHelpTopics(page: number, perPage: number, search: string): Observable<any> {
        let API = `${this.dataService.getServerUrl()}helps?per_page=${perPage}&page=${page}&type=vendor`;
        if (search) {
            API += `&search=${search}`;
        }
        return this.httpService.getRequest(API);
    }

    vote(voteType: number, helpTopicId: string): Observable<any> {
        const API = `${this.dataService.getServerUrl()}helps/${helpTopicId}/vote?type=vendor`;
        
        const body = {
            vote: voteType
        }
        return this.httpService.postRequest(API, body);
    }

    raiseRequest(request) {
        const API = this.dataService.getServerUrl() + this.dataService.apiEndPoints.vendor_raise_request;
        const requestData = new FormData();
        for (const key of Object.keys(request)) {
          const value = request[key];
          const underscored_key = this.dataService.underscore(this.dataService.CONSTANTS.REQUEST + this.dataService.CONSTANTS.OPEN_BRACKET + key + this.dataService.CONSTANTS.CLOSE_BRACKET);
          if (value != null) {
            if (key == "attachment_attributes") {
              requestData.append(underscored_key + this.dataService.CONSTANTS.FILE_PARAM, value.file, value.file.name);
            }
            else {
              requestData.append(underscored_key, value);
            }
          }
        }
        return this.httpService.postRequest(API, requestData);
      }

}
