import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import 'rxjs/add/operator/do';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { DataService } from '../shared/services/data.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(private dataService: DataService, private router: Router) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).do((event: HttpEvent<any>) => {}, (error: any) => {
            if (error instanceof HttpErrorResponse) {
                this.dataService.showHideLoader(false);
                if (error.status === 403 || error.status === 401) {
                    localStorage.clear();
                    this.router.navigate(['login']);
                } else if (error.status === 500 || error.status === 502 || error.status === 503) {
                    this.dataService.sharedServiceType = 'generalError';
                    this.dataService.areServicesRunning = false;
                    setTimeout(x => {
                        this.dataService.areServicesRunning = true;
                    }, 10000);
                }
            }
        });
    }
}
