import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChildren, QueryList, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { SelectContainerComponent } from 'ngx-drag-to-select';

import { PhaseService } from '../../shared/services/phase.service';
import { ScheduleService } from './schedule.service';
import { DataService } from '../../shared/services/data.service';
import { RateCardService } from "../rate-card/rate-card.service";
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
    selector: 'schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ScheduleComponent implements OnInit, OnDestroy {
    private readonly PER_PAGE = 15;
    userSkills = [];
    developers: any[] = [];
    serverTime = '';
    allocations: any[] = [];
    showRightPanel = false;
    showManageRequest = false;
    toasterData: any = {};
    showToaster = false;
    metaData: any;
    columnWidth = 0;
    days: any[] = [];
    availableMonths = [];
    redirect_to = 'schedule';
    queryParams = {
        selectedYear: parseInt(moment().format('YYYY')),
        selectedMonth: parseInt(moment().format('M')),
        monthsToDisplay: [],
        search: '',
        sortBy: 'allocated_first',
        page: 1,
        meta: {} as any
    };
    myDatePickerOptions: IMyDpOptions = {
        dateFormat: 'yyyy-mm-dd',
        showInputField: false,
        showClearDateBtn: false,
        disableDays: []
    };
    isScrolled = false;
    scheduleObservable: Subscription;
    isNewRateCardCreatedObservable: Subscription;
    projectDetail: any;
    memberInfo: any;
    projectData: any;
    memberData: any;
    selectedMonthYear = `${moment().format('MMM')} ${moment().format('YYYY')}`;
    selectedDates: any = [];
    popupActive = false;
    popupLeftPosition = 0;
    popupTopPosition = 0;
    popupData: any;
    showCommentInput = false;
    isDragOver = false;
    popupError = '';
    showConfirmDelete = false;
    timeOffType = 'unavailable';
    firstTimeMouseNotMoved = true;
    loaderOn = false;
    slideConfig = {slidesToShow: 6, slidesToScroll: 6, infinite: false, initialSlide: 2*6};
    @ViewChildren(SelectContainerComponent) componentScroll: QueryList<any>;
    @ViewChildren(SelectContainerComponent, {read: ElementRef}) componentScrollElement: QueryList<ElementRef>;
    currentDateValue;
    contentHeight = 0;
    vendorData;
    rateCardService;
    dataService;

    constructor(
        private phaseService: PhaseService,
        private scheduleService: ScheduleService,
        dataService: DataService,
        rateCardService: RateCardService,
        private router: Router,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        private analyticsSegment: Angulartics2Segment
    ) {
        this.rateCardService = rateCardService;
        this.dataService = dataService;
        this.vendorData = this.dataService.getUser();
        this.phaseService.leftMenuActive = 'schedule';
        if (this.dataService.allocationRequestData) {
            this.queryParams.selectedMonth = parseInt(moment(this.dataService.allocationRequestData.from).format('M'));
            this.queryParams.selectedYear = parseInt(moment(this.dataService.allocationRequestData.from).format('YYYY'));
            this.dataService.allocationRequestData = null;
        }
        this.availableMonths = this.availableMonths.concat(this.scheduleService.getMonths(parseInt(moment().subtract(1, 'years').format('YYYY'))));
        this.availableMonths = this.availableMonths.concat(this.scheduleService.getMonths(this.queryParams.selectedYear));
        this.availableMonths = this.availableMonths.concat(this.scheduleService.getMonths(parseInt(moment().add(1, 'years').format('YYYY'))));
        if (this.queryParams.selectedMonth >= 7) {
            this.slideConfig.initialSlide = 3*6;
            this.setCurrentMonthAndYear(7, this.queryParams.selectedYear);
        } else {
            this.setCurrentMonthAndYear(1, this.queryParams.selectedYear);
        }
        this.isNewRateCardCreatedObservable = this.dataService.isNewRateCardCreated.subscribe(() => {
            this.setEnvForAllocatedFirst();
            this.getAndRenderSchedule();
            this.getCurrentDate();
        });
    }

    ngOnInit() {
        this.dataService.afterLoginRedirectTo = '';
        this.getAndRenderSchedule();
        this.setMixPanelEvents();
        this.getCurrentDate();
    }

    getCurrentDate(){
        var today = new Date();
        this.currentDateValue = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    }

    openInviteMembers(): void {
        this.closeRightPanel('memberInfo');
        this.dataService.showInviteMembers = true;
        this.dataService.mixpanel_invite_members("schedule");
        setTimeout(() => {
            this.dataService.classInviteMembers = true;
        }, 500);
    }

    setMixPanelEvents(): void {
        this.dataService.getStatisticsForMixpanel()
        .subscribe(res => {
            this.analyticsSegment.setUserProperties({
                userId: this.vendorData ? this.vendorData.id : '',
                dev_count: res.developer_count,
                approved_skills: res.approved_skills,
                multiskill_devs: res.multiskill_devs
            });
        });
    }

    ngOnDestroy(): void {
        if (this.scheduleObservable) this.scheduleObservable.unsubscribe();
        if (this.isNewRateCardCreatedObservable) this.isNewRateCardCreatedObservable.unsubscribe();
        this.dataService.showInviteMembers = false;
    }

    getDays(): void {
        this.days = this.scheduleService.getDays(this.queryParams.selectedYear, this.queryParams.selectedMonth);
    }

    setDays(): void {
        setTimeout(() => this.columnWidth = document.getElementsByClassName('user-data')[0].clientWidth/this.days.length, 400);
    }

    getAndRenderSchedule(): void {
        this.dataService.showHideLoader(true);
        this.loaderOn = true;
        if (this.scheduleObservable) this.scheduleObservable.unsubscribe();
        this.scheduleObservable = this.scheduleService.getSchedule(this.queryParams, this.PER_PAGE)
        .subscribe(res => {
            this.loaderOn = false;
            this.dataService.showHideLoader(false);
            if (this.isScrolled) {
                this.isScrolled = false;
                this.developers = this.developers.concat(res.data);
            } else {
                this.developers = res.data;
            }
            this.queryParams.meta = res.meta;
            this.checkForPendingAllocation();
            this.popupActive = false;
            this.getDays();
            this.showUpskillPopup();
            this.formatDevelopers();
            setTimeout(() => {
                this.cdRef.detectChanges();
                this.onNormalScroll();
            }, 10);
            this.cdRef.markForCheck();
            if (this.developers.length > 0) this.setDays();
            setTimeout(() => {
                this.contentHeight = document.getElementById('contentHeight') && document.getElementById('contentHeight').offsetHeight + 7;
                this.cdRef.detectChanges();
            }, 400);

        }, err => {
            this.loaderOn = false;
            this.dataService.showHideLoader(false);
        });
    }

    checkForPendingAllocation(): void {
        if (this.dataService.allocationId) {
            let isAllocation = false;
            for (let developer of this.developers) {
                for (let timeline of developer.timelines) {
                    const allocation = timeline.find(x => x.allocation_status && x.allocation_status === 'pending' && x.allocation_id == this.dataService.allocationId);
                    if (allocation) {
                        isAllocation = true;
                        this.dataService.allocationId = '';
                        this.projectData = allocation;
                        this.memberData = developer;
                        this.showManageRequest = true;
                        break;
                    }
                }
                if (isAllocation) {
                    break;
                }
            }
            if (!isAllocation) {
                this.dataService.areServicesRunning = false;
                this.dataService.sharedServiceType = 'allocationError';
                setTimeout(x => {
                    this.dataService.areServicesRunning = true;
                }, 5000);
                this.dataService.allocationId = '';
            }
        }
    }

    showUpskillPopup(): void {
        if(this.dataService.show_upskill_rate_cards){
            this.dataService.show_upskill_rate_cards = false;
            let allSetupRateCards = this.getAllSetupRateCards();
            if(allSetupRateCards.length > 0) {
                this.showSetupRateCardPopup(allSetupRateCards);
            } else {
                this.router.navigate(['vendor/billing/rate-cards']);
            }
        }
    }

    getAllSetupRateCards(){
        let allSetupRateCardList = [];
        for(let developer of this.developers){
            if(developer.developer_skills && developer.developer_skills.length > 0){
                for(let devSkill of developer.developer_skills){
                    if(!devSkill.rate_card || !devSkill.rate_card.id){
                        allSetupRateCardList.push(devSkill);
                    }
                }
            }
        }
        return allSetupRateCardList;
    }

    showSetupRateCardPopup(allSetupRateCards): void {
        this.rateCardService.setupRatesForSkillExpertise = allSetupRateCards;
        this.rateCardService.clickedScreen = 'setupRatesForDev';
        this.dataService.rateCardPopup = true;
    }

    formatDevelopers(): void {
        this.developers = this.developers.map(dev => {
            let rateCardsToDisplay = '';
            let allRateCardsToDisplay = '';
            let setupRateCardList = [];
            let doneRateCardList = [];
            this.serverTime = dev.server_time;
            if(dev.developer_skills && dev.developer_skills.length > 0){
                for(let dev_skill of dev.developer_skills){
                        if(!dev_skill.rate_card || !dev_skill.rate_card.id){
                            setupRateCardList.push(dev_skill);
                        } else{
                            doneRateCardList.push(dev_skill);
                        }
                }
                // if(doneRateCardList.length > 0 && setupRateCardList.length === 0){
                    if(doneRateCardList.length === 1){
                        rateCardsToDisplay = doneRateCardList[0].rate_card.title;
                    } else if (doneRateCardList.length > 1) {
                        rateCardsToDisplay = `${doneRateCardList[0].rate_card.title} + ${doneRateCardList.length-1}`;
                    }
                // }
                if(doneRateCardList.length > 0){
                    allRateCardsToDisplay = doneRateCardList.map(dev_skill => dev_skill.rate_card.title).join(", ");
                }
            }
            dev.rateCardsToDisplay = rateCardsToDisplay;
            dev.allRateCardsToDisplay = allRateCardsToDisplay;
            dev.setupRateCardList = setupRateCardList;
            dev.nonWorkingDayTimelines = [];
            for (let timeline of dev.timelines) {
                for (let capsule of timeline) {
                    capsule.numberOfProjDays = moment(capsule.to).diff(moment(capsule.from), 'days')+1;
                    if (capsule.status === 'leave' || capsule.status === 'unavailable') {
                        dev.nonWorkingDayTimelines.push(capsule);
                    }
                    if ((capsule.status === 'project' && capsule.allocation_status === 'pending') || (capsule.allocation_status === 'accepted' && capsule.extension_type === 'extension' && capsule.extension_status === 'epending')) {
                        this.setRemainingTimeForRequestExpiry(capsule);
                    }
                }
            }
            dev.dailyTimelines = JSON.parse(JSON.stringify(this.days));
            for (let dailyTimeline of dev.dailyTimelines) {
                for (let timeline of dev.nonWorkingDayTimelines) {
                    if (moment(dailyTimeline.date, 'YYYY-MM-DD').isSameOrAfter(moment(timeline.from, 'YYYY-MM-DD')) && moment(dailyTimeline.date, 'YYYY-MM-DD').isSameOrBefore(moment(timeline.to, 'YYYY-MM-DD'))) {
                        dailyTimeline.info = timeline;
                    }
                }
            }
            return dev;
        });
    }

    onResize(): void {
        if (this.developers.length > 0) {
            this.columnWidth = document.getElementsByClassName('user-data')[0].clientWidth/this.days.length;
            this.cdRef.detectChanges();
        }
    }

    // setMonths(type: string): void {
    //     if (type === 'next') {
    //         if (this.queryParams.monthsToDisplay[0].value >= 7) {
    //             this.setCurrentMonthAndYear(1, this.queryParams.monthsToDisplay[0].year+1);
    //         } else {
    //             this.setCurrentMonthAndYear(7, this.queryParams.monthsToDisplay[0].year);
    //         }
    //     } else if (type === 'previous') {
    //         if (this.queryParams.monthsToDisplay[0].value >= 7) {
    //             this.setCurrentMonthAndYear(1, this.queryParams.monthsToDisplay[0].year);
    //         } else {
    //             this.setCurrentMonthAndYear(7, this.queryParams.monthsToDisplay[0].year-1);
    //         }
    //     }
    // }

    setCurrentMonthAndYear(month: number, year: number): void {
        const indexofMonths = this.availableMonths.findIndex(x => x.value === month && x.year === year);
        if (indexofMonths === -1) return;
        this.queryParams.monthsToDisplay = this.availableMonths.slice(indexofMonths, indexofMonths+6);
    }

    selectMonth(month: any): void {
        this.selectedMonthYear = `${month.label} ${month.year}`;
        this.queryParams.selectedYear = month.year;
        this.queryParams.selectedMonth = month.value;
        this.setEnvForAllocatedFirst();
        this.getAndRenderSchedule();
    }

    sortBy(type: string): void {
        this.dataService.mixpanel_events_track('event', 'Filters', {'type': type});
        this.queryParams.sortBy = type;
        this.setEnvForAllocatedFirst();
        this.getAndRenderSchedule();
    }

    onScroll() {
        if (this.queryParams.meta && this.queryParams.meta.next_page !== null && !this.isScrolled) {
            this.isScrolled = true;
            this.queryParams.page++;
            this.getAndRenderSchedule();
        }
    }

    onNormalScroll() {
        this.componentScrollElement.toArray().forEach((element, index) => {
            this.componentScroll.toArray()[index].update();
        });
    }

    onSearch(event: any): void {
        const key = event.keyCode || event.charCode;
        if ((key === 8 || key === 46) && this.queryParams.search.length === 0) {
            this.setEnvForAllocatedFirst();
            this.getAndRenderSchedule();
        }
        if (this.queryParams.search.length < 1) return;
        this.analyticsSegment.eventTrack('Search', {email: this.vendorData ? this.vendorData.email : ''});
        this.setEnvForAllocatedFirst();
        this.getAndRenderSchedule();
    }

    setEnvForAllocatedFirst(): void {
        this.queryParams.page = 1;
        this.developers = [];
    }

    setupRateCard(dev): void {
        this.rateCardService.setupRatesForSkillExpertise = [];
        this.analyticsSegment.eventTrack('Add to rate card', {email: this.vendorData ? this.vendorData.email : ''});
        const vendorUser = this.dataService.getUser();
        if (vendorUser.tutorials.indexOf("migrationDone") < 0){
            this.dataService.firstMigrationPopup = true;
        } else{
            this.rateCardService.setupRatesForSkillExpertise = dev.setupRateCardList;
            this.rateCardService.clickedScreen = 'setupRatesForDev';
            this.dataService.rateCardPopup = true;
        }
    }

    setRemainingTimeForRequestExpiry(allocation): void {
        let diffInSeconds = moment.utc(this.serverTime).diff(moment.utc(allocation.created_at), 'seconds');
        if(allocation.allocation_status === 'accepted' && allocation.extension_type === 'extension' && allocation.extension_status === 'epending'){
            diffInSeconds = moment.utc(this.serverTime).diff(moment.utc(allocation.extension_requested_at), 'seconds');
        }
        let hms = this.secondsToHms(diffInSeconds);
        const expiryTime = this.secondsToHms(allocation.expiry_time);
        let finalDays = expiryTime.days - hms.days;
        let finalHours = expiryTime.h - hms.h;
        let finalMinutes = expiryTime.m - hms.m;
        let finalSeconds = expiryTime.s - hms.s;

        if (diffInSeconds <= 0) {
            finalDays = expiryTime.days;
            finalHours = expiryTime.h;
            finalMinutes = expiryTime.m;
            finalSeconds = expiryTime.s;
        }
        if (finalDays <=0 && finalHours <= 0 && finalMinutes <= 0 && finalSeconds <= 0) {
            allocation.time = new Date(0, 0, 0, 0, 0, 0);
            this.removeExpiredAllocation(allocation);
        } else {
            // let dateObj = new Date(0, 0, 0, finalHours, finalMinutes, finalSeconds, 0);
            // allocation.time = dateObj;
            let momentObj = moment();
            momentObj.days(0);
            momentObj.hours(finalHours);
            momentObj.minutes(finalMinutes);
            momentObj.seconds(finalSeconds);
            allocation.time = momentObj.toDate();
            // if (this.refreshIntervalId) {
            //   clearInterval(this.refreshIntervalId);
            // }
            this.timerTick(allocation);
        }
        if (finalDays <= 0) {
          if (allocation.expiry_time <= 3600) {
            allocation.timeRemainingPercentage = (100/(allocation.expiry_time/60))*allocation.time.getMinutes();
          } else {
            allocation.timeRemainingPercentage = (100/(allocation.expiry_time/3600))*allocation.time.getHours();
          }
        } else {
          allocation.timeRemainingPercentage = (100/(allocation.expiry_time/3600))*(allocation.time.getHours()*finalDays);
        }
    }

    private timerTick(allocation) {

    // this.refreshIntervalId
        setInterval(() => {
            allocation.time.setSeconds(allocation.time.getSeconds(), -1);
            if (allocation.time.getDay() === 0 && allocation.time.getHours() === 0 && allocation.time.getMinutes() === 0 && allocation.time.getSeconds() === 0) {
                // clearInterval(this.refreshIntervalId);
                this.removeExpiredAllocation(allocation);
            }
        }, 1000);
    }

    removeExpiredAllocation(allocation): void {
        for(let dev of this.developers){
            for (let timeline of dev.timelines) {
                const allocationIndex = timeline.findIndex(x => x.id === allocation.id);
                if (allocation.extension_type === 'extension') {
                    // allocation.to = allocation.original_to_date;
                    // allocation.extension_type = '';
                    // allocation.extension_status = '';
                    // this.formatDevelopers();
                    this.setEnvForAllocatedFirst();
                    this.getAndRenderSchedule();
                    break;
                } else {
                    if(allocationIndex >= 0){
                        timeline.splice(allocationIndex, 1);
                    }
                }
            }
        }
    }

      setAllocationAccepted(allocation): void {
        for(let dev of this.developers){
          for (let timeline of dev.timelines) {
              const alloc = timeline.find(x => x.id === allocation.id);
              if(alloc){
                alloc.allocation_status = 'accepted';
                alloc.extension_type = '';
                alloc.extension_status = '';
                break;
              }
        }
      }
    }

      private secondsToHms(d) {
          d = Number(d);
          let days = Math.floor(d / (3600*24));
          let h = Math.floor(d / 3600);
          let m = Math.floor(d % 3600 / 60);
          let s = Math.floor(d % 3600 % 60);
          return {h, m, s, days};
      }

    onCapsuleClick(capsule: any, dev: any): void {
        if (capsule.status === 'project') {
            this.closePopup();
            this.componentScroll.toArray().forEach((element) => {
                element.clearSelection();
            });
            this.analyticsSegment.eventTrack('Project Details', {email: this.vendorData ? this.vendorData.email : ''});
            this.projectData = capsule;
            this.memberData = dev;
            this.showManageRequest = true;
        } else if (capsule.status === 'leave' || capsule.status === 'unavailable') {
            this.memberInfo = null;
            this.projectDetail = null;
            this.showManageRequest = false;
            this.popupData = {
                popupType: 'editTimeOff',
                devId: dev.id,
                devName: dev.name,
                timeoffId: capsule.id,
                from: capsule.actual_range.from,
                to: capsule.actual_range.to,
                status: capsule.status,
                comment: capsule.comment,
                disableSubmit: true
            };
            this.popupActive = true;
        }
    }

    onUserProfileClick(dev: any): void {
        this.memberInfo = dev;
        //this.showRightPanel = true;
        if (dev && dev.developer_skills) {
            var skills = dev.developer_skills.map(devSkill => {
                return devSkill.skill.title
            });
        }
        this.analyticsSegment.eventTrack('Dev info', {
            email: this.vendorData ? this.vendorData.email : '',
            dev_name: dev ? dev.name : '',
            dev_email: dev ? dev.email : '',
            skills: dev && dev.developer_skills ? skills : []
        });
    }

    closeRightPanel(type: string): void {
        this[type] = null;
        this.showRightPanel = false;
    }

    closeManageRequest(type: any): void {
        if(type && type.status === 'accepted'){
            this.setAllocationAccepted(type.allocation);
            this.updateToaster('success', type);
        }
        else if(type && type.status === 'rejected'){
            this.removeExpiredAllocation(type.allocation);
            this.createUnavailabilityAfterDecliningRequest(type.unavail_from, type.unavail_to);
            this.updateToaster('error', type);
        }
        this.showManageRequest = false;
    }

    updateToaster(status, type){
        this.toasterData.status = status;
        this.toasterData.text = 'Project '+type.allocation.project.name+' '+type.status+'!';
        this.showToaster = true;
    }

    createUnavailabilityAfterDecliningRequest(from: string, to: string): void {
        if (!(from && to)) return;
        this.dataService.showHideLoader(true);
        this.scheduleService.createUnavailability(from, to, this.memberData.id, this.queryParams.selectedYear, this.queryParams.selectedMonth)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            for (let dev=0; dev < this.developers.length; dev++) {
                if (this.developers[dev].id === res.data.id) {
                    this.developers[dev] = res.data;
                }
            }
            this.formatDevelopers();
        }, err => {
            this.dataService.showHideLoader(false);
        });
    }

    closeToaster(): void {
        this.showToaster = false;
    }

    closePopup(): void {
        this.popupError = '';
        this.popupActive = false;
        this.showConfirmDelete = false;
        this.selectedDates = [];
        this.showCommentInput = false;
        this.showManageRequest = false;
        setTimeout(() => this.cdRef.detectChanges(), 0);
    }

    onMouseMove(dev, e): void {
        // if (this.dataService.isReadOnlyUser()) return;
        if (this.firstTimeMouseNotMoved) {
            this.firstTimeMouseNotMoved = false;
            setTimeout(() => this.onNormalScroll(), 0);
        }
        if (this.selectedDates.length > 1) {
            let momentDate = moment(this.selectedDates[this.selectedDates.length - 1].date);
            let dayNumer = momentDate.format('D');
            if (dayNumer === '1' || momentDate.daysInMonth() === parseInt(dayNumer)) {
                this.dragOver(dev, e);
            }
        }
    }

    dragStart(event): void {
        this.closePopup();
        if (this.dataService.isReadOnlyUser()) return;
        this.isDragOver = false;
        this.componentScroll.toArray().forEach((element) => {
            element.clearSelection();
        });
        this.cdRef.markForCheck();
    }

    dragOver(dev, e): void {
        if (this.dataService.isReadOnlyUser()) return;
        if (this.isDragOver) return;
        if (this.selectedDates.length <= 0) return;
        this.isDragOver = true;
        let disableSubmit = false;
        let popupType = '', timeoffId = '', actualRange = null;
        for (let date of this.selectedDates) {
            if ((date.info && date.info.status === 'unavailable') || (date.info && date.info.status === 'leave')) {
                disableSubmit = true;
            }
        }
        if ((this.selectedDates[0].info && this.selectedDates[0].info.status === 'unavailable' && this.selectedDates[this.selectedDates.length - 1].info && this.selectedDates[this.selectedDates.length - 1].info.status === 'unavailable') ||
            (this.selectedDates[0].info && this.selectedDates[0].info.status === 'leave' && this.selectedDates[this.selectedDates.length - 1].info && this.selectedDates[this.selectedDates.length - 1].info.status === 'leave')) {
            popupType = 'editTimeOff';
            if (this.selectedDates[0].info && this.selectedDates[this.selectedDates.length - 1].info && this.selectedDates[0].info.id === this.selectedDates[this.selectedDates.length - 1].info.id) {
                timeoffId = this.selectedDates[0].info.id;
                actualRange = this.selectedDates[0].info.actual_range;
            } else {
                popupType = '';
            }
        } else if (this.selectedDates[0].info && this.selectedDates[0].info.status === 'projects' && this.selectedDates[this.selectedDates.length - 1].info && this.selectedDates[this.selectedDates.length - 1].info.status === 'projects') {
            popupType = 'projectInfo';
        } else {
            popupType = 'addTimeOff';
        }
        let from, to;
        if (actualRange) {
            from = actualRange.from;
            to = actualRange.to;
        } else {
            from = moment(this.selectedDates[0].date, 'YYYY-MM-DD').isBefore(moment(this.selectedDates[this.selectedDates.length - 1].date, 'YYYY-MM-DD')) ? this.selectedDates[0].date : this.selectedDates[this.selectedDates.length - 1].date;
            to = moment(this.selectedDates[0].date, 'YYYY-MM-DD').isBefore(moment(this.selectedDates[this.selectedDates.length - 1].date, 'YYYY-MM-DD')) ? this.selectedDates[this.selectedDates.length - 1].date : this.selectedDates[0].date;
        }
        let popupData = {
            popupType: popupType,
            devId: dev.id,
            devName: dev.name,
            timeoffId: timeoffId,
            from: from,
            to: to,
            status: this.selectedDates[0].info && this.selectedDates[this.selectedDates.length - 1].info && this.selectedDates[0].info.status === this.selectedDates[this.selectedDates.length - 1].info.status ? this.selectedDates[0].info.status : '',
            comment: this.selectedDates[0].info && this.selectedDates[0].info.comment ? this.selectedDates[0].info.comment : '',
            disableSubmit: disableSubmit
        };
        this.popupData = Object.assign({}, popupData);
        this.myDatePickerOptions.disableDays = dev.dailyTimelines
        .filter(x => {
            if (x.info && (x.info.status === 'unavailable' || x.info.status === 'leave')) {
                if (!(this.popupData.popupType === 'editTimeOff' && x.date >= this.popupData.from && x.date <= this.popupData.to)) {
                    return x;
                }
            }
        })
        .map(x => {
            let splitDate = x.date.split("-");
            return {
                year: parseInt(splitDate[0]),
                month: parseInt(splitDate[1]),
                day: parseInt(splitDate[2])
            };
        });
        this.memberInfo = null;
        this.projectDetail = null;
        this.showManageRequest = false;
        this.popupActive = true;
        if (this.popupData.popupType === 'editTimeOff') {
            this.popupActive = false;
        }
        if (this.selectedDates.length > 1 && this.selectedDates[0].info && this.selectedDates[this.selectedDates.length - 1].info && this.selectedDates[0].info.id !== this.selectedDates[this.selectedDates.length - 1].info.id) {
            let emptyDatesCount = 0;
            for (let date of this.selectedDates) {
                if (date.info && date.info.status !== 'empty') {
                    emptyDatesCount++;
                }
            }
            if (emptyDatesCount === this.selectedDates.length) {
                this.popupActive = false;
            }
        }
        setTimeout(() => this.cdRef.detectChanges(), 0);
    }

    onPopupDateChanged(event: IMyDateModel, dateType: string): void {
        if (dateType === 'from') {
            this.popupData.from = `${event.formatted}`;
        } else if (dateType === 'to') {
            this.popupData.to = `${event.formatted}`;
        }
    }

    enableComment(e): void {
        this.showCommentInput = true;
        e.stopPropagation();
    }

    setTimeOff(): void {
        if (!this.popupData.from || !this.popupData.to) return;
        if (moment(this.popupData.to).isBefore(moment(this.popupData.from))) {
            this.popupError = 'From cannot be greater than to';
            return;
        }
        if (this.popupData.disableSubmit) return;
        if (this.popupData.timeoffId) {
            this.updateTimeOff();
        } else {
            this.saveTimeOff();
        }
    }

    saveTimeOff(): void {
        this.dataService.showHideLoader(true);
        this.scheduleService.postTimeOff(this.popupData, this.timeOffType)
        .subscribe(res => {
            this.popupError = '';
            this.analyticsSegment.eventTrack('Time off', {
                type: this.timeOffType,
                email: this.vendorData ? this.vendorData.email : ''
            });
            this.setEnvForAllocatedFirst();
            this.getAndRenderSchedule();
            this.closePopup();
        }, err => {
            if (!err.error) return;
            let error = err.error;
            let popupErr = '';
            if (error.message) {
                popupErr = error.message;
            }
            this.popupError = popupErr;
            this.cdRef.detectChanges();
            this.dataService.showHideLoader(false);
        });
    }

    updateTimeOff(): void {
        this.dataService.showHideLoader(true);
        this.scheduleService.putTimeOff(this.popupData, this.timeOffType)
        .subscribe(res => {
            this.popupError = '';
            this.setEnvForAllocatedFirst();
            this.getAndRenderSchedule();
            this.closePopup();
        }, err => {
            console.log(err);
            this.popupError = '';
            this.dataService.showHideLoader(false);
        });
    }

    onDeleteTimeOff(): void {
        this.showConfirmDelete = true;
    }

    confirmDelete(): void {
        this.dataService.showHideLoader(true);
        this.scheduleService.deleteTimeOff(this.popupData.timeoffId)
        .subscribe(res => {
            this.setEnvForAllocatedFirst();
            this.getAndRenderSchedule();
            this.closePopup();
        }, err => {
            console.log(err);
            this.dataService.showHideLoader(false);
        });
    }

    onEditTimeOff(e): void {
        e.stopPropagation();
        this.popupData.disableSubmit = false;
        if (this.popupData.status === 'leave') {
            this.timeOffType = 'leave';
        } else if (this.popupData.status === 'unavailable') {
            this.timeOffType = 'unavailable';
        }
        if (this.popupData.comment) {
            this.showCommentInput = true;
        } else {
            this.showCommentInput = false;
        }
        this.popupData.popupType = 'addTimeOff';
    }

    hasScrollbar(): boolean {
        let scrollDiv = document.getElementById('scrollId');
        let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        return scrollbarWidth > 0;
    }

}
