import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../shared/services/data.service';

@Component({
    selector: 'resend-confirmation-email',
    templateUrl: 'resend-confirmation-email.component.html'
})

export class ResendConfirmationEmailComponent {
    showProgress = false;
    email = '';
    preApiErrorMessage = '';
    postApiErrorMessage = '';
    successMessage = '';
    dataService;
    regexForEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    constructor(
        dataService: DataService,
        private router: Router
    ) {
        this.dataService = dataService;
    }

    goToLogin(): void {
        this.router.navigate(['login']);
    }

    resendConfirmationEmail(): void {
        if (this.showProgress) return;
        this.resetAllMessages();
        if (!this.email) {
            this.preApiErrorMessage = 'Please enter email';
            return;
        }
        if (!this.regexForEmail.test(this.email)) {
            this.preApiErrorMessage = 'Invalid email address';
            return;
        }
        this.showProgress = true;
        this.dataService.resendConfirmationEmail(this.email)
        .subscribe(res => {
            this.showProgress = false;
            this.successMessage = 'Email sent successfully';
            sessionStorage.setItem('email', this.email);
            this.router.navigate(['register/otp']);
        }, error => {
            this.showProgress = false;
            if (error.error.errors.email) {
                this.postApiErrorMessage = `Email ${error.error.errors.email[0]}`;
            }
        });
    }

    resetAllMessages(): void {
        this.preApiErrorMessage = '';
        this.postApiErrorMessage = '';
        this.successMessage = '';
    }

}