import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataService } from '../shared/services/data.service';
import { HttpService } from '../shared/services/http.service';

@Injectable()
export class TutorialService {

  constructor(private dataService: DataService, private httpService: HttpService) {}

  gotIt(screens: any[]): Observable<any> {
    const url = `${this.dataService.getServerUrl()}vendor/profile`;
    const body = {
      vendor: {
        tutorials: screens
      }
    }
    return this.httpService.putRequest(url, body);
  }

}
