import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../../shared/services/data.service';
import { RegisterService } from '../../register.service';

@Component({
    selector: 'app-team-structure',
    templateUrl: './team-structure.component.html',
    styleUrls: ['./team-structure.component.scss']
})

export class TeamStructureComponent implements OnInit {
    teamStructures: any[] = [];
    user;
    editMode = false;
    dataService;

    constructor(
        dataService: DataService,
        private router: Router,
        private registerService: RegisterService
    ) { 
        this.dataService = dataService;
    }

    ngOnInit() {
        this.getTeamStructures();
    }

    private getTeamStructures() {
        this.dataService.showHideLoader(true);
        this.dataService.getTeamStructures()
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            this.teamStructures = res.data;
            this.teamStructures = this.teamStructures.filter(x => {
                x.skills = x.skills.filter(skill => skill.urgency != 'absent');
                const absentCount = x.skills.filter(skill => skill.urgency === 'absent').length;
                if (absentCount !== x.skills.length) {
                    return x;
                }
            });
            this.getUserProfile();
        }, err => {
            this.dataService.showHideLoader(false);
        });
    }

    getUserProfile(): void {
        this.user = this.dataService.getUser();
        if (this.user.skills && this.user.skills.length > 0) {
            this.preFillData();
        }
    }

    preFillData(): void {
        this.editMode = true;
        for(let userSkill of this.user.skills) {
            for(let teamStructure of this.teamStructures) {
                for(let skill of teamStructure.skills) {
                    if (userSkill.id === skill.id) {
                        skill.selected = true;
                    }
                }
            }
        }
    }

    onBack(): void {
        const VendorUser = this.dataService.getUser();
        VendorUser.present_state = 'business_spoc';
        this.dataService.setUser(VendorUser);
        this.router.navigate(['register/confirmed/3']);
    }

    finishRegistration(): void {
        this.dataService.showHideLoader(true);
        let teamStructureIds = [];
        let teamStructureNames = [];
        for(let teamStructure of this.teamStructures) {
            for(let skill of teamStructure.skills) {
                if (skill.selected) {
                    teamStructureIds.push({id: skill.id});
                    teamStructureNames.push(skill.title);
                }
            }
        }
        this.registerService.updateUserWithTeamStructure(teamStructureIds, this.editMode)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            this.dataService.setUser(res.data);
            this.router.navigate(['contract']);
        }, err => {
            this.dataService.showHideLoader(false);
        });
        this.dataService.mixpanel_events_track('event', 'Team view', {'team structure': teamStructureNames});
        this.dataService.mixpanel_events_track('people', '', {'team structure': teamStructureNames});
    }
    

}
