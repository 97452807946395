import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { DataService } from '../shared/services/data.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private dataService: DataService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers;
    headers = headers.append('x-nst', this.dataService.getSecurityParams().nonceString);
    headers = headers.append('x-ct', this.dataService.getSecurityParams().currentTimestamp);
    headers = headers.append('x-hst', this.dataService.getSecurityParams().hashedString);
    if (this.dataService.getAuthToken()) headers = headers.append('authtoken', this.dataService.getAuthToken());
    request = request.clone({headers});
    return next.handle(request);
  }
  
}
