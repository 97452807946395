import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { RequestsService } from '../../requests/requests.service';
import { IMyDateModel, IMyDpOptions, IMySelector } from 'mydatepicker';
import { Angulartics2Segment } from 'angulartics2/segment';
import * as moment from 'moment';
import { TutorialService } from '../../../tutorial/tutorial.service';

@Component({
    selector: 'manage-request',
    templateUrl: './manage-request.component.html',
    styleUrls: ['./manage-request.component.scss']
})

export class ManageRequestComponent implements OnInit {
    @Input() allocation;
    @Input() memberInfo: any;
    @Output() onCloseManageRequest = new EventEmitter<any>();
    dataService;
    showProjectInfo = false;
    showDeclinePopup = false;
    declineAllocation: any;
    declineStatus: any;
    selectedListItem: any;
    selectedListItemVal: any;
    concerns = [];
    otherReason = '';
    unavail_from = '';
    unavail_to = '';
    durationError = '';
    concernSuccess = false;
    mem_unavail_from = '';
    mem_unavail_to = '';
    popupData: any;
    animationActive = false;

    // Initialize selector state to false
    private selector1: IMySelector = {
        open: false
    };
    private selector2: IMySelector = {
        open: false
    };
    myDatePickerOptions1: IMyDpOptions = {
        dateFormat: 'yyyy-mm-dd',
        showInputField: true,
        showClearDateBtn: false
    };
    myDatePickerOptions2: IMyDpOptions = {
        dateFormat: 'yyyy-mm-dd',
        showInputField: true,
        showClearDateBtn: false,
        alignSelectorRight: true
    };
    showInfo = '';
    showTutorialSlide = 'first';

    constructor(dataService: DataService, private requestsService: RequestsService, private analyticsSegment: Angulartics2Segment, private tutorialService: TutorialService, private cdRef: ChangeDetectorRef) {
        this.dataService = dataService;
    }

    ngOnInit() {
        this.getConcerns();
        setTimeout(() => {
            this.animationActive = true;
            this.cdRef.detectChanges();
        }, 300);

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.allocation && changes.allocation.currentValue) {
            this.showProjectInfo = true;
            this.showInfo = 'project';
        }
    }

    closepicker1(event: number): void {
        // if(event === 1){
        //     this.selector2 = {
        //         open: true
        //     };
        // }else if(event !== 6){
        //     this.selector2 = {
        //         open: false
        //     };
        // }
        // this.selector1 = {
        //     open: false
        // };
    }
    closepicker2(event: number): void {
        // if(event === 1){
        //     this.selector1 = {
        //         open: true
        //     };
        // }else if(event !== 6){
        //     this.selector1 = {
        //         open: false
        //     };
        // }
        // this.selector2 = {
        //     open: false
        // };
    }
    getConcerns() {
        this.requestsService.getConcerns()
        .subscribe(concerns => {
            for(let concernData of concerns.data){
                if(concernData === 'The member is not available'){
                    concernData = this.memberInfo.name+' is not available';
                }
                this.concerns.push(concernData);
            }
        }, error => {
            console.log(error);
        });
    }

    closeManageRequestPopup() {
        this.onCloseManageRequest.emit();
    }

    updateTutorial(tutorialScreen: string): void {
        const user = this.dataService.getUser();
        if (user.tutorials.indexOf(tutorialScreen) >= 0) return;
        user.tutorials.push(tutorialScreen);
        if(tutorialScreen == 'firstTimeAllocation'){
            user.tutorials.push('ProjectRequestsToFeatureRequests');
        }
        this.tutorialService.gotIt(user.tutorials)
        .subscribe(res => {
            this.dataService.setUser(res.data);
        }, err => {
            console.log(err);
        });
    }

    onRequestDecline(allocation: any, status: string): void {
        this.showProjectInfo = false;
        this.showDeclinePopup = true;
        this.declineAllocation = allocation;
        this.declineStatus = status;
      }

    submitDeclineReason(): void {
        if (!this.selectedListItem) return;
          let rejectedReason = '';
          if (this.selectedListItemVal === 'Other' && !this.otherReason) return;
          if (this.selectedListItemVal === 'Other' && this.otherReason) {
            rejectedReason = this.otherReason;
          } else {
            rejectedReason = this.concerns.find((data, index) => index === (this.selectedListItem-1));
          }
          if(this.selectedListItem !== 1){
            this.unavail_from = '';
            this.unavail_to = '';
          } else if(this.selectedListItem === 1){
            rejectedReason = rejectedReason+` From: ${this.unavail_from} To: ${this.unavail_to}`;
          }
          if (moment(this.unavail_to).isBefore(moment(this.unavail_from))) {
                this.durationError = 'From cannot be greater than to';
                return;
            }
          this.handleRequest(this.declineAllocation, this.declineStatus, rejectedReason, this.unavail_from, this.unavail_to);

      }

      handleRequest(allocation: any, status: string, rejectedReason = '', unavail_from = '', unavail_to = ''): void {
        const venderUser = this.dataService.getUser();
        allocation.allocationAction = status === 'accepted' ? 'approve' : 'decline';
        allocation.hasPendingExtension = (allocation.allocation_status === 'accepted' && allocation.extension_type === 'extension' && allocation.extension_status === 'epending') ? true : false;
        this.dataService.showHideLoader(true);
        this.requestsService.handleRequest(allocation.allocation_id, status, allocation.allocationAction, rejectedReason, unavail_from, unavail_to, allocation.hasPendingExtension)
        .subscribe(res => {
            this.analyticsSegment.eventTrack('Request feedback', {
                feedback: allocation.allocationAction,
                email: venderUser ? venderUser.email : ''
            });
            allocation.allocationAction = '';
            this.dataService.showHideLoader(false);
            this.onCloseManageRequest.emit({allocation, status, unavail_from, unavail_to});
        }, err => {
            allocation.allocationAction = '';
            this.dataService.showHideLoader(false);
            if (err.error) {
              const error = err.error;
              if (error.message) {
                this.dataService.areServicesRunning = false;
                this.dataService.sharedServiceType = 'allocationError';
                setTimeout(x => {
                  this.dataService.areServicesRunning = true;
                }, 5000);
                this.dataService.allocationId = '';
              }
            }
        });
    }

    onOptionClick(option, concern){
        this.selectedListItem = option;
        this.selectedListItemVal = concern;
        if(this.selectedListItem !== 1){
            this.unavail_from = '';
            this.unavail_to = '';
        }
    }

    onPopupDateChanged(event: IMyDateModel, dateType: string): void {
        if (dateType === 'from') {
            this.unavail_from = `${event.formatted}`;
        } else if (dateType === 'to') {
            this.unavail_to = `${event.formatted}`;
        }
    }

    backToProjectPopup(): void {
        this.showDeclinePopup = false;
        this.showProjectInfo = true;
    }

    formatDate(date: string) {
        return date.split(' - ').map(x => moment(x, 'YYYY-MM-DD').format('D MMM')).join(' - ');
    }

}
