import { Component, OnInit } from '@angular/core';
import { DataService } from "../../../shared/services/data.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-confirm-payment-detail',
  templateUrl: './confirm-payment-detail.component.html',
  styleUrls: ['./confirm-payment-detail.component.scss']
})
export class ConfirmPaymentDetailComponent {

  vendorData:any;
  token: string;
  dataService: any;

  constructor(dataService: DataService, private router: Router) { 
    this.dataService = dataService;
    this.vendorData = dataService.vendorProfileData;
  }

  cancelPaymentDetails(){
    this.dataService.cancelPaymentDetails = true;
    this.router.navigate(['/vendor/billing/invoices/payment-detail']);
  }

  confirmPaymentDetails(){
    this.dataService.showHideLoader(true);
    this.dataService.updatePaymentDetails(this.vendorData)
    .subscribe(res => {
      this.dataService.setUser(res.data);
      this.dataService.showHideLoader(false);
      this.dataService.cancelPaymentDetails = false;
      this.router.navigate(['/vendor/billing/invoices/invoice-listing']);
    }, error => {
      console.log(error);
      this.dataService.showHideLoader(false);
    });
  }

}
