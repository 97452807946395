import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "../../../shared/services/data.service";
import { RateCardService } from "../rate-card.service";

@Component({
  selector: 'app-rate-card-detail',
  templateUrl: './rate-card-detail.component.html',
  styleUrls: ['./rate-card-detail.component.scss']
})
export class RateCardDetailComponent implements OnInit {
  rateCardService;
  constructor(public dataService: DataService, 
              rateCardService: RateCardService,
              private route: ActivatedRoute
        ) {
          this.rateCardService = rateCardService;
         }

  ngOnInit() {
        this.dataService.showHideLoader(true);
        this.route.params
        .subscribe(params => {
            this.getRateCardDetails(params.id);
        });
  }

  getRateCardDetails(id){
    this.rateCardService.getRateCardDetails(id)
    .subscribe(res => {
      this.dataService.showHideLoader(false);
      this.rateCardService.clickedScreen = "newRateCardView";
      this.rateCardService.currentRateCard = res.data;
    });
  }

}
