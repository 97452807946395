import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2Segment } from "angulartics2/segment";

import { RegisterService } from '../register.service';
import { DataService } from '../../shared/services/data.service';

@Component({
    selector: 'otp',
    templateUrl: 'otp.component.html'
})

export class OtpComponent implements OnInit {
    @ViewChild('otpInput1', {static: true}) otpInput1: any;
    @ViewChild('otpInput2', {static: true}) otpInput2: any;
    @ViewChild('otpInput3', {static: true}) otpInput3: any;
    @ViewChild('otpInput4', {static: true}) otpInput4: any;
    otp1 = '';
    otp2 = '';
    otp3 = '';
    otp4 = '';
    postApiError = '';
    email = '';
    private readonly NUMBER_KEYCODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8];
    dataService;

    constructor(
        private router: Router,
        private registerService: RegisterService,
        dataService: DataService,
        private analytics: Angulartics2Segment
    ) {
        this.dataService = dataService;
    }

    ngOnInit(): void {
        this.email = sessionStorage.getItem('email');
        if (!this.email) {
            this.router.navigate(['login']);
        }
        this.otpInput1.nativeElement.focus();
    }

    onKeyup(blockNumber: number, event: any): void {
        if (this.NUMBER_KEYCODES.indexOf(event.which) === -1) return;
        if (blockNumber === 1) {
            if (event.which !== 8) {
                this.otpInput2.nativeElement.focus();
            }
        } else if (blockNumber === 2) {
            if (event.which === 8) {
                this.otpInput1.nativeElement.focus();
            } else {
                this.otpInput3.nativeElement.focus();
            }
        } else if (blockNumber === 3) {
            if (event.which === 8) {
                this.otpInput2.nativeElement.focus();
            } else {
                this.otpInput4.nativeElement.focus();
            }
        } else if (blockNumber === 4) {
            if (event.which === 8) {
                this.otpInput3.nativeElement.focus();
            }
        }
        // if (this.otp1 && this.otp2 && this.otp3 && this.otp4) {
        //     this.submitOtp();
        // }
    }

    resendCode(): void {
        this.router.navigate(['resend-confirmation-email']);
    }

    submitOtp(): void {
        if (!(this.otp1 && this.otp2 && this.otp3 && this.otp4)) return;
        this.dataService.showHideLoader(true);
        this.postApiError = '';
        this.registerService.submitOtp(`${this.otp1}${this.otp2}${this.otp3}${this.otp4}`)
        .subscribe(res => {
            sessionStorage.removeItem('email');
            this.dataService.setAuthToken(res.data.authtoken);
            delete res.data.authtoken;
            this.dataService.setUser(res.data);
            this.analytics.setAlias(res.id);
            this.dataService.showHideLoader(false);
            this.dataService.mixpanel_events_track('event', 'Email verify');
            this.router.navigate(['register/confirmed/1']);
        }, err => {
            this.dataService.showHideLoader(false);
            const error = err.error;
            if (error.errors && error.errors.confirmation_token) {
                this.postApiError = `OTP ${error.errors.confirmation_token[0]}`;
            }
        });
    }

}
