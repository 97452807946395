import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MyDatePickerModule } from 'mydatepicker';

import { SharedModule } from '../../shared/shared.module';
import { InvoiceComponent } from './invoice.component';
import { InvoiceMessageComponent } from './invoice-message/invoice-message.component';
import { TutorialModule } from '../../tutorial/tutorial.module';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { RouterModule } from '@angular/router';
import { ConfirmPaymentDetailComponent } from './confirm-payment-detail/confirm-payment-detail.component';
import { InvoiceListingComponent } from './invoice-listing/invoice-listing.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { SubmitHoursComponent } from './submit-hours/submit-hours.component';
import { ToasterModule } from '../../toaster/toaster.module';

@NgModule({
    imports: [
        FormsModule,
        SharedModule,
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        InfiniteScrollModule,
        MyDatePickerModule,
        TutorialModule,
        RouterModule,
        ToasterModule
    ],
    declarations: [
        InvoiceComponent,
        InvoiceMessageComponent,
        PaymentDetailComponent,
        ConfirmPaymentDetailComponent,
        InvoiceListingComponent,
        InvoiceDetailsComponent,
        SubmitHoursComponent
    ]
})

export class InvoiceModule { }
