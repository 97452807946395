import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { Ng2SearchPipe } from './pipes/search.pipe';
import { HttpService } from './services/http.service';
import { PhaseService } from './services/phase.service';
import { InvoiceService } from './services/invoice.service';
import { CustomValidators } from './services/validators.service';
import { GoogleplaceDirective } from './directives/googleplace.directive';
import { CountryCodesDirective } from './directives/country-codes.directive';


@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule],
  declarations: [Ng2SearchPipe, GoogleplaceDirective, CountryCodesDirective],
  exports: [Ng2SearchPipe, GoogleplaceDirective, CountryCodesDirective],
  providers: [HttpService, PhaseService, CustomValidators, InvoiceService]
})

export class SharedModule {}
