import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { RegisterService } from '../register.service';
import { DataService } from '../../shared/services/data.service';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
    selector: 'email-password',
    templateUrl: 'email-password.component.html'
})

export class EmailPasswordComponent implements OnInit {
    @ViewChild('emailEl', {static: true}) emailEl: any;
    email = '';
    password = '';
    passwordError = '';
    emailError = '';
    checkbox1Error = false;
    checkbox2Error = false;
    postApiError = '';
    inputType = 'password';
    termsAndCondition = false;
    privacyPolicy = false;
    dataService;
    blockedDomains = [];
    private readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    constructor(
        private router: Router,
        private registerService: RegisterService,
        dataService: DataService,
        private analyticsSegment: Angulartics2Segment
    ) {
        this.dataService = dataService;
    }

    ngOnInit(): void {
        window['analytics'].reset();
        this.analyticsSegment.eventTrack('Registration page', {});
        this.getBlockedDomains();
    }

    getBlockedDomains(): void {
        this.dataService.showHideLoader(true);
        this.dataService.getBlockedDomains().subscribe(res => {
            this.blockedDomains = res.data;
            this.emailEl.nativeElement.focus();
        }, err => {}, () => this.dataService.showHideLoader(false));
    }

    navigateToLogin(): void {
        this.router.navigate(['login']);
    }

    getStarted(): void {
        this.dataService.mixpanel_events_track('event', 'Get started');
        if (this.formHasError()) return;
        this.dataService.showHideLoader(true);
        this.postApiError = '';
        this.registerService.registerVendor(this.email, this.password)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            sessionStorage.setItem('email', this.email);
            this.router.navigate(['register/otp']);
        }, err => {
            this.dataService.showHideLoader(false);
            const error = err.error;
            if (error.message) {
                this.postApiError = error.message;
            }
        });
    }

    formHasError(): boolean {
        let formHasError = false;
        this.emailError = '';
        this.passwordError = '';
        this.checkbox1Error = false;
        this.checkbox2Error = false;
        if (!this.email) {
            this.emailError = 'Please enter email address';
            formHasError = true;
        } else if (!this.EMAIL_REGEX.test(this.email.toLowerCase())) {
            this.emailError = 'Please enter valid email address';
            formHasError = true;
        } else if (this.blockedDomains.indexOf(this.email.toLowerCase().split('@')[1]) >= 0) {
            this.emailError = 'Public domains are not allowed';
            formHasError = true;
        }
        if (!this.password) {
            this.passwordError = 'Please enter password';
            formHasError = true;
        } else if (!this.isValidPassword()) {
            this.passwordError = 'Please enter atleast 8 characters';
            formHasError = true;
        }
        if (!this.privacyPolicy) {
            this.checkbox1Error = true;
            formHasError = true;
        }
        if (!this.termsAndCondition) {
            this.checkbox2Error = true;
            formHasError = true;
        }
        return formHasError;
    }

    private isValidPassword(): boolean {
        return this.password.length >= 8;
    }

    isEmailValid(email: string): boolean {
        return this.EMAIL_REGEX.test(email.toLowerCase()) && this.blockedDomains.indexOf(this.email.toLowerCase().split('@')[1]) === -1;
    }

    checkForError(type: string, e: any): void {
        if (e.which === 13) return;
        if (type === 'email') {
            if (!this.email) {
                this.emailError = 'Please enter email address';
            } else if (!this.EMAIL_REGEX.test(this.email.toLowerCase())) {
                this.emailError = 'Please enter valid email address';
            } else if (this.blockedDomains.indexOf(this.email.toLowerCase().split('@')[1]) >= 0) {
                this.emailError = 'Public domains are not allowed';
            } else {
                this.emailError = '';
            }
        } else if (type === 'password') {
            if (!this.password) {
                this.passwordError = 'Please enter password';
            } else if (!this.isValidPassword()) {
                this.passwordError = 'Please enter atleast 8 characters';
            } else {
                this.passwordError = '';
            }
        }
    }

    onPrivacyPolicyChange(): void {
        if (this.privacyPolicy) {
            this.checkbox1Error = false;
        } else {
            this.checkbox1Error = true;
        }
    }

    onTermsAndConditionChange(): void {
        if (this.termsAndCondition) {
            this.checkbox2Error = false;
        } else {
            this.checkbox2Error = true;
        }
    }

    // mxp_focusoutHandler(type='event', eventName='', event){
    //     jsonData = {}
    //     this.dataService.mixpanel_events_track(type, eventName);
    // }

}
