import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../shared/services/data.service';
import { PhaseService } from '../../shared/services/phase.service';
import { HttpService } from '../../shared/services/http.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { TutorialService } from '../../tutorial/tutorial.service';

@Component({
  selector: 'invoice',
  templateUrl: 'invoice.component.html',
  styleUrls: ['invoice.component.scss']
})

export class InvoiceComponent implements OnInit {
  uploadBillErr: string;
  isInvoiceUploaded = false;
  isUploadInvoicePopupOpen = false;
  invoiceBill: File;
  invoices: any[];
  uploadInvoiceFromDate: any;
  uploadInvoiceToDate: any;
  showUploadBtnSpinner = false;
  filesNotAllowedErr: string;
  noFilteredInvoices = false;
  phaseService;
  dataService;
  showInvoiceTutorial = false;
  invoiceService;

  constructor(
    dataService: DataService,
    phaseService: PhaseService,
    private httpService: HttpService,
    invoiceService: InvoiceService,
    private router: Router,
    private tutorialService: TutorialService
  ) {
    this.dataService = dataService;
    this.phaseService = phaseService;
    this.phaseService.leftMenuActive = 'billing';
    this.invoiceService = invoiceService;
  }

  ngOnInit(): void {
  }

}
