import { Directive, ElementRef, HostListener, Input, Renderer, OnInit } from '@angular/core';
import { DataService } from "../services/data.service";

@Directive({
  selector: '[appCountryCodes]'
})
export class CountryCodesDirective {
  @Input() selectedCountryCode;
  countryCodes: Array<any> = [];
  el: ElementRef;
  renderer: Renderer;

  constructor(el: ElementRef, renderer: Renderer, private dataService: DataService) {
   this.renderCountryCodes(el, renderer);
  }

  renderCountryCodes(el, renderer): void{
    this.dataService.getCountryCodes()
      .subscribe(countryCodes => {
        this.renderHtml(countryCodes, el, renderer);
      });
  }

  renderHtml(countryCodes, el, renderer): void{
    let optionHtml = '';
    for(let countryCode of countryCodes){
      const selected = (countryCode.value == this.selectedCountryCode) ? 'selected' : '';
      optionHtml = optionHtml + `<option value="${countryCode.value}" ${selected}>${countryCode.key}</option>`;
    }
    renderer.setElementProperty(el.nativeElement, 'innerHTML', optionHtml);
  }

}
