import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notification-less-more',
  templateUrl: './notification-less-more.component.html',
  styleUrls: ['./notification-less-more.component.scss']
})
export class NotificationLessMoreComponent implements OnInit {

  @Input('notification') notification: any;
  limit = 4;
  show: boolean = true;
  constructor() { }

  ngOnInit() {
  }

  viewMore(notification){
    this.limit=notification.data.length;
    this.show = false;
  }



}
