import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, HostListener, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';

import { DataService } from '../../shared/services/data.service';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
    selector: 'invite-members',
    templateUrl: './invite-members.component.html',
    styleUrls: ['./invite-members.component.scss']
})

export class InviteMembersComponent implements OnInit {
    private readonly PER_PAGE = 10;
    pendingDevs = [];
    page = 1;
    meta: any;
    isScrolled = false;
    devUniqCode = '';
    showCopiedLinkPopup = false;
    form: FormGroup;
    inviteMembersError = '';
    inviteMembersSuccess = '';
    companyDomains = [];
    blockedDomains = [];
    private readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    actionButtons = false;
    showToaster = false;
    toasterData:any = {};

    constructor(
        public dataService: DataService,
        private fb: FormBuilder,
        private cdRef: ChangeDetectorRef,
        private analyticsSegment: Angulartics2Segment
    ) {
        this.devUniqCode = window.location.origin + '/#/join/' + this.dataService.getUser().uniq_code;
        this.companyDomains = this.dataService.getUser().domain_names;
    }

    ngOnInit(): void {
        this.getBlockedDomains();
    }

    getBlockedDomains(): void {
        this.dataService.showHideLoader(true);
        this.dataService.getBlockedDomains(this.dataService.getUser().email).subscribe(res => {
            this.blockedDomains = res.data;
            this.filterCompanyDomains();
            this.form = this.fb.group({
                inviteInputs: this.fb.array([
                   this.getInviteInputs()
                ])
            });
            this.getPendingDevelopers();
        }, err => {}, () => this.dataService.showHideLoader(false));
    }

    filterCompanyDomains(): void {
        this.companyDomains = this.companyDomains.filter(domain => {
            return this.blockedDomains.indexOf(domain) === -1;
        });
    }

    onDomainChange(inviteInput: any, index: number): void {
        this.onKeyupComplete(inviteInput, index);
    }

    getInviteInputs(): any {
        return this.fb.group({
            email: ['', [Validators.required, this.usernameValidator]],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            selectedDomain: [this.companyDomains.length > 0 ? this.companyDomains[0] : '' , Validators.required]
        });
    }

    usernameValidator(control: AbstractControl): { [key: string]: boolean } | null {
        if (!control.value) return null;
        const isValid = control.value.indexOf('@') === -1;
        return isValid ? null : { 'username': true };
    }

    getPendingDevelopers(): void {
        this.dataService.showHideLoader(true);
        this.dataService.getPendingDevelopers(this.PER_PAGE, this.page)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            if (this.isScrolled) {
                this.isScrolled = false;
                this.pendingDevs = this.pendingDevs.concat(res.data);
            } else {
                this.pendingDevs = res.data;
            }
            this.meta = res.meta;
            this.cdRef.detectChanges();
        }, err => {
            this.dataService.showHideLoader(false);
            console.log(err);
        });
    }

    closeInviteMembers(): void {
        this.resetComponent();
        this.dataService.showInviteMembers = false;
        this.dataService.classInviteMembers = false;
        this.inviteMembersError = '';
        this.inviteMembersSuccess = '';
    }

    onScroll(): void {
        if (this.meta && this.meta.next_page !== null && !this.isScrolled) {
            this.isScrolled = true;
            this.page++;
            this.getPendingDevelopers();
        }
    }

    resendInvitation(dev: any){
        this.dataService.showHideLoader(true);
        this.dataService.resendDevInvitation([dev.id])
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            this.showToaster = true;
            this.toasterData.text = 'Invitation Resend successfully to ' + dev.name;
            this.toasterData.status = 'success';
        }, err => {
            this.dataService.showHideLoader(false);
            console.log(err);
        });
    }

    deleteDev(dev: any): void {
        const vendorUser = this.dataService.getUser();
        this.dataService.showHideLoader(true);
        this.dataService.mixpanel_events_track('event', 'Delete invite', {'partner name': vendorUser ? vendorUser.name : '', 'creator email': dev ? dev.email : ''});
        this.dataService.mixpanel_events_track('people', '', {'creator email': dev ? dev.email : ''});
        this.dataService.deleteDevelopers([dev.id])
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            const devIndex = this.pendingDevs.findIndex(x => x.id === dev.id);
            this.pendingDevs.splice(devIndex, 1);
            this.cdRef.detectChanges();
        }, err => {
            this.dataService.showHideLoader(false);
            console.log(err);
        });
    }

    copyInviteLink(): void {
        const vendorUser = this.dataService.getUser();
        let el = document.createElement('input');
        document.body.appendChild(el);
        el.setAttribute('value', this.devUniqCode);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        this.showCopiedLinkPopup = true;
        this.analyticsSegment.eventTrack('Link share', {email: vendorUser ? vendorUser.email : ''});
        setTimeout(() => {
            this.showCopiedLinkPopup = false;
            this.cdRef.detectChanges();
        }, 2000);
    }

    isSendInviteDisabled(): boolean {
        let disabled = true;
        for (let inviteInput of this.form.controls.inviteInputs['controls']) {
            if (inviteInput.valid) {
                disabled = false;
            }
        }
        if (this.companyDomains.length === 0) disabled = true;
        return disabled;
    }

    onKeyupComplete(inviteInput: any, index: number): void {
        if (inviteInput.controls.email.valid && inviteInput.controls.firstName.valid && inviteInput.controls.lastName.valid && inviteInput.controls.selectedDomain.valid && this.companyDomains.length > 0 && index === this.form.controls.inviteInputs['controls'].length-1) {
            const control = <FormArray>this.form.controls['inviteInputs'];
            control.push(this.getInviteInputs());
        }
    }

    removeInviteInput(i: number): void {
        const control = <FormArray>this.form.controls['inviteInputs'];
        control.removeAt(i);
    }

    inviteMembers(): void {
        if (this.isSendInviteDisabled()) return;
        let membersToBeInvited = [];
        this.inviteMembersError = '';
        this.inviteMembersSuccess = '';
        for (let inviteInput of this.form.controls.inviteInputs['controls']) {
            if (inviteInput.valid) {
                membersToBeInvited.push({email: `${inviteInput.controls.email.value}@${inviteInput.controls.selectedDomain.value}`, first_name: inviteInput.controls.firstName.value, last_name: inviteInput.controls.lastName.value})
            }
        }
        this.dataService.showHideLoader(true);
        const vendorUser = this.dataService.getUser();
        this.dataService.inviteMembers(membersToBeInvited)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            this.analyticsSegment.eventTrack('Send Invites', {
                dev_invited: membersToBeInvited.length,
                email: vendorUser ? vendorUser.email : ''
            });
            this.setMixPanelEvents(res);
            this.resetComponent();
            this.inviteMembersSuccess = 'You have successfully invited all members';
            setTimeout(() => {
                this.inviteMembersSuccess = '';
                this.cdRef.detectChanges();
            }, 3000);
            this.getPendingDevelopers();
        }, err => {
            this.dataService.showHideLoader(false);
            if (err) {
                const error = err.error;
                this.inviteMembersError = error.message;
            }
            this.cdRef.detectChanges();
        });
    }

    setMixPanelEvents(res): void {
        this.analyticsSegment.setUserProperties({
            total_invited: res.statistics.total_invited,
            dev_count: res.statistics.developer_count
        });
    }

    resetComponent(): void {
        this.page = 1;
        this.form.reset();
        const inputsLength = this.form.controls.inviteInputs['controls'].length;
        for (let i=0; i < inputsLength; i++) {
            this.removeInviteInput(0);
        }
        const control = <FormArray>this.form.controls['inviteInputs'];
        control.push(this.getInviteInputs());
    }

    @HostListener('document:keydown.escape', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const keyPressed = event.which || event.keyCode;
        if (keyPressed === 27) {
            this.closeInviteMembers();
        }
    }

    closeToaster(){
        this.showToaster = false;
    }

}
