import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'partner-agreement',
  templateUrl: './partner-agreement.component.html',
  styleUrls: ['./partner-agreement.component.scss']
})
export class PartnerAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
