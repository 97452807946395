import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../../shared/services/data.service';

@Component({
    selector: 'right-panel',
    templateUrl: './right-panel.component.html',
    styleUrls: ['./right-panel.component.scss']
})

export class RightPanelComponent implements OnChanges {
    @Input() projectDetail: any;
    @Input() memberInfo: any;
    @Output() onCloseRightPanel = new EventEmitter<string>();
    @Output() onSetupRates = new EventEmitter<any>();
    showInfo = '';
    copiedToClipboard:boolean = false;
    skillSlideConfig = {slidesToShow: 2, slidesToScroll: 1, infinite: false};
    dataService: any;

    constructor(
        private router: Router,
        dataService: DataService,
        private cdRef: ChangeDetectorRef
    ) { 
        this.dataService = dataService;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectDetail && changes.projectDetail.currentValue) {
            this.showInfo = 'project';
            this.onCloseRightPanel.emit('memberInfo');
        }
        if (changes.memberInfo && changes.memberInfo.currentValue) {
            this.showInfo = 'member';
            this.onCloseRightPanel.emit('projectDetail');
        }
    }

    copyToClipboard(val){
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.copiedToClipboard = true;
        setTimeout(() => {
            this.copiedToClipboard = false;
            this.cdRef.detectChanges();
        }, 2000);
    }

    setupRateCard(dev): void {
        this.dataService.mixpanel_events_track('event', 'Add to rate card');
        this.onSetupRates.emit(dev);
        this.onCloseRightPanel.emit("memberInfo");
    }

    closeRightPanel(type: string): void {
        this.showInfo = '';
        this.onCloseRightPanel.emit(type);
    }

    downloadFile(name: string, attachment: string): void {
        let anchor = document.createElement('a');
        anchor.setAttribute('href', attachment);
        anchor.setAttribute('target', '_blank');
        anchor.setAttribute('download', name);
        anchor.click();
    }

}
