import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import {DataService} from '../../shared/services/data.service';
import {Router} from '@angular/router';
import {PhaseService} from '../../shared/services/phase.service';
import { TutorialService } from '../../tutorial/tutorial.service';
import { RateCardService } from "../rate-card/rate-card.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
    public rateCardService;
    public notificationList = [];
    public markAllRead: boolean;
    public notificationId = '';
    public isScrolled = false;
    dataService;
    public page = 1;
    phaseService;
    pixelsFromTop = 0;
    meta;
    isNewRateCardCreatedObservable: Subscription;
    // showSuggestiveRateCardPopup = false;
    // rateCardsForDevSkill = [];
    // devAlreadyLinkedToRateCard = false;
    // devName = '';
    // devPic:string;
    // selectedRateCardForPopup:any;
    // autoAddCheckBoxValue:boolean = true;
    // rateCardLinkToastData = {
    //     showToastMsg: false,
    //     success: true,
    //     toastMsg: ''
    // };
    // autoAddPopupDoneButtonDisabled:boolean = true;
    // rateCardListTotalLength:number;
    // rateCardListLengthLimit:number = 3;
    // showAllButton:boolean = true;

    constructor(
        dataService: DataService,
        rateCardService: RateCardService,
        private router: Router,
        phaseService: PhaseService,
        private renderer: Renderer2,
        private tutorialService: TutorialService
    ) {
        this.dataService = dataService;
        this.phaseService = phaseService;
        this.rateCardService = rateCardService;
        this.phaseService.leftMenuActive = 'inbox';
        this.isNewRateCardCreatedObservable = this.dataService.isNewRateCardCreated.subscribe(() => {
            this.notificationList = [];
            this.page = 1;
            this.isScrolled = false;
            this.getListOfNotifications();
            this.phaseService.isBellClicked = false;
        });
    }

    ngOnInit() {
        this.getListOfNotifications();
        this.phaseService.isBellClicked = false;
        // this.phaseService.showLinkDevToRateCardPopup = false;
        // if(this.dataService.createRateCardThroughAutoAddPopup && this.dataService.createRateCardThroughAutoAddPopupHaveDone || this.dataService.closeRateCardPopupAfterComingFromAutoAdd){
        //     this.phaseService.showLinkDevToRateCardPopup = true;
        //     this.suggestiveRateCardPopup();
        //     this.dataService.closeRateCardPopupAfterComingFromAutoAdd = false;
        // }
    }

    ngOnDestroy(){
        this.rateCardService.notificationRateCard = '';
        if (this.isNewRateCardCreatedObservable) this.isNewRateCardCreatedObservable.unsubscribe();
    }

    public getListOfNotifications() {
        if (!this.isScrolled) {
            this.phaseService.isBellClicked = !this.phaseService.isBellClicked;
            this.dataService.showHideLoader(true);
        }
        this.dataService.getAllNotifications(this.page).subscribe((response) => {
            this.dataService.notificationCount = 0;
            if (this.isScrolled) {
                this.isScrolled = false;
                this.notificationList = this.notificationList.concat(response.data);
            } else {
                this.notificationList = response.data;
                this.dataService.showHideLoader(false);
            }

            setTimeout(() => {
                this.checkForAllocationTutorial();
            });
            // this.isScrolled = false;
            this.phaseService.devsInNotification = response.data;
            // this.page = response.meta.next_page;
            this.meta = response.meta;
        });
    }

    checkForAllocationTutorial(): void {
        let vendorUser = this.dataService.getUser();
        let inViewport = true;
        this.pixelsFromTop = 0;
        for (let notification of this.notificationList) {
            if (notification.identifier === 'project_allocation_request') {
                if (!this.isAnyPartOfElementInViewport(document.getElementById(notification.id))) {
                    inViewport = false;
                    this.pixelsFromTop = 0;
                }
                break;
            }
            const divHeight = document.getElementById(notification.id) ? document.getElementById(notification.id).clientHeight : 0;
            this.pixelsFromTop += divHeight;
        }
        if (vendorUser && vendorUser.tutorials.indexOf('requestAllocationNotification') >= 0) {
            inViewport = false;
            this.pixelsFromTop = 0;
        }
        if (!(this.notificationList && this.notificationList.length > 0)) {
            this.pixelsFromTop = 0;
            inViewport = false;
        }
        const projectAllocationNotification = this.notificationList.find(x => x.identifier === 'project_allocation_request');
        if (!projectAllocationNotification) {
            this.pixelsFromTop = 0;
            inViewport = false;
        }
        if (inViewport) {
            let tBodyEl = document.getElementById('tableBody');
            this.pixelsFromTop += tBodyEl.getBoundingClientRect().top;
            this.renderer.addClass(document.body, 'scroll-disabled');
        }
    }

    public redirectTo(redirectionValue: string, isRead: boolean, data: any): void {
        if (!isRead) {
            this.markAsReadClicked(data);
        }
        if (redirectionValue === 'rejected_developers') {
            this.dataService.mixpanel_events_track('event', 'Rejection reason');
            for (let dev of data.data) {
                dev.devsWithSameSkill = [];
            }
            for (let dev of data.data) {
                let devIndex = -1;
                for (let rejectedDevIndex=0; rejectedDevIndex<this.dataService.rejectedDevs.length; rejectedDevIndex++) {
                    if (this.dataService.rejectedDevs[rejectedDevIndex].email === dev.email) {
                        devIndex = rejectedDevIndex;
                    }
                }
                if (devIndex >= 0) {
                    this.dataService.rejectedDevs[devIndex].devsWithSameSkill.push(dev);
                } else {
                    this.dataService.rejectedDevs.push(dev);
                }
            }
            return;
        } else if (redirectionValue === '/vendor/schedule/') {
            this.dataService.allocationId = data.data.length > 0 ? data.data[0].allocation_id : '';
            this.dataService.allocationRequestData = {
                from: data.data[0].from,
                to: data.data[0].to
            };
        } else if (redirectionValue === '/vendor/billing/invoices/invoice-listing') {
            this.dataService.mixpanel_events_track('event', 'Submit hours notification');
            this.dataService.submitHoursData = data.data;
        } else if(redirectionValue === '/vendor/rate-cards/'){
            redirectionValue = '/vendor/rate-cards/' + data.data[0].rate_card_id;
        } else if(redirectionValue === '/vendor/settings'){
            this.dataService.goToAutoMatchPreferencesFromNotifications = true;
        } else if (redirectionValue === 'setupRates') {
            this.dataService.mixpanel_events_track('event', 'Setup rates notification');
            this.checkIsMigrated(data);
            // this.phaseService.notificationData = data;
            // this.phaseService.developerAllocation = data;
            // this.suggestiveRateCardPopup();
            return;
        } else if (redirectionValue === '') {
            return;
        }
        this.router.navigate([redirectionValue]);
    }

    checkIsMigrated(notificationData){
        let developer_skills = [];
        const vendorUser = this.dataService.getUser();
        this.dataService.showHideLoader(true);
        if(notificationData.data){
            for(let devSkill of notificationData.data){
                if(!devSkill.rate_card || !devSkill.rate_card.id){
                    developer_skills.push(devSkill);
                }
            }
        }
        
        if (vendorUser.tutorials.indexOf("migrationDone") < 0){
            this.dataService.getRateCardsMigrate()
                .subscribe(res => {
                    if(res.data && res.data.length > 0){
                            this.dataService.showHideLoader(false);
                            this.dataService.firstMigrationPopup = true;
                    } else{
                            this.getUpdatedDevSkills(developer_skills);
                    }
                });
        } else{
                this.getUpdatedDevSkills(developer_skills);
        }
    }

    getUpdatedDevSkills(developer_skills): void{
        this.dataService.getRateCardsMigrate(developer_skills)  
                    .subscribe(res => {
                        this.dataService.showHideLoader(false);
                        if(res.data.length > 0){
                            this.rateCardService.clickedScreen = 'setupRates';
                            this.rateCardService.currentRateCard = res.data[0];
                            this.dataService.rateCardPopup = true;
                        }
                    }) ;
    }

    // suggestiveRateCardPopup(){
    //     this.getRateCardsForDeveloperSkill();
    //     this.rateCardListTotalLength = 0;
    //     this.rateCardListLengthLimit = 3;
    //     this.showAllButton = true;
    //     const getDevName = this.phaseService.notificationData.title.split("'");
    //     this.devName = getDevName[1];
    //     if(this.phaseService.notificationData && this.phaseService.notificationData.developer && this.phaseService.notificationData.developer.profile_photo_url){
    //         this.devPic = this.phaseService.notificationData.developer.profile_photo_url;
    //     }else{
    //         this.devPic = '../../../assets/images/icon-profile-default.svg';
    //     }
    // }

    // suggestiveRateCardPopupDone(){
    //     if(this.autoAddPopupDoneButtonDisabled){
    //         return;
    //     }
    //     this.autoAddPopupDoneButtonDisabled = true;
    //     this.dataService.rateCardLinkThroughSuggestiveRateCard(this.selectedRateCardForPopup.id, this.phaseService.notificationData.reference_ids[0], this.autoAddCheckBoxValue)
    //     .subscribe(res => {
    //         this.autoAddPopupDoneButtonDisabled = false;
    //         this.phaseService.showLinkDevToRateCardPopup = false;
    //         this.rateCardLinkToastData.toastMsg = res.message;
    //         this.rateCardLinkToastData.showToastMsg = true;
    //         this.rateCardLinkToastData.success = true;
    //         this.dataService.createRateCardThroughAutoAddPopup = false;
    //         this.dataService.createRateCardThroughAutoAddPopupHaveDone = false;
    //         setTimeout(() => {
    //             this.rateCardLinkToastData.showToastMsg = false;
    //         }, 3000);
    //         let rateCardObj = {
    //             rate_card_name: res.data.title,
    //             auto_add: false,
    //             rate_card_id: res.data.id
    //         }
    //         let currentNotificationIndex = this.notificationList.findIndex(x => x.id === this.phaseService.notificationData.id);
    //         this.notificationList[currentNotificationIndex].data.push(rateCardObj);
    //     }, err => {
    //         this.autoAddPopupDoneButtonDisabled = false;
    //         this.rateCardLinkToastData.toastMsg = err.error.message;
    //         this.rateCardLinkToastData.success = false;
    //     })
    // }

    // closeToast(){
    //     this.rateCardLinkToastData.showToastMsg = false;
    // }

    // closeSuggestiveRateCardPopup(){
    //     this.phaseService.showLinkDevToRateCardPopup = false;
    //     this.devAlreadyLinkedToRateCard = false;
    //     this.dataService.createRateCardThroughAutoAddPopup = false;
    //     this.dataService.createRateCardThroughAutoAddPopupHaveDone = false;
    //     this.autoAddPopupDoneButtonDisabled = true;
    // }

    // selectRateCard(rateCard){
    //     if(rateCard.alreadySelected == true){
    //         return;
    //     }
    //     for (let rateCard of this.rateCardsForDevSkill) {
    //         rateCard.currentlyActive = false;
    //         rateCard.alreadySelected = false;
    //     }
    //     rateCard.currentlyActive = true;
    //     this.selectedRateCardForPopup = rateCard;
    //     this.autoAddPopupDoneButtonDisabled = false;
    // }

    // createNewRateCard(){
    //     this.dataService.isShowRateCardPopUp = true;
    //     this.router.navigate(['/vendor/billing/rate-cards']);
    //     this.dataService.createRateCardThroughAutoAddPopup = true;
    //     this.dataService.goToRateCardFromAutoAddPopup = true;
    // }

    // showAllRateCards(){
    //     this.rateCardListLengthLimit = this.rateCardListTotalLength;
    //     this.showAllButton = false;
    // }

    // getRateCardsForDeveloperSkill(): void {
    //     this.dataService.getRateCardsForDeveloperSkill(this.phaseService.notificationData.reference_ids[0])
    //     .subscribe(res => {
    //       this.phaseService.showLinkDevToRateCardPopup = true;
    //       this.rateCardsForDevSkill = res.data;
    //       for (let rateCard of this.rateCardsForDevSkill) {
    //         this.rateCardListTotalLength++;
    //         if (rateCard.developer_skill_ids.indexOf(this.phaseService.notificationData.reference_ids[0]) >= 0) {
    //             rateCard.alreadySelected = true;
    //             this.selectedRateCardForPopup = rateCard;
    //             this.autoAddPopupDoneButtonDisabled = false;
    //             if(!this.dataService.createRateCardThroughAutoAddPopup || !this.dataService.createRateCardThroughAutoAddPopupHaveDone){
    //                 this.devAlreadyLinkedToRateCard = true;
    //                 this.rateCardsForDevSkill = [rateCard];
    //                 this.autoAddPopupDoneButtonDisabled = true;
    //                 return;
    //             }
    //         } else {
    //           rateCard.alreadySelected = false;
    //         }
    //         rateCard.currentlyActive = false;
    //       }
    //     }, err => {
    //       console.log(err);
    //     });
    //   }

    public markAsReadClicked(data, e?) {
        if (e) {
            e.stopPropagation();
        }
        if (data === 'mark_all_as_read') {
            this.markAllRead = true;
            for (const notification of this.notificationList) {
                notification.read = true;
            }
        } else {
            this.notificationId = data.id;
            data.read = true;
        }
        const dataForRead = this.markAllRead ? this.markAllRead : this.notificationId;
        this.dataService.markReadNotification(dataForRead).subscribe((response) => {
        });
    }

    onScroll() {
        // this.page !== null
        this.checkForAllocationTutorial();
        if (this.meta && this.meta.next_page !== null && !this.isScrolled) {
            this.isScrolled = true;
            this.page++;
            this.getListOfNotifications();
        }
    }

    redirectToPage(page: string): void {
        this.router.navigate([page]);
    }

    isAnyPartOfElementInViewport(el) {
        if (!el) return;
        const rect = el.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - 100;
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
        const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
        const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
        return (vertInView && horInView);
    }

    updateTutorials(tutorialScreen: string): void {
        const user = this.dataService.getUser();
        if (user.tutorials.indexOf(tutorialScreen) >= 0) return;
        this.pixelsFromTop = 0;
        this.renderer.removeClass(document.body, 'scroll-disabled');
        user.tutorials.push(tutorialScreen);
        this.tutorialService.gotIt(user.tutorials)
        .subscribe(res => {
            this.dataService.setUser(res.data);
        }, err => {
            console.log(err);
        });
    }

    closeToaster(){
        this.rateCardService.rateCardavAilabilityToastData.showToaster = false;
    }
}
