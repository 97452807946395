import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TagInputModule } from 'ngx-chips';
import { SettingsService } from './settings.service';
import { PartnerAgreementModule } from '../../partner-agreement/partner-agreement.module';
import { RouterModule } from '@angular/router';
import { ToasterModule } from '../../toaster/toaster.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TagInputModule,
    PartnerAgreementModule,
    RouterModule,
    ToasterModule
  ],
  declarations: [SettingsComponent],
  providers: [SettingsService]
})
export class SettingsModule { }
