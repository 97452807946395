import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { MyDatePickerModule } from 'mydatepicker';
import { ClickOutsideModule } from 'ng-click-outside';

import { ScheduleService } from './schedule.service';
import { ScheduleComponent } from './schedule.component';
import { RightPanelComponent } from './right-panel/right-panel.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ManageRequestComponent } from './manage-request/manage-request.component';
import { ToasterModule } from '../../toaster/toaster.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        InfiniteScrollModule,
        DragToSelectModule,
        MyDatePickerModule,
        ClickOutsideModule,
        SlickCarouselModule,
        ToasterModule
    ],
    declarations: [
        ScheduleComponent,
        RightPanelComponent,
        ManageRequestComponent
    ],
    providers: [ScheduleService]
})

export class ScheduleModule { }
