import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { NewUpdateComponent } from './new-update/new-update.component';
import { CreateRateCardComponent } from './create-rate-card/create-rate-card.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    NewUpdateComponent,
    CreateRateCardComponent,
    ConfirmationComponent
  ],
  exports: [
    NewUpdateComponent,
    CreateRateCardComponent,
    ConfirmationComponent,
    FormsModule
  ]
})
export class PopupModule { }
