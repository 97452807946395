import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimeAgoPipe } from 'time-ago-pipe';

import { RateCardComponent } from './rate-card.component';
import { RateCardService } from './rate-card.service';
import { TutorialModule } from '../../tutorial/tutorial.module';
import { RateCardDetailComponent } from './rate-card-detail/rate-card-detail.component';
import { PopupModule } from '../popup/popup.module';
import { ToasterModule } from '../../toaster/toaster.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NouisliderModule,
        NgSelectModule,
        TutorialModule,
        PopupModule,
        ToasterModule
    ],
    declarations: [
        RateCardComponent,
        TimeAgoPipe,
        RateCardDetailComponent
    ],
    providers: [RateCardService]
})

export class RateCardModule { }
