import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PhaseService } from '../../shared/services/phase.service';
import { DataService } from '../../shared/services/data.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subscription } from 'rxjs';
import { Angulartics2Segment } from 'angulartics2/segment';
import { SettingsService } from './settings.service';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('bussSpocEmail', {static: true}) bussSpocEmail: any;
  @ViewChild('signSpocEmail', {static: true}) signSpocEmail: any;
  @ViewChild('compName', {static: true}) compName: any;
  selectedCountryCode;
  selectedSignSpocCC;
  selectedBusinessSpocCC;
  tabActive = 'account';
  updatePaymentForm: FormGroup;
  updateAccountForm: FormGroup;
  readOnlyUserForm: FormGroup;
  currencyDisabled: boolean = false;
  countries: any;
  countryCodes: Array<any> = [];
  dataService:any;
  private observableProfile: Subscription;
  teamStructures: any[];
  public selectedSkills = new Set();
  logo: File;
  logoUrl: any='';
  companyDomains = [];
  companyDomainErr = '';
  validators = [this.domainNameValidator.bind(this)];
  errorMessages = {
      'incorrectDomainName': 'Incorrect domain name',
      'alreadyAdded': 'This domain is already added',
      'publicDomainName': 'Public domains are not allowed'
  };
  showToaster = false;
  toasterData: any = {};
  deletedDocs = [];
  vendorAccountData: any;
  vendorPaymentData: any;
  sign_spoc_id = '';
  business_spoc_id = '';
  currencies:any;
  showInr: boolean;
  showUsd: boolean;
  @ViewChild('settingsContent', {static: false}) private myScrollContainer: ElementRef;
  preferences: any[] = [];
  showChangePassword = false;
  oldPassword = '';
  newPassword = '';
  inputType = 'password';
  showChangePasswordError = '';
  contractDoc: string;
  desktopNotification;
  blockedDomains = [];
  readOnlyPwdPlaceHolder = '8+ characters';

  constructor(dataService: DataService, private phaseService: PhaseService,
    private formBuilder: FormBuilder, private settingService: SettingsService,
    private router: Router, private analyticsSegment: Angulartics2Segment
  ) {
    this.dataService = dataService;
    this.phaseService.leftMenuActive = 'settings';
    this.observableProfile = this.dataService.getTeamStructures().subscribe(res => {
      this.teamStructures = res.data;
      this.dataService.showLoader = false;
      this.setTeamSkill();
    });
   }

  ngOnInit() {
    this.getBlockedDomains();
    this.desktopNotification  = this.dataService.getUser().desktop_notification;
    this.getContractPdf();
    //this.getAutoMatchPreferences();
    this.updateAccountForm = this.formBuilder.group({
      compLogo: ['', Validators.required],
      orgName: ['', [Validators.required, this.noWhitespaceValidator]],
      companyName: ['', [Validators.required, this.noWhitespaceValidator]],
      email: {value: '', disabled: true},
      countryCode: ['', Validators.required],
      ofcPhone: ['', [Validators.required, this.noWhitespaceValidator]],
      compRegNum: ['', [Validators.required, this.noWhitespaceValidator]],
      address: ['', [Validators.required, this.noWhitespaceValidator]],
      city: ['', [Validators.required, this.noWhitespaceValidator]],
      state: ['', [Validators.required, this.noWhitespaceValidator]],
      pincode: ['', [Validators.required, this.noWhitespaceValidator]],
      country: ['', Validators.required],
      sign_spoc_name: ['', [Validators.required, this.noWhitespaceValidator, Validators.pattern("[a-zA-Z0-9._' -]*"), Validators.maxLength(100)]],
      sign_spoc_email: ['', [Validators.required, this.noWhitespaceValidator, Validators.email, Validators.maxLength(100), this.emailDomainValidator.bind(this)]],
      sign_spoc_cc: ['', Validators.required],
      sign_spoc_phone: ['', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(15), Validators.minLength(5), Validators.pattern("[- +()]*[0-9][- +()0-9]*")]],
      business_spoc_name: ['', [Validators.required, this.noWhitespaceValidator, Validators.pattern("[a-zA-Z0-9._' -]*"), Validators.maxLength(100)]],
      business_spoc_email: ['', [Validators.required, this.noWhitespaceValidator, Validators.email, Validators.maxLength(100), , this.emailDomainValidator.bind(this)]],
      business_spoc_cc: ['', Validators.required],
      business_spoc_phone: ['', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(15), Validators.minLength(5), Validators.pattern("[- +()]*[0-9][- +()0-9]*")]],
      companyDomains: ['']
    });
    this.updatePaymentForm = this.formBuilder.group({
      accName: ['', [Validators.required, this.noWhitespaceValidator]],
      accNumber: ['', [Validators.required, this.noWhitespaceValidator]],
      ifsccode: ['', Validators.required],
      nameOfBranch: ['', [Validators.required, this.noWhitespaceValidator]],
      gstNumber: [''],
      panNumber: ['', [Validators.required, this.noWhitespaceValidator]],
      swiftcode: ['', [Validators.required, this.noWhitespaceValidator]],
      payingIn: [{value: '', disabled: this.currencyDisabled}]
    });
    this.readOnlyUserForm = this.formBuilder.group({
      readOnlyName: ['', [Validators.required, this.noWhitespaceValidator]],
      readOnlyEmail: [{value: '', disabled: false}, [Validators.required, this.noWhitespaceValidator, Validators.email, Validators.maxLength(100), this.emailDomainValidator.bind(this)]],
      readOnlyPwd: ['', [Validators.required, this.passwordEmptyCheck, this.isValidPassword]]
    });
  }

  getBlockedDomains(): void {
    this.dataService.showHideLoader(true);
    this.dataService.getBlockedDomains(this.dataService.getUser().email).subscribe(res => {
      this.blockedDomains = res.data;
      this.fillAccountInfo();
      this.fillReadOnlyUser();
      this.setDataForms();
    }, err => {}, () => this.dataService.showHideLoader(false));
  }

  setDataForms() {
    this.dataService.getCountryList()
    .subscribe(countries => {
      this.countries = countries;
      this.setCountry();
      this.getCurrencies();
      this.fillPaymentInfo();
    });
  }

  emailDomainValidator(control: FormGroup) {
    if (!control.value) return;
    if (this.blockedDomains.indexOf(control.value.toLowerCase().split('@')[1]) >= 0) {
        return {
            'publicDomainName': true
        };
    }
    if (this.dataService.getUser().domain_names.indexOf(control.value.toLowerCase().split('@')[1]) === -1) {
        return {
            'domainNotListed': true
        };
    }
    return null;
  }

  closeAgreementPopup(){
    this.dataService.showContractCopy = false;
  }

  // getAutoMatchPreferences(): void {
  //   this.settingService.getAutoMatchPreferences()
  //   .subscribe(res => {
  //     this.preferences = res.data;
  //   });
  // }

  goToRateCard(rateCard: any): void {
    this.router.navigate([`vendor/rate-cards/${rateCard.id}`]);
  }

  // clearPreference(preference: any): void {
  //   this.dataService.showLoader = true;
  //   this.settingService.deletePreference(preference.id)
  //   .subscribe(res => {
  //     const preferenceIndex = this.preferences.findIndex(preferenceJson => preferenceJson.id === preference.id);
  //     this.preferences.splice(preferenceIndex, 1);
  //     this.dataService.showLoader = false;
  //   }, err => {
  //     this.dataService.showLoader = false;
  //   });
  // }

  onDesktopNotificationChange(): void {
    this.dataService.patchUserProfile({desktop_notification: this.desktopNotification})
    .subscribe(res => {
      this.dataService.setUser(res.data);
    }, err => {
      this.desktopNotification = !this.desktopNotification;
    });
  }

  onChangePassword(): void {
    if (!(this.oldPassword && this.newPassword)) return;
    if (!(this.oldPassword.length >= 8 && this.newPassword.length >= 8)) return;
    this.dataService.showLoader = true;
    this.showChangePasswordError = '';
    this.settingService.changePassword(this.oldPassword, this.newPassword)
    .subscribe(res => {
      this.dataService.setAuthToken(res.authtoken);
      this.dataService.showLoader = false;
      this.updateToaster("success", "Password changed successfully!");
      this.cancelChangePassword();
    }, err => {
      this.dataService.showLoader = false;
      const error = err.error;
      this.showChangePasswordError = error.message;
    });
  }

  getValidation(value: string): string {
    if (value.length > 0 && value.length < 8) return 'Please enter atleast 8 charachters';
    return '';
  }

  cancelChangePassword(): void {
    this.oldPassword = '';
    this.newPassword = '';
    this.showChangePassword = false;
    this.inputType = 'password';
    this.showChangePasswordError = '';
  }

  get accountControls(){ return this.updateAccountForm.controls;}

  get formControls(){ return this.updatePaymentForm.controls;}

  get readOnlyControls(){ return this.readOnlyUserForm.controls;}

  setCountry(): void{
    const vendorUser = this.dataService.getUser();
    if(vendorUser.address){
      this.accountControls.country.setValue(vendorUser.address.country);
    }
  }

  fillReadOnlyUser(): void {
    const user = this.dataService.getUser();
    if (!user.member) return;
    this.readOnlyControls.readOnlyName.setValue(user.member.name);
    this.readOnlyControls.readOnlyEmail.setValue(user.member.email);
    this.readOnlyControls.readOnlyEmail.disable();
    this.readOnlyControls.readOnlyPwd.setValidators([this.passwordEmptyCheck, this.isValidPassword]);
    this.readOnlyControls.readOnlyPwd.setValue('');
    this.readOnlyPwdPlaceHolder = '********'
  }

  fillAccountInfo(){
    const vendorUser = this.dataService.getUser();
    this.logoUrl = vendorUser.logo ? vendorUser.logo.file_url: '';
    if(this.logoUrl){
      this.accountControls.compLogo.setValue("");
      this.accountControls.compLogo.clearValidators();
      this.accountControls.compLogo.updateValueAndValidity();
    }
    this.accountControls.email.setValue(vendorUser.email);
    this.accountControls.orgName.setValue(vendorUser.name);
    this.accountControls.companyName.setValue(vendorUser.company_name);
    if(vendorUser.phone){
      const phoneInfo = this.dataService.splitPhoneNumber(vendorUser.phone);
      this.accountControls.countryCode.setValue(phoneInfo.countryCode);
      this.selectedCountryCode = phoneInfo.countryCode;
      this.accountControls.ofcPhone.setValue(phoneInfo.phoneNumber);
    }
    this.accountControls.compRegNum.setValue(vendorUser.company_reg_number);

    if(vendorUser.address){
      this.accountControls.address.setValue(vendorUser.address.address);
      this.accountControls.city.setValue(vendorUser.address.city);
      this.accountControls.state.setValue(vendorUser.address.state);
      this.accountControls.pincode.setValue(vendorUser.address.pincode);
    }
    for (let i of vendorUser.spocs) {
      if (i.spoc_type === 'signatory') {
        this.sign_spoc_id = i.id;
        this.accountControls.sign_spoc_name.setValue(i.name);
        this.accountControls.sign_spoc_email.setValue(i.email);
        if(i.phone){
          const sign_phoneInfo = this.dataService.splitPhoneNumber(i.phone);
          this.accountControls.sign_spoc_cc.setValue(sign_phoneInfo.countryCode);
          this.selectedSignSpocCC = sign_phoneInfo.countryCode;
          this.accountControls.sign_spoc_phone.setValue(sign_phoneInfo.phoneNumber);
        }
      } else if (i.spoc_type === 'business') {
        this.business_spoc_id = i.id;
        this.accountControls.business_spoc_name.setValue(i.name);
        this.accountControls.business_spoc_email.setValue(i.email);
        if(i.phone){
          const business_phoneInfo = this.dataService.splitPhoneNumber(i.phone);
          this.accountControls.business_spoc_cc.setValue(business_phoneInfo.countryCode);
          this.selectedBusinessSpocCC = business_phoneInfo.countryCode;
          this.accountControls.business_spoc_phone.setValue(business_phoneInfo.phoneNumber);
        }
      }
    }
    if (vendorUser.domain_names) {
      this.companyDomains = vendorUser.domain_names.map(x => {
        return {
            display: x,
            value: x
        };
    });
    this.accountControls.companyDomains.setValue(this.companyDomains);
    }
    this.signSpocEmail.nativeElement.focus();
    this.bussSpocEmail.nativeElement.focus();
    this.compName.nativeElement.focus();
  }

  fillPaymentInfo(){
    const vendorUser = this.dataService.getUser();
    if(vendorUser.payment_detail){
      this.formControls.accName.setValue(vendorUser.payment_detail.name_of_account);
      this.formControls.accNumber.setValue(vendorUser.payment_detail.account_number.toString());
      this.formControls.ifsccode.setValue(vendorUser.payment_detail.ifsc_code);
      this.formControls.nameOfBranch.setValue(vendorUser.payment_detail.name_of_bank_branch);
      this.formControls.gstNumber.setValue(vendorUser.payment_detail.gst_number);
      this.formControls.panNumber.setValue(vendorUser.payment_detail.pan_number);
      this.formControls.swiftcode.setValue(vendorUser.payment_detail.swift_code);
      this.formControls.payingIn.setValue(vendorUser.payment_detail.currency.code);
      setTimeout(() => {
        this.selectCurrency(this.formControls.payingIn.value);
      }, 0);
      setTimeout(() => {
        this.currencyDisabled = true;
        this.formControls.payingIn.disable();
      }, 100);
    }
  }

  getCurrencies(){
    this.dataService.getCurrencies()
    .subscribe(res => {
      this.currencies = res.data;
      if(!this.dataService.getUser().payment_detail){
        setTimeout(() => {
          if(this.accountControls.country.value === 'India'){
            this.formControls.payingIn.setValue("INR");
            this.selectCurrency("INR");
          }
          else{
            this.formControls.payingIn.setValue("USD");
            this.selectCurrency("USD");
          }
        }, 1000);
      }

    }, error => {
      console.log(error);
    });
  }

  getCurrencyID(code: string){
    let currencyOBJ = this.currencies.find(currency => currency.code === code);
    return currencyOBJ.id;
  }

  selectCurrency(value){

    if(value === 'INR'){
      this.showInr = true;
      this.showUsd = false;
      this.formControls.swiftcode.setValue("");

      this.formControls.swiftcode.clearValidators();
      this.formControls.swiftcode.updateValueAndValidity();

      this.formControls.ifsccode.setValidators([Validators.required, this.noWhitespaceValidator]);
      this.formControls.panNumber.setValidators([Validators.required, this.noWhitespaceValidator]);

    }
    else{
      this.showInr = false;
      this.showUsd = true;

      this.formControls.swiftcode.setValidators([Validators.required, this.noWhitespaceValidator]);

      this.formControls.ifsccode.setValue("");
      this.formControls.ifsccode.clearValidators();
      this.formControls.ifsccode.updateValueAndValidity();

      this.formControls.gstNumber.setValue("");

      this.formControls.panNumber.setValue("");
      this.formControls.panNumber.clearValidators();
      this.formControls.panNumber.updateValueAndValidity();
    }
  }

  skill_change(skill, thisData){
    if(thisData.target.checked){
      skill.selected = true;
    } else{
      skill.selected = false;
    }
  }

  updateAccountDetails() {
    let companyDomainsList;
    let spoc_data;
    let teamStructures = [];
    let documents = [];
    let vendorUser = this.dataService.getUser();
    if (this.companyDomains.length === 0) {
      this.companyDomainErr = 'Please enter at least one company domain';
      return;
    }
    if(this.updateAccountForm.invalid){
      this.dataService.showLoader = false;
      return;
    }
    let addressID = (vendorUser.address && vendorUser.address.id) ? vendorUser.address.id : '';
    let logoID = (vendorUser.logo && vendorUser.logo.id) ? vendorUser.logo.id : '';
    let logoFile = this.logo ? this.logo : '';
    if(this.companyDomains && this.companyDomains.length > 0){
        companyDomainsList = this.companyDomains.map(domain => {
          return domain.value;
      });
    }

    this.teamStructures.map(teamStructure => {
      teamStructure.skills.map(skill => {
        if (skill.selected) {
          teamStructures.push({ "id": skill.id, "title": skill.title });
        }
      });
    });
    if (this.deletedDocs) {
      for (let deletedDoc of this.deletedDocs) {
        documents.push(deletedDoc);
      }
    }
    spoc_data = [
      {
        id: this.sign_spoc_id,
        name: this.updateAccountForm.value.sign_spoc_name,
        email: this.updateAccountForm.value.sign_spoc_email,
        phone: `+${this.updateAccountForm.value.sign_spoc_cc}-${this.updateAccountForm.value.sign_spoc_phone}`,
        spoc_type:'signatory'
      },
      {
        id: this.business_spoc_id,
        name: this.updateAccountForm.value.business_spoc_name,
        email: this.updateAccountForm.value.business_spoc_email,
        phone: `+${this.updateAccountForm.value.business_spoc_cc}-${this.updateAccountForm.value.business_spoc_phone}`,
        spoc_type:'business'
      }
    ];
    this.vendorAccountData = {
      id: vendorUser.id,
      name: this.updateAccountForm.value.orgName,
      company_name: this.updateAccountForm.value.companyName,
      phone: '+'+this.updateAccountForm.value.countryCode+'-'+this.updateAccountForm.value.ofcPhone,
      company_reg_number: this.updateAccountForm.value.compRegNum,
      address_attributes: {
        id: addressID,
        address: this.updateAccountForm.value.address,
        city: this.updateAccountForm.value.city,
        state: this.updateAccountForm.value.state,
        country: this.updateAccountForm.value.country,
        pincode: this.updateAccountForm.value.pincode
      },
      logo_attributes: logoFile,
      domain_names: companyDomainsList,
      spocs_attributes: spoc_data,
      skills_attributes: teamStructures
  }
  this.updateVendorAccount(this.vendorAccountData);
  }

  updateVendorAccount(vendorData){
    this.dataService.showLoader = true;
    this.settingService.updateForm(vendorData)
      .subscribe(res => {
        this.handleUpdateProfileSuccess(res.data);
        this.dataService.showLoader = false;
        this.updateToaster("success", "Profile Updated Successfully");
      }, err => {
        this.dataService.showLoader = false;
        this.updateToaster("error", err.error.message);
      });
  }

  saveReadOnlyUser(): void {
    if (this.readOnlyUserForm.invalid) return;
    this.dataService.showLoader = true;
    this.settingService.saveReadOnlyUser(
        this.readOnlyUserForm.value.readOnlyName, 
        this.dataService.getUser().member && this.dataService.getUser().member.email ? null : this.readOnlyUserForm.value.readOnlyEmail, 
        this.readOnlyUserForm.value.readOnlyPwd ? this.readOnlyUserForm.value.readOnlyPwd : null,
        this.dataService.getUser().member && this.dataService.getUser().member.id ? this.dataService.getUser().member.id : null
    )
    .subscribe(res => {
        this.dataService.showLoader = false;
        this.dataService.setUser(res.data);
        this.readOnlyControls.readOnlyEmail.disable();
        this.readOnlyControls.readOnlyPwd.setValidators([this.passwordEmptyCheck, this.isValidPassword]);
        this.readOnlyControls.readOnlyPwd.setValue('');
        this.readOnlyPwdPlaceHolder = '********'
        this.updateToaster("success", "Saved read only user");
    }, err => {
        this.dataService.showLoader = false;
        this.updateToaster("error", err.error.message);
    });
  }

  updatePaymentDetails(){
    this.dataService.showLoader = true;
    if(this.updatePaymentForm.invalid){
      this.dataService.showLoader = false;
      return;
    }
    const vendorUser = this.dataService.getUser();
    let paymentID = (vendorUser.payment_detail && vendorUser.payment_detail.id) ? vendorUser.payment_detail.id : '';
    let currencyId = this.currencyDisabled ? vendorUser.payment_detail.currency.id : this.getCurrencyID(this.updatePaymentForm.value.payingIn);
    let currencyCode = this.currencyDisabled ? vendorUser.payment_detail.currency.code : this.updatePaymentForm.value.payingIn;

    this.vendorPaymentData = {
        payment_detail: {
          id: paymentID,
          name_of_account: this.updatePaymentForm.value.accName,
          account_number: this.updatePaymentForm.value.accNumber,
          ifsc_code: this.updatePaymentForm.value.ifsccode,
          name_of_bank_branch: this.updatePaymentForm.value.nameOfBranch,
          gst_number: this.updatePaymentForm.value.gstNumber,
          pan_number: this.updatePaymentForm.value.panNumber,
          swift_code: this.updatePaymentForm.value.swiftcode,
          currency:{
            id: currencyId,
            code: currencyCode
          }
        }
    }
    this.updateVendorPayment(this.vendorPaymentData);
  }

  updateVendorPayment(vendorPaymentData){
    this.dataService.showLoader = true;
    this.dataService.updatePaymentDetails(vendorPaymentData, "payment")
    .subscribe(res => {
      this.dataService.setUser(res.data);
      this.dataService.showLoader = false;
      this.updateToaster("success", "Profile Updated Successfully");
    }, err => {
      this.dataService.showLoader = false;
      this.updateToaster("error", err.error.message);
    });
  }

  handleUpdateProfileSuccess(vendorUser): void {
    this.analyticsSegment.setUserProperties({
      userId: vendorUser ? vendorUser.id : '',
      login_count: vendorUser.sign_in_count,
      email: vendorUser ? vendorUser.email : '',
      name: vendorUser ? vendorUser.name : '',
      phone: vendorUser ? vendorUser.phone : '',
      address: vendorUser ? vendorUser.address.address : '',
      logo: vendorUser && vendorUser.logo ? vendorUser.logo.file_url : ''
    });
    this.dataService.setUser(vendorUser);
    this.deletedDocs = [];
  }

  companyLogoUpload(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      for (const file of Array.from(fileList)) {
        this.logo = file;
      }
    }
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (subEvent: any) => {
        this.logoUrl = subEvent.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    event.target.value = null;
  }

addDomain($event){
  this.companyDomains.push({"display": $event.display, "value": $event.value});
}

removeDomain($event){
  this.companyDomains = this.companyDomains.filter( h => h.value !== $event.value);
}

private domainNameValidator(control: FormGroup) {
  if (!control.value) return;
  if (this.isDomainNameValid(control.value)) {
      if (this.blockedDomains.indexOf(control.value) >= 0) {
          return {
            'publicDomainName': true
          };
      } else if (this.companyDomains.indexOf(control.value) >= 0) {
          return {
              'alreadyAdded': true
          };
      }
  } else {
      return {
          'incorrectDomainName': true
      };
  }
  return null;
}

private isDomainNameValid(companyDomain: string): boolean {
  if (companyDomain.indexOf('.') > 0 && companyDomain.indexOf('.') < companyDomain.length - 1) {
      return true;
  }
  return false;
}

  getAddress(place: any): void {
    if (place != null && place.address_components != null) {
        for (const placeAddressComponent of place.address_components) {
            const addressType = placeAddressComponent.types[0];
            if (addressType === 'administrative_area_level_1') {
                this.accountControls.state.setValue(placeAddressComponent['long_name']);
            } else if (addressType === 'administrative_area_level_2') {
                this.accountControls.city.setValue(placeAddressComponent['long_name']);
            } else if (addressType === 'country') {
                this.accountControls.country.setValue(placeAddressComponent['long_name']);
            } else if (addressType === 'postal_code') {
                this.accountControls.pincode.setValue(placeAddressComponent['long_name']);
            }
        }
    }

    this.accountControls.address.setValue(place.formatted_address);
}

    public noWhitespaceValidator(control: FormGroup) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    isValidPassword(control: FormGroup) {
        if (control.value === "") return null;
        if (control.value.length >= 8) return null;
        return { 'validPwd': true };
    }

    passwordEmptyCheck(control: FormGroup) {
        if (control.value === "") return null;
        const isWhitespace = (control.value).trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    public setTeamSkill() {
        const vendorUser = this.dataService.getUser();
        if(vendorUser && vendorUser.skills){
            vendorUser.skills.forEach(element => {
            this.selectedSkills.add(element.id);
            });
        }
        this.teamStructures.map(teamStructure => {
            teamStructure.skills.map(skill => {
            if (this.selectedSkills.has(skill.id)) {
                skill.selected = true;
            } else {
                skill.selected = false;
            }
            });
        });

    }
  selectTab(tabValue){
    var scrollToValue = document.getElementById(tabValue+"Content").offsetTop - 142;
    document.getElementById("settingsContent").scrollTo({top: scrollToValue, behavior: 'smooth'});
  }

  onScroll(){

    var topMarginValue = 142 + 100; // 142 for top gap of scrollable area and +100 for early active

    var companyDetailsTopOffset = document.getElementById("accountContent").offsetTop - topMarginValue;
    var readOnlyUserOffset = document.getElementById("readOnlyUserContent").offsetTop - topMarginValue
    var paymentAndTaxTopOffset = document.getElementById("paymentsContent").offsetTop - topMarginValue;
    var privacyTopOffset = document.getElementById("privacyContent").offsetTop - topMarginValue;
    var scrollTop = this.myScrollContainer.nativeElement.scrollTop;
    if(companyDetailsTopOffset < scrollTop){
      this.tabActive = 'account';
      if(readOnlyUserOffset < scrollTop){
        this.tabActive = 'readOnlyUser';
        if(paymentAndTaxTopOffset < scrollTop){
          this.tabActive = 'payments';
          if(privacyTopOffset < scrollTop){
            this.tabActive = 'privacy';
          }
        }
      }
    }
  }

  updateToaster(status, toastMsg){
    this.showToaster = true;
    this.toasterData.status = status;
    this.toasterData.text = toastMsg;
  }

  closeToaster(){
    this.showToaster = false;
  }

  viewAgreement(){
    this.dataService.showHideLoader(true);
    if(this.contractDoc){
      this.dataService.renderPDF(this.contractDoc, document.getElementById('holder'));
    }
  }

  getContractPdf(){
    this.contractDoc = this.dataService.getUser().contract_link;
}


}
