import { Component } from '@angular/core';

import { DataService } from '../../shared/services/data.service';

@Component({
    selector: 'app-after-email-confirmation-process',
    templateUrl: './after-email-confirmation-process.component.html',
    styleUrls: ['./after-email-confirmation-process.component.scss']
})

export class AfterEmailConfirmationProcessComponent {
    dataService;
    vendorUser: any;

    constructor(dataService: DataService) {
        this.dataService = dataService;
        this.vendorUser = this.dataService.getUser();
    }

}
