import { Component, OnInit } from '@angular/core';
import { DataService } from "../../../shared/services/data.service";
import { TutorialService } from "../../../tutorial/tutorial.service";
import { RateCardService } from "../../rate-card/rate-card.service";

@Component({
  selector: 'new-update',
  templateUrl: './new-update.component.html',
  styleUrls: ['./new-update.component.scss']
})
export class NewUpdateComponent implements OnInit {
  rateCardService;
  constructor(public dataService: DataService, 
              public tutorialService: TutorialService, 
              rateCardService: RateCardService) { 
                this.rateCardService = rateCardService;
              }

  ngOnInit() {
  }

  reviewNewRateCard(): void{
    this.dataService.firstMigrationPopup = false;
    this.rateCardService.clickedScreen = "migrate";
    this.dataService.rateCardPopup = true;
    this.dataService.mixpanel_events_track('event', 'Review new');
  }

reviewLater(): void{
  this.rateCardService.reviewLater();
}

}
