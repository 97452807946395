import {FormControl, FormGroup} from '@angular/forms';

export class CustomValidators {

  static emailValidator(control: FormControl) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control && control.value && !control.value.match(emailRegex)) {
      return { 'invalidEmailAddress': true };
    }
  }

  static matchPasswordsValidator(group: FormGroup) { // here we have the 'passwords' group
    let password = group.controls.password.value;
    let confirmPassword = group.controls.repassword.value;
    if (group.controls.currentPassword) {
      let currentPassword = group.controls.currentPassword.value;
    }

    if (password && confirmPassword) {
      return password === confirmPassword ? null : { notSame: true }
    }
  }

  static nospaceValidator(control: FormControl): { [s: string]: boolean } {
    if (control.value && control.value.trim().length == 0) {
      return { nospace: true };
    }
  }

  static isPhoneNumberValid(phoneNumber: string): boolean {
    const phoneNoRegex = /^(\d+-?)+\d+$/;
    const isValid =  phoneNumber.match(phoneNoRegex) ? true : false;
    const numberWithoutHyphens = phoneNumber.split('-').join('');
    return (isValid && numberWithoutHyphens.length >= 5 && numberWithoutHyphens.length <= 10) ? true : false;
}

}
