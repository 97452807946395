import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AddADeveloper } from './add-a-developer/add-a-developer.component';
import { CoreComponent } from './core/core.component';
import { ConfirmingEmailComponent } from './confirming-email/confirming-email.component';
import { ResendConfirmationEmailComponent } from './resend-confirmation-email/resend-confirmation-email.component';
import { RegisterComponent } from './register/register.component';
import { EmailPasswordComponent } from './register/email-password/email-password.component';
import { OtpComponent } from './register/otp/otp.component';
import { AfterEmailConfirmationProcessComponent } from './register/after-email-confirmation-process/after-email-confirmation-process.component';
import { OrganizationComponent } from './register/after-email-confirmation-process/organization/organization.component';
import { SpocDetailsComponent } from './register/after-email-confirmation-process/spoc-details/spoc-details.component';
import { TeamStructureComponent } from './register/after-email-confirmation-process/team-structure/team-structure.component';
import { NdaContractComponent } from './nda-contract/nda-contract.component';
import { NotificationsComponent } from './core/notifications/notifications.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RequestsComponent } from './core/requests/requests.component';
import { ScheduleComponent } from './core/schedule/schedule.component';
import { BillingComponent } from './core/billing/billing.component';
import { InvoiceComponent } from './core/invoices/invoice.component';
import { RateCardComponent } from './core/rate-card/rate-card.component';
import { RateCardDetailComponent } from './core/rate-card/rate-card-detail/rate-card-detail.component';
import { InboxComponent } from './core/inbox/inbox.component';
import { PaymentDetailComponent } from './core/invoices/payment-detail/payment-detail.component';
import { ConfirmPaymentDetailComponent } from './core/invoices/confirm-payment-detail/confirm-payment-detail.component';
import { InvoiceListingComponent } from './core/invoices/invoice-listing/invoice-listing.component';
import { InvoiceDetailsComponent } from './core/invoices/invoice-details/invoice-details.component';
import { SettingsComponent } from './core/settings/settings.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: 'reset-password',
    component: ResetPasswordComponent
  }, {
    path: 'register',
    component: RegisterComponent,
    children: [{
        path: '',
        component: EmailPasswordComponent
    }, {
        path: 'otp',
        component: OtpComponent
    }, {
        path: 'confirmed',
        component: AfterEmailConfirmationProcessComponent,
        children: [{
            path: '',
            redirectTo: '1',
            pathMatch: 'full'
        }, {
            path: '1',
            component: OrganizationComponent
        }, {
            path: '2',
            component: SpocDetailsComponent,
            data: {spocType: 'signatory'}
        },  {
            path: '3',
            component: SpocDetailsComponent,
            data: {spocType: 'business'}
        }, {
            path: '4',
            component: TeamStructureComponent
        }]
    }]
  }, {
    path: 'contract',
    component: NdaContractComponent
  }, {
    path: 'join/:id',
    component: AddADeveloper
  }, {
    path: 'login',
    component: LoginComponent
  }, {
    path: 'confirming-email',
    component: ConfirmingEmailComponent
  }, {
    path: 'resend-confirmation-email',
    component: ResendConfirmationEmailComponent
  }, {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  }, {
    path: 'vendor',
    component: CoreComponent,
    children: [
      {
        path: '',
        component: CoreComponent
    }, {
        path: 'dashboard',
        loadChildren: () => import('./core/dashboard/dashboard.module').then(m => m.DashboardModule)
    }, {
        path: 'billing',
        component: BillingComponent,
        children: [{
                path: 'invoices',
                component: InvoiceComponent,
                children: [{
                  path: '',
                  redirectTo: 'invoice-listing',
                  pathMatch: 'full'
                }, {
                  path: 'payment-detail',
                  component: PaymentDetailComponent
                }, {
                  path: 'confirm-payment-detail',
                  component: ConfirmPaymentDetailComponent
                }, {
                  path: 'invoice-listing',
                  component: InvoiceListingComponent
                }
            ]}, {
                path: 'rate-cards',
                component: RateCardComponent
            }
        ]
    }, {
        path: 'inbox',
        component: InboxComponent,
        children: [{
            path: 'notifications',
            component: NotificationsComponent
        }, {
            path: 'requests',
            component: RequestsComponent
        }]
    }, {
        path: 'invoices/:invoiceId',
        component: InvoiceDetailsComponent,
    }, {
        path: 'rate-cards/:id',
        component: RateCardDetailComponent,
    }, {
        path: 'need-help',
        loadChildren: () => import('./core/need-help/need-help.module').then(m => m.NeedHelpModule)
    }, {
        path: 'schedule',
        component: ScheduleComponent
    }, {
      path: 'settings',
      component: SettingsComponent
    }]
  }
];
