import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Intercom } from 'ng-intercom';

import { DataService } from '../../shared/services/data.service';
import { PhaseService } from '../../shared/services/phase.service';
import { NeedHelpService } from './need-help.service';

@Component({
    selector: 'need-help',
    templateUrl: 'need-help.component.html',
    styleUrls: ['need-help.component.scss']
})

export class NeedHelpComponent {
    private readonly PER_PAGE = 10;
    request = {subject: '', request_type: 'enquiry', description: '', attachment_attributes: null};
    filesList = [];
    helpTopics: any[] = [];
    dataService;
    page = 1;
    meta: any;
    isScrolled = false;
    search = '';
    needHelpObservable: Subscription;
    accordionId = '';

    constructor(
        dataService: DataService,
        private phaseService: PhaseService,
        private needHelpService: NeedHelpService,
        private intercom: Intercom
    ) {
        this.dataService = dataService;
        this.phaseService.leftMenuActive = 'needHelp';
    }

    ngOnInit(): void {
        this.getHelpTopics();
    }

    requestTypeUpdate(value){
        this.request.request_type = value;
    }

    onTitleClick(helpTopic: any): void {
        if (this.accordionId === helpTopic.id) {
            this.accordionId = '';
        } else {
            this.accordionId = helpTopic.id;
        }
    }

    getHelpTopics(): void {
        this.dataService.showHideLoader(true);
        if (this.needHelpObservable) this.needHelpObservable.unsubscribe();
        this.needHelpObservable = this.needHelpService.getHelpTopics(this.page, this.PER_PAGE, this.search)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            if (this.isScrolled) {
                this.isScrolled = false;
                this.helpTopics = this.helpTopics.concat(res.data);
            } else {
                this.helpTopics = res.data;
            }
            this.meta = res.meta;
        }, err => {
            this.dataService.showHideLoader(false);
        });
    }

    vote(voteType: number, helpTopic: any): void {
        this.dataService.showHideLoader(true);
        let vote = voteType;
        if (helpTopic.my_vote === voteType) {
            vote = 0;
        }
        this.needHelpService.vote(vote, helpTopic.id)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            helpTopic.my_vote = vote;
        }, err => {
            this.dataService.showHideLoader(false);
        });
    }

    onScroll(): void {
        if (this.meta && this.meta.next_page !== null && !this.isScrolled) {
            this.isScrolled = true;
            this.page++;
            this.getHelpTopics();
        }
    }

    onSearch(event: any): void {
        const key = event.keyCode || event.charCode;
        if ((key === 8 || key === 46) && this.search.length === 0) {
            this.page = 1;
            this.getHelpTopics();
        }
        if (this.search.length < 1) return;
        this.page = 1;
        this.getHelpTopics();
    }

    sendRequest(form: FormGroup) {
        if (!this.request.subject || !this.request.description) return;
        this.dataService.showHideLoader(true);
        this.needHelpService.raiseRequest(this.request)
        .subscribe(() => {
            this.dataService.showHideLoader(false);
            this.dataService.showSuccessfulRegistrationMessage = true;
            this.dataService.successfulRegistrationMessage = 'Your request has been submitted successfully';
            setTimeout(() => {
                this.dataService.showSuccessfulRegistrationMessage = false;
                this.dataService.successfulRegistrationMessage = '';
            }, 3000);
            form.reset();
            this.filesList = [];
        }, () => {
            this.dataService.showHideLoader(false);
        });
    }

    fileChange(event): void {
        const fileList: FileList = event.target.files;
        this.filesList = [];
        if (fileList.length > 0) {
            this.filesList.push(fileList[0]);
        }
        if (this.filesList.length > 0) {
            this.request.attachment_attributes = {
            file: this.filesList[0]
            };
        }
        event.target.value = null;
    }

    openIntercom(): void {
        this.intercom.showNewMessage('');
    }

}
