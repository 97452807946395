
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DataService } from '../../shared/services/data.service';
import { HttpService } from '../../shared/services/http.service';

@Injectable()
export class RequestsService {

    constructor(private dataService: DataService, private httpService: HttpService) { }

    getRequests(vendorId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl(true)}api/v1/vendor/allocations?capacity_partner_uuid=${vendorId}`;
        let headers = this.dataService.getHeaders('application/json');
        if (this.dataService.isStaging) {
            headers = headers.append('secret-key', 'giLUlmUfGyAJDKUUXooj');
        } else if (this.dataService.isProduction) {
            headers = headers.append('secret-key', 'HpNZjDspQcfFPbtNyfrw');
        } else if (this.dataService.isLiquidStudio) {
            headers = headers.append('secret-key', 'HpNZjDspQcfFPbtNyfrw');
        } else if (this.dataService.isLiquidStudioStaging) {
            headers = headers.append('secret-key', 'HpNZjDspQcfFPbtNyfrw');
        } else {
            headers = headers.append('secret-key', 'giLUlmUfGyAJDKUUXooj');
        }
        return this.httpService.getRequest(url, headers);
    }

    handleRequest(id: number, status: string, allocationAction: string, rejectedReason = '', unavail_from, unavail_to, hasPendingExtension): Observable<any> {
        let url = `${this.dataService.getServerUrl(true)}api/v1/vendor/allocations/${id}`;
        if(hasPendingExtension){
            url = `${this.dataService.getServerUrl(true)}api/v1/vendor/allocations/${id}/update_allocation_extension`;
        }
        let headers = this.dataService.getHeaders('application/json');
        if (this.dataService.isStaging) {
            headers = headers.append('secret-key', 'giLUlmUfGyAJDKUUXooj');
        } else if (this.dataService.isProduction) {
            headers = headers.append('secret-key', 'HpNZjDspQcfFPbtNyfrw');
        } else if (this.dataService.isLiquidStudio) {
            headers = headers.append('secret-key', 'HpNZjDspQcfFPbtNyfrw');
        } else if (this.dataService.isLiquidStudioStaging) {
            headers = headers.append('secret-key', 'HpNZjDspQcfFPbtNyfrw');
        } else {
            headers = headers.append('secret-key', 'giLUlmUfGyAJDKUUXooj');
        }
        let json = {
            allocation: {
                status: status
            }
        } as any;
        if (allocationAction === 'decline') {
            json.allocation.reason = rejectedReason;
            /*if(unavail_from && unavail_to){
                json.allocation.unavail_from = unavail_from; 
                json.allocation.unavail_to = unavail_to; 
            }*/
        }
        if(hasPendingExtension){
            json = {
                allocation: {
                    extension_status: 'e'+json.allocation.status
                }
            }
            if (allocationAction === 'decline') {
                json.allocation.reason = rejectedReason;
            }
        }
        return this.httpService.patchRequest(url, json, headers);
    }

    getConcerns(): Observable<any>{
        let url = `${this.dataService.getServerUrl()}declining_reasons`;
        return this.httpService.getRequest(url);
    }
}
