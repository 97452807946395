import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TutorialComponent } from './tutorial.component';
import { TutorialService } from './tutorial.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [TutorialComponent],
  exports: [TutorialComponent],
  providers: [TutorialService]
})

export class TutorialModule { }
