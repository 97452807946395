import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { mergeMap } from 'rxjs/operators';

import { DataService } from '../../../shared/services/data.service';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss']
})
export class PaymentDetailComponent implements OnInit {
  selectedCountryCode;
  updatePaymentForm: FormGroup;
  isSubmitted: boolean = false;
  address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  countries: any;
  countryCodes: any;
  logo: File;
  logoUrl: any='';
  vendorProfile:any;
  dataService:any;
  currencies:any;
  showInr: boolean;
  showUsd: boolean;
  currencyDisabled: boolean = false;

  constructor(dataService: DataService, private router: Router, private formBuilder: FormBuilder) {
    this.dataService = dataService;
   }

  ngOnInit() {
    this.updatePaymentForm = this.formBuilder.group({
      compLogo: ['', Validators.required],
      orgName: ['', [Validators.required, this.noWhitespaceValidator]],
      companyName: ['', [Validators.required, this.noWhitespaceValidator]],
      countryCode: ['', Validators.required],
      ofcPhone: ['', [Validators.required, this.noWhitespaceValidator]],
      compRegNum: ['', [Validators.required, this.noWhitespaceValidator]],
      address: ['', [Validators.required, this.noWhitespaceValidator]],
      city: ['', [Validators.required, this.noWhitespaceValidator]],
      state: ['', [Validators.required, this.noWhitespaceValidator]],
      pincode: ['', [Validators.required, this.noWhitespaceValidator]],
      country: ['', Validators.required],
      accName: ['', [Validators.required, this.noWhitespaceValidator]],
      accNumber: ['', [Validators.required, this.noWhitespaceValidator]],
      ifsccode: ['', Validators.required],
      nameOfBranch: ['', [Validators.required, this.noWhitespaceValidator]],
      gstNumber: [''],
      panNumber: ['', [Validators.required, this.noWhitespaceValidator]],
      swiftcode: ['', [Validators.required, this.noWhitespaceValidator]],
      payingIn: [{value: '', disabled: this.currencyDisabled}]
    });
    this.setCountryCode();
    this.setDataForms();
  }
  get formControls(){ return this.updatePaymentForm.controls;}

  setCountryCode(): void{
    const vendorUser = this.dataService.cancelPaymentDetails ? this.dataService.vendorProfileData : this.dataService.getUser();
    if(vendorUser.phone){
      const phoneInfo = this.dataService.splitPhoneNumber(vendorUser.phone);
      this.formControls.countryCode.setValue(phoneInfo.countryCode);
      this.selectedCountryCode = phoneInfo.countryCode;
    }
  }

  setDataForms() {
    this.dataService.getCountryList()
    .subscribe(countries => {
      this.countries = countries;
      this.getVendorProfile();
    });
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

  getAddress(place: any): void {
    if (place != null && place.address_components != null) {
        for (const placeAddressComponent of place.address_components) {
            const addressType = placeAddressComponent.types[0];
            if (addressType === 'administrative_area_level_1') {
                this.formControls.state.setValue(placeAddressComponent['long_name']);
            } else if (addressType === 'administrative_area_level_2') {
                this.formControls.city.setValue(placeAddressComponent['long_name']);
            } else if (addressType === 'country') {
                this.formControls.country.setValue(placeAddressComponent['long_name']);
            } else if (addressType === 'postal_code') {
                this.formControls.pincode.setValue(placeAddressComponent['long_name']);
            }
        }
    }

    this.formControls.address.setValue(place.formatted_address);
}

companyLogoUpload(event: any): void {
  const fileList: FileList = event.target.files;
  if (fileList.length > 0) {
      for (const file of Array.from(fileList)) {
          this.logo = file;
          let reader = new FileReader();

          reader.onload = (event: ProgressEvent) => {
              this.logoUrl = (<FileReader>event.target).result;
          }

          reader.readAsDataURL(event.target.files[0]);
      }
  }
}

getVendorProfile(){
  if(this.dataService.cancelPaymentDetails){
    this.vendorProfile = this.dataService.vendorProfileData;
    this.getCurrencies();
    this.fillVendorProfile();
  }
  else{
        this.dataService.showHideLoader(true);
        this.dataService.getUserProfile()
          .subscribe(res=> {
            this.vendorProfile = res.data;
            this.getCurrencies();
            this.fillVendorProfile();
            this.dataService.showHideLoader(false);
          }, error => {
            console.log(error);
            this.dataService.showHideLoader(false);
          });
  }
}

fillVendorProfile(){
  this.logoUrl = this.vendorProfile.logo ? this.vendorProfile.logo.file_url: '';
  if(this.logoUrl){
    this.formControls.compLogo.setValue("");
    this.formControls.compLogo.clearValidators();
    this.formControls.compLogo.updateValueAndValidity();
  }
  this.formControls.orgName.setValue(this.vendorProfile.name);
  this.formControls.companyName.setValue(this.vendorProfile.company_name);
  const phoneInfo = this.dataService.splitPhoneNumber(this.vendorProfile.phone);
  const phoneNumber = phoneInfo.phoneNumber;
  this.formControls.ofcPhone.setValue(phoneNumber);
  this.formControls.compRegNum.setValue(this.vendorProfile.company_reg_number);

  if(this.vendorProfile.address){
    this.formControls.address.setValue(this.vendorProfile.address.address);
    this.formControls.city.setValue(this.vendorProfile.address.city);
    this.formControls.state.setValue(this.vendorProfile.address.state);
    this.formControls.pincode.setValue(this.vendorProfile.address.pincode);
    this.formControls.country.setValue(this.vendorProfile.address.country);
  }

  if(this.vendorProfile.payment_detail){
    this.formControls.accName.setValue(this.vendorProfile.payment_detail.name_of_account);
    this.formControls.accNumber.setValue(this.vendorProfile.payment_detail.account_number.toString());
    this.formControls.ifsccode.setValue(this.vendorProfile.payment_detail.ifsc_code);
    this.formControls.nameOfBranch.setValue(this.vendorProfile.payment_detail.name_of_bank_branch);
    this.formControls.gstNumber.setValue(this.vendorProfile.payment_detail.gst_number);
    this.formControls.panNumber.setValue(this.vendorProfile.payment_detail.pan_number);
    this.formControls.swiftcode.setValue(this.vendorProfile.payment_detail.swift_code);
    this.formControls.payingIn.setValue(this.vendorProfile.payment_detail.currency.code);
    setTimeout(() => {
      this.selectCurrency(this.formControls.payingIn.value);
    }, 0);

  }
  if(this.dataService.getUser().payment_detail){
    setTimeout(() => {
      this.currencyDisabled = true;
      this.formControls.payingIn.disable();
    }, 100);
  }
}

updatePaymentDetails(){
  this.isSubmitted = true;
  this.dataService.showHideLoader(true);
  if(this.updatePaymentForm.invalid){
    this.dataService.showHideLoader(false);
    return;
  }
  let addressID = (this.vendorProfile.address && this.vendorProfile.address.id) ? this.vendorProfile.address.id : '';
  let paymentID = (this.vendorProfile.payment_detail && this.vendorProfile.payment_detail.id) ? this.vendorProfile.payment_detail.id : '';
  //let currencyId = (this.vendorProfile.payment_detail && this.vendorProfile.payment_detail.currency && this.vendorProfile.payment_detail.currency.id) ? this.vendorProfile.payment_detail.currency.id : '';
  let logoID = (this.vendorProfile.logo && this.vendorProfile.logo.id) ? this.vendorProfile.logo.id : '';
  let logoFile = this.logo ? this.logo : (this.dataService.cancelPaymentDetails ? this.dataService.vendorProfileData.logo.file : '');
  let currencyId = this.currencyDisabled ? this.vendorProfile.payment_detail.currency.id : this.getCurrencyID(this.updatePaymentForm.value.payingIn);
  let currencyCode = this.currencyDisabled ? this.vendorProfile.payment_detail.currency.code : this.updatePaymentForm.value.payingIn;

  this.dataService.vendorProfileData = {
      name: this.updatePaymentForm.value.orgName,
      company_name: this.updatePaymentForm.value.companyName,
      phone: '+'+this.updatePaymentForm.value.countryCode+'-'+this.updatePaymentForm.value.ofcPhone,
      company_reg_number: this.updatePaymentForm.value.compRegNum,
      address: {
        id: addressID,
        address: this.updatePaymentForm.value.address,
        city: this.updatePaymentForm.value.city,
        state: this.updatePaymentForm.value.state,
        country: this.updatePaymentForm.value.country,
        pincode: this.updatePaymentForm.value.pincode
      },
      payment_detail: {
        id: paymentID,
        name_of_account: this.updatePaymentForm.value.accName,
        account_number: this.updatePaymentForm.value.accNumber,
        ifsc_code: this.updatePaymentForm.value.ifsccode,
        name_of_bank_branch: this.updatePaymentForm.value.nameOfBranch,
        gst_number: this.updatePaymentForm.value.gstNumber,
        pan_number: this.updatePaymentForm.value.panNumber,
        swift_code: this.updatePaymentForm.value.swiftcode,
        currency:{
          id: currencyId,
          code: currencyCode
        }
      },
      logo: {
        id: logoID,
        file: logoFile,
        file_url: this.logoUrl
      }
  }
  this.dataService.cancelPaymentDetails = false;
  this.dataService.showHideLoader(false);
  this.router.navigate(['/vendor/billing/invoices/confirm-payment-detail']);
}

getCurrencies(){
  this.dataService.getCurrencies()
  .subscribe(res => {
    this.currencies = res.data;
    if((!this.dataService.cancelPaymentDetails && (!this.vendorProfile.payment_detail))){
          setTimeout(() => {
            if(this.formControls.country.value === 'India'){
              this.formControls.payingIn.setValue("INR");
              this.showInr = true;
              this.showUsd = false;

              this.formControls.swiftcode.setValue("");

              this.formControls.swiftcode.clearValidators();
              this.formControls.swiftcode.updateValueAndValidity();

              this.formControls.ifsccode.setValidators([Validators.required, this.noWhitespaceValidator]);
              this.formControls.panNumber.setValidators([Validators.required, this.noWhitespaceValidator]);

            }
            else{
              this.formControls.payingIn.setValue("USD");
              this.showInr = false;
              this.showUsd = true;

              this.formControls.swiftcode.setValidators([Validators.required, this.noWhitespaceValidator]);

              this.formControls.ifsccode.setValue("");
              this.formControls.ifsccode.clearValidators();
              this.formControls.ifsccode.updateValueAndValidity();

              this.formControls.gstNumber.setValue("");

              this.formControls.panNumber.setValue("");
              this.formControls.panNumber.clearValidators();
              this.formControls.panNumber.updateValueAndValidity();

            }
          }, 1000);
    }

  }, error => {
    console.log(error);
  });
}

getCurrencyID(code: string){
  let currencyOBJ = this.currencies.find(currency => currency.code === code);
  return currencyOBJ.id;
}

selectCurrency(value){

  if(value === 'INR'){
    this.showInr = true;
    this.showUsd = false;
    this.formControls.swiftcode.setValue("");

    this.formControls.swiftcode.clearValidators();
    this.formControls.swiftcode.updateValueAndValidity();

    this.formControls.ifsccode.setValidators([Validators.required, this.noWhitespaceValidator]);
    this.formControls.panNumber.setValidators([Validators.required, this.noWhitespaceValidator]);

  }
  else{
    this.showInr = false;
    this.showUsd = true;

    this.formControls.swiftcode.setValidators([Validators.required, this.noWhitespaceValidator]);

    this.formControls.ifsccode.setValue("");
    this.formControls.ifsccode.clearValidators();
    this.formControls.ifsccode.updateValueAndValidity();

    this.formControls.gstNumber.setValue("");

    this.formControls.panNumber.setValue("");
    this.formControls.panNumber.clearValidators();
    this.formControls.panNumber.updateValueAndValidity();
  }
}

}
