import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { ResendConfirmationEmailComponent } from './resend-confirmation-email.component';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        LoaderModule
    ],
    declarations: [
        ResendConfirmationEmailComponent
    ]
})

export class ResendConfirmationEmailModule { }
