import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { InboxComponent } from './inbox.component';

@NgModule({
    imports: [
      CommonModule,
      RouterModule
    ],
    declarations: [InboxComponent]
})

export class InboxModule { }
