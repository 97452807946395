import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { PhaseService } from '../../shared/services/phase.service';

@Component({
    selector: 'inbox',
    templateUrl: './inbox.component.html',
    styleUrls: ['./inbox.component.scss']
})

export class InboxComponent {

    constructor(
        private phaseService: PhaseService,
        private router: Router
    ) {
        this.phaseService.leftMenuActive = 'inbox';
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }

}
