import { Component, ViewEncapsulation, HostListener, ApplicationRef, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Intercom } from 'ng-intercom';
import { SwUpdate } from '@angular/service-worker';

import { PhaseService } from './shared/services/phase.service';
import { DataService } from './shared/services/data.service';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
  selector: 'app',
  styleUrls: ['./stylesheets/index.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  providers: []
})
export class AppComponent implements OnInit {
  phaseService;
  dataService;
  isShowHeader: boolean;
  router;
  idleState = 'Not started.';
  timedOut = false;
  chatWindowShown = false;
  currentUrl = '';
  joinAdeveloper: boolean = false;
  autoUpdateApp = true;

  constructor(
    phaseService: PhaseService, dataService: DataService, router: Router, public route: ActivatedRoute,
    private idle: Idle, private ref: ApplicationRef, private intercom: Intercom,
    private renderer: Renderer2, private analyticsSegment: Angulartics2Segment,
    private swUpdate: SwUpdate
  ) {
    this.phaseService = phaseService;
    this.dataService = dataService;
    this.router = router;
    this.setCurrentOrigin();
    this.redirectCall();
    idle.setIdle(5);
    idle.setTimeout(30);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
    });
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.startTimeOut();
    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
    });
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });
    this.phaseService.getJsonFromUrl();
    router.events.subscribe((val) => {
      this.dataService.showInviteMembers = false;
      this.dataService.classInviteMembers = false;
        if (((val instanceof NavigationStart || val instanceof NavigationEnd) && val.url.indexOf('register') >= 0) || ((val instanceof NavigationStart || val instanceof NavigationEnd) && val.url === '/login')) {
            this.phaseService.showFooter = false;
            this.renderer.addClass(document.body, 'register');
            if (val.url.indexOf('confirmed') >= 0) {
              this.renderer.removeClass(document.body, 'register');
            }
        } else if ((val instanceof NavigationStart || val instanceof NavigationEnd) && val.url.indexOf('register') === -1 || ((val instanceof NavigationStart || val instanceof NavigationEnd) && val.url !== '/login')) {
            this.phaseService.showFooter = true;
            this.renderer.removeClass(document.body, 'register');
        }
    });
  }

  ngOnInit() {
    this.checkMaintenance();
    //this.handleSoftwareUpdate();
    setInterval(() => this.checkMaintenance(), 30000);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    if (window.location.pathname.split('/')[1] === 'join') {
      this.isShowHeader = false;
    } else {
      this.isShowHeader = true;
    }
    this.updateUserAfterHelloSign();
    if (this.dataService.getUser()) {
      this.getRateCardCount();
      this.getUserProfile();
      if (!this.dataService.isOneSignalInitialized) {
        this.dataService.initializeOneSignalNotification();
      }
    }
  }

  private handleSoftwareUpdate() {
    if (this.swUpdate.isEnabled) {
        this.autoUpdateApp = false;
        this.swUpdate.available.subscribe(() => {
            if(confirm("A newer version of our site is now available. Refresh the page now to update")) {
              this.swUpdate.activateUpdate().then(() => document.location.reload());
            }
        });
    }
  }

  closeRejectedDevPopup(): void {
    this.dataService.rejectedDevs = [];
  }

  checkMaintenance(): void {
    if (this.autoUpdateApp) {
      this.handleSoftwareUpdate();
    }
    this.dataService.getMaintenanceReport()
    .subscribe(res => {
      this.dataService.isMaintenanceOn = res.maintenance ? 'show' : 'hide';
      if (window.location.origin.indexOf('accenture') === -1 && this.dataService.isMaintenanceOn === 'hide' && !this.dataService.getUser()) {
        this.dataService.intercomShowHide('activate');
      }
    });
  }

  private getRateCardCount(): void {
      this.dataService.getRateCards()
      .subscribe((data) => {
          this.phaseService.updateRateCardCount(data);
      },() => this.dataService.showPageLoader = false);
  }

  private getUserProfile(): void {
    this.dataService.getUserProfile()
    .subscribe(res => {
      this.dataService.setUser(res.data);
      const vendorUser = this.dataService.getUser();
      if (vendorUser.tutorials.indexOf("migratePopup") < 0){
        this.dataService.firstMigrationPopup = true;
      } else if (vendorUser.tutorials.indexOf("migrationDone") < 0){
        // call migrate api(if review later + have some card to be migrate pending)
        this.dataService.showRCMigrateStrip();
      }
      if (res.data.skills) {
        const skillsAvailable = res.data.skills.map(skill => skill.title);
        this.analyticsSegment.setUserProperties({
          'Skills available': skillsAvailable,
          userId: vendorUser ? vendorUser.id : ''
        });
      } else {
        this.analyticsSegment.setUserProperties({
          'Skills available': [],
          userId: vendorUser ? vendorUser.id : ''
        });
      }
    },() => this.dataService.showPageLoader = false);
  }

  updateUserAfterHelloSign() {
    if (this.dataService.ndaSigned === 'signature_request_signed') {
      let data = {
        vendor: {
          nda_signed: true,
          signature_request_guid: this.dataService.signature_request_guid,
          signature_request_status: this.dataService.signature_request_status,
          present_state: 'nda'
        }
      };
      this.dataService.showNdaLoader = true;
      this.dataService.updateUserWithHelloSign(data);
    }
  }

  private startTimeOut() {
    if (!this.chatWindowShown) {
      this.chatWindowShown = true;
      this.intercom.showNewMessage('');
    }
  }

  private setCurrentOrigin() {
    this.dataService.isLiquidStudio = window.location.origin ? window.location.origin.indexOf('accenture') != -1 && window.location.origin.indexOf('staging') === -1 ? true : false : false;
    this.dataService.isLiquidStudioStaging = window.location.origin ? window.location.origin.indexOf('accenture') != -1 && window.location.origin.indexOf('staging') != -1 ? true : false : false;
    this.dataService.isDev = window.location.origin ? (window.location.origin.indexOf('localhost') != -1 || window.location.origin.indexOf('develop-partner') != -1) ? true : false : false;
    this.dataService.isStaging = window.location.origin ? window.location.origin.indexOf('staging') != -1 && window.location.origin.indexOf('accenture') === -1 ? true : false : false;
    this.dataService.isProduction = window.location.origin ? (this.dataService.isDev || this.dataService.isStaging || this.dataService.isLiquidStudio || this.dataService.isLiquidStudioStaging) ? false : true : false;
  }

  private reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  private warning() {
    const timeout = setTimeout(this.timeOutMethod, 1000);
    return 'You have unsaved data changes. Are you sure to close the page?';
  }

  private timeOutMethod() {
    this.ref.tick();
  }

  @HostListener('window:beforeunload', ['$event'])
  private beforeUnloadHander(event) {
    if (this.dataService.unsavedChanges) {
      event.returnValue = this.warning();
    }
  }

  hideAllPopups() {
    this.phaseService.showList = false;
    this.phaseService.isBellClicked = false;
  }

  private trackEvent(eventString) {
    if (eventString === 'talktous') {
      this.intercom.showNewMessage('');
    }
  }
  private redirectCall() {
    const vendorUser = this.dataService.getUser();
    const path: string = window.location.hash;
    let subUrl = '';
    if (path && path.length > 0) {
      if (path.indexOf('?') !== -1) {
        subUrl = path.substr(2, path.indexOf('?') - 2);
      } else {
        subUrl = path.substr(2);
      }
      if (subUrl !== '') {
        if (subUrl === 'confirmation') {
          this.router.navigate(['confirming-email']);
        } else if (subUrl === 'become-a-vendor' || subUrl === 'register' || subUrl === 'form_vendor') {
          this.dataService.isFormFlow = true;
        } else if (subUrl === 'reset-password') {
          this.router.navigate(['reset-password']);
        } else if (subUrl === 'privacy_policy') {
          this.dataService.privacyPage = true;
        } else if (subUrl === 'terms_and_conditions') {
          this.dataService.tncPage = true;
        } else if (subUrl.split('/')[0] === 'join') {
          this.joinAdeveloper = true;
        }

      }
    }
    if (this.dataService.privacyPage) {

      this.router.navigate(['privacy_policy']);

    }
    else if (this.dataService.tncPage) {

      this.router.navigate(['terms_and_conditions']);

    }
    else if (this.dataService.isFormFlow) {
      this.router.navigate(['register']);
    }

    else if (this.joinAdeveloper) {
      this.router.navigate(['join', subUrl.split('/')[1]]);
    }
    else if (vendorUser) {
      if (vendorUser.present_state && vendorUser.present_state === 'organisation') {
        this.router.navigate(['register/confirmed/1']);
      } else if (vendorUser.present_state && vendorUser.present_state === 'signatory_spoc') {
        this.router.navigate(['register/confirmed/2']);
      } else if (vendorUser.present_state && vendorUser.present_state === 'business_spoc') {
        this.router.navigate(['register/confirmed/3']);
      } else if (vendorUser.present_state && vendorUser.present_state === 'team_structure') {
        this.router.navigate(['register/confirmed/4']);
      } else if (vendorUser.present_state && vendorUser.present_state === 'documents') {
        this.router.navigate(['contract']);
      } else if (vendorUser.present_state && vendorUser.present_state === 'nda') {
        this.router.navigate(['contract']);
      } else {
        this.router.navigate(['vendor/dashboard']);
      }
    }


  }

}
