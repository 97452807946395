import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { PhaseService } from '../shared/services/phase.service';
import { DataService } from '../shared/services/data.service';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Intercom } from 'ng-intercom';
import { Subscription } from 'rxjs';

@Component({
  selector: 'left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})

export class LeftMenuComponent implements OnInit, OnDestroy {
    phaseService: any;
    dataService: any;
    router: any;
    isNewRateCardCreatedObservable: Subscription;

    constructor(
        phaseService: PhaseService,
        dataService: DataService,
        router: Router,
        private location: Location,
        private analyticsSegment: Angulartics2Segment,
        private intercom: Intercom
    ) {
        this.phaseService = phaseService;
        this.dataService = dataService;
        this.router = router;
        this.isNewRateCardCreatedObservable = this.dataService.isNewRateCardCreated.subscribe(() => {
            this.getNotificationCount();
        });
    }

    ngOnInit(): void {
        this.getNotificationCount();
    }

    ngOnDestroy(): void {
        if (this.isNewRateCardCreatedObservable) this.isNewRateCardCreatedObservable.unsubscribe();
    }

    getNotificationCount(): void{
        this.dataService.getNotificationCount()
        .subscribe(res => {
            this.dataService.notificationCount = res.data.notification_count;
            if (this.dataService.oneSignalNotification) {
                this.dataService.notificationCount = 0;
            }
        }, err => {
            console.log(err);
        });
    }

    isShowMyAccount(): boolean {
        return this.location.path().indexOf('register') === -1 && this.location.path().indexOf('/contract') === -1;
    }

    showOptionList(e) {
        this.phaseService.showList ? this.phaseService.showList = false : this.phaseService.showList = true;
        e.stopPropagation();
    }

    logoutUser() {
        window['analytics'].reset();
        this.dataService.signout_user().subscribe(() => localStorage.clear());
        this.dataService.intercomShowHide('activate');
        this.router.navigate([this.dataService.firstPage]);
    }

    performUserActions(option) {
        this.phaseService.showList = false;
        if (option === 'logout') {
            this.logoutUser();
        }
    }
    

    redirectTo(path: string): void {
        const user = this.dataService.getUser();
        if (path === '/vendor/inbox/notifications') {
            this.analyticsSegment.eventTrack('Inbox', {email: user ? user.email : ''});
        } else if (path === '/vendor/billing/invoices') {
            this.analyticsSegment.eventTrack('Billing', {email: user ? user.email : ''});
        }
        this.router.navigate([path]);
    }

    onLogoClick(): void {
        if (this.location.path().indexOf('register') >= 0 || this.location.path().indexOf('contract') >= 0) return;
        if (this.dataService.getUser()) {
          this.router.navigate(['vendor/dashboard']);
        } else {
          this.router.navigate(['login']);
        }
    }

}
