import {Injectable} from "@angular/core";
import { Router } from '@angular/router';
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import "rxjs/add/operator/mergeMap";
import {DataService} from "./data.service";
import * as moment from 'moment';

@Injectable()
export class PhaseService {
  randomNum = Math.random();
  public devsInNotification = [];
  showFooter = true;
  showLinkDevToRateCardPopup = false;
  notificationData: any;
  public leftMenuActive = 'myProjects';
  public rateCardCount = 0;
  public dataService;
  public requestedFeatureList = [];
  public selectedFeatures = [];
  public showList = false;
  public isBellClicked = false;
  public isNdaFlow = false;
  public selectedIndex = -1;
  public showSignin = false;
  public showRegister = false;
  public showForgotPassword = false;
  public showAddMoreDev = false;
  public resetPasswordToken = null;
  trackerDeveloperName: string;
  showMyTeamDetail = false;
  sampleInvoiceUrl: string;
  developerAllocation = '' as any;

  constructor(dataService: DataService, private router: Router) {
    this.dataService = dataService;
  }

  public updateRateCardCount(data: any): void {
    if (data.data) {
      this.rateCardCount = data.data.length;
    } else {
      this.rateCardCount = 0;
    }
  }

  public capitalizeFirstLetter(string) {
    if (string) {
      if (string.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } else {
        return string.charAt(0).toUpperCase();
      }
    }
  }

  public getJsonFromUrl() {
    const path = location.href;
    let query;
    const vendorUser = this.dataService.getUser();
    if (path.indexOf("?") != -1) {
      query = path.substr(path.indexOf("?") + 1);
      const result = {} as any;
      query.split("&").forEach((part) => {
        const item = part.split("=");
        if (item[0] != '') {
          result[item[0]] = decodeURIComponent(item[1]);
        }
      });
      if (result.notification_type && result.notification_type === 'onesignal') {
        this.dataService.oneSignalNotification = true;
        this.dataService.notificationCount = 0;
        this.router.navigate(['vendor/inbox/notifications']);
      } else if (result.notification_type && result.notification_type === 'request') {
        this.dataService.allocationId = result.allocation_id;
        this.dataService.allocationRequestData = {
            from: result.from,
            to: result.to
        };
        this.dataService.notificationCount = 0;
        this.dataService.afterLoginRedirectTo = 'vendor/schedule';
        if (vendorUser) {
          this.router.navigate([this.dataService.afterLoginRedirectTo]);
        }
      } else if (result.invoice_id) {
        // this.dataService.invoiceId = result.invoice_id;
        this.dataService.afterLoginRedirectTo = `vendor/invoices/${result.invoice_id}`;
        if (vendorUser) {
          this.router.navigate([`vendor/invoices/${result.invoice_id}`]);
        }
      } else if (result.add_hours) {
        this.dataService.submitHoursData = result;
        this.dataService.afterLoginRedirectTo = 'vendor/billing/invoices/invoice-listing';
        if (vendorUser) {
          this.router.navigate([this.dataService.afterLoginRedirectTo]);
        }
      } else if (result.show_upskill_rate_cards) {
        this.dataService.show_upskill_rate_cards = true;
        this.dataService.afterLoginRedirectTo = 'vendor/schedule';
        if (vendorUser) {
          this.router.navigate([this.dataService.afterLoginRedirectTo]);
        }
      }
      const subUrl = window.location.hash.substr(2, window.location.hash.indexOf('?') - 2);
      if (subUrl.indexOf('confirmation') < 0) {
        this.setIncomingUrlParameters(result);
      }
      return result;
    }
    return null;
  }

  public setIncomingUrlParameters(urlInfo) {
    if (urlInfo && urlInfo.token) {
      this.resetPasswordToken = urlInfo.token;
    }
    return this.resetPasswordToken;
  }

}
