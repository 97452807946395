
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DataService } from '../shared/services/data.service';
import { HttpService } from '../shared/services/http.service';

@Injectable()
export class RegisterService {

    constructor(private dataService: DataService, private httpService: HttpService) { }

    registerVendor(email: string, password: string): Observable<any> {
        const url = `${this.dataService.getServerUrl()}vendor/profile`;
        const body = {
            vendor: {
                email: email,
                password: password
            }
        }
        return this.httpService.postRequest(url, body);
    }

    submitOtp(otp: string): Observable<any> {
        const url = `${this.dataService.getServerUrl()}vendor/profile/confirmation?confirmation_token=${otp}`;
        return this.httpService.getRequest(url);
    }

    updateUserWithOrganization(name: string, phone: string, domainNames: string[],
    companyRegistrationNumber: string, address: string, city: string, state: string,
    country: string, pincode: string, logo: any, countryCode: string, addressId: string): Observable<any> {
        const vendorUser = this.dataService.getUser();
        const url = `${this.dataService.getServerUrl()}vendor/profile`;
        const body = new FormData();
        body.append('vendor[present_state]', 'organisation');
        body.append('vendor[name]', name);
        body.append('vendor[phone]', `+${countryCode}-${phone}`);
        body.append('vendor[company_reg_number]', companyRegistrationNumber);
        for(let domainName of domainNames) {
            body.append('vendor[domain_names][]', domainName);
        }
        const logoFile = logo && logo.lastModified ? logo : '';
        if (logoFile) {
            body.append('vendor[logo_attributes][file]', logoFile);
        }
        if (addressId) {
            body.append('vendor[address_attributes]id', addressId);
        }
        body.append('vendor[address_attributes]address', address);
        body.append('vendor[address_attributes]city', city);
        body.append('vendor[address_attributes]state', state);
        body.append('vendor[address_attributes]country', country);
        body.append('vendor[address_attributes]pincode', pincode);
        return this.httpService.putRequest(url, body);
    }

    updateUserWithSpoc(spocId: string, name: string, email: string, phone: string,
        spocType: string, countryCode: string): Observable<any> {
        const vendorUser = this.dataService.getUser();
        const url = `${this.dataService.getServerUrl()}vendor/profile`;
        const body = {
            vendor: {
                present_state: spocType === 'signatory' ? 'signatory_spoc' : 'business_spoc',
                spocs_attributes: [
                    {
                        name: name,
                        email: email,
                        phone: `+${countryCode}-${phone}`,
                        spoc_type: spocType
                    } as any
                ]
            }
        } as any
        if (spocId) {
            body.vendor.spocs_attributes[0].id = spocId;
        }
        return this.httpService.putRequest(url, body);
    }

    updateUserWithTeamStructure(teamStructureIds: any[], editMode: boolean): Observable<any> {
        const url = `${this.dataService.getServerUrl()}vendor/profile`;
        const body = {
            vendor: {
                present_state: 'team_structure',
                skills_attributes: teamStructureIds
            }
        }
        return this.httpService.putRequest(url, body);
    }

}
