import { Component, OnInit } from '@angular/core';
import { DataService } from "../shared/services/data.service";
import { RateCardService } from "../core/rate-card/rate-card.service";

@Component({
  selector: 'notification-strip',
  templateUrl: './notification-strip.component.html',
  styleUrls: ['./notification-strip.component.scss']
})
export class NotificationStripComponent implements OnInit {

  constructor(public dataService: DataService, public rateCardService: RateCardService) { }

  ngOnInit() {
  }

  openFirstMigrationPopup(): void{
    this.dataService.firstMigrationPopup = true;
  }

  reviewNewRateCard(): void{
    this.dataService.rateCardPopup = true;
    this.rateCardService.clickedScreen = "migrate";
    this.dataService.mixpanel_events_track('event', 'Review new');
  }

}
