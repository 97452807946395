import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpService {
    constructor(private http: HttpClient) { }

    getRequest(url: string, headers?: HttpHeaders): Observable<any> {
        if (headers) {
            return this.http.get(url, {headers: headers});
        } else {
            return this.http.get(url);
        }
    }

    postRequest(url: string, data: any, headers?: HttpHeaders): Observable<any> {
        if (headers) {
            return this.http.post(url, data, {headers: headers});
        } else {
            return this.http.post(url, data);
        }
    }

    putRequest(url: string, data: any, headers?: HttpHeaders): Observable<any> {
        if (headers) {
            return this.http.put(url, data, {headers: headers});
        } else {
            return this.http.put(url, data);
        }
    }

    patchRequest(url: string, data: any, headers?: HttpHeaders): Observable<any> {
        if (headers) {
            return this.http.patch(url, data, {headers: headers});
        } else {
            return this.http.patch(url, data);
        }
    }

    deleteRequest(url: string, headers?: HttpHeaders): Observable<any> {
        if (headers) {
            return this.http.delete(url, {headers: headers});
        } else {
            return this.http.delete(url);
        }
    }    

}
