
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DataService } from '../../shared/services/data.service';
import { HttpService } from '../../shared/services/http.service';

@Injectable()
export class SettingsService {

    constructor(private dataService: DataService, private httpService: HttpService) { }

    getAutoMatchPreferences(): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_rate_card_preferences}`;
        return this.httpService.getRequest(url);
    }

    deletePreference(preferenceId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_rate_card_preferences}/${preferenceId}`;
        return this.httpService.deleteRequest(url);
    }

    changePassword(oldPassword: string, newPassword: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.change_password}`;
        const postJson = {
            password: oldPassword,
            new_password: newPassword,
            new_password_confirmation: newPassword
        }
        return this.httpService.postRequest(url, postJson);
    }

    saveReadOnlyUser(name: string, email: string, password: string, id: number): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.read_only_user}`;
        const body = {
            member: {
                name
            }
        }
        if (email) {
            body.member['email'] = email;
        }
        if (password) {
            body.member['password'] = password;
        }
        if (id) {
            url += `/${id}`;
            return this.httpService.putRequest(url, body);
        } else {
            return this.httpService.postRequest(url, body);
        }
    }

    updateForm(formDetails): Observable<any> {
       const vendorUser = this.dataService.getUser();
        const data = new FormData();
        const API = this.dataService.getServerUrl() + this.dataService.apiEndPoints.vendor_signup;
        for (const key of Object.keys(formDetails)) {
          const value = formDetails[key];
          const underscored_key = this.dataService.underscore(this.dataService.CONSTANTS.VENDOR_BRACKET + this.dataService.CONSTANTS.OPEN_BRACKET + key + this.dataService.CONSTANTS.CLOSE_BRACKET);
          if (key == "documents_attributes") {
            let index = 0;
            for (const item of value) {
              if (item.file) {
                data.append(underscored_key + this.dataService.CONSTANTS.OPEN_BRACKET + index + this.dataService.CONSTANTS.CLOSE_BRACKET + this.dataService.CONSTANTS.FILE_PARAM, item.file, item.file.name);
                data.append(underscored_key + this.dataService.CONSTANTS.OPEN_BRACKET + index + this.dataService.CONSTANTS.CLOSE_BRACKET + this.dataService.CONSTANTS.DOCUMENT_PARAM, item.document_scope);
              } else {
                data.append(underscored_key + this.dataService.CONSTANTS.OPEN_BRACKET + index + this.dataService.CONSTANTS.CLOSE_BRACKET + '[id]', item.id);
                data.append(underscored_key + this.dataService.CONSTANTS.OPEN_BRACKET + index + this.dataService.CONSTANTS.CLOSE_BRACKET + '[_destroy]', item._destroy);
              }
              index++;
            }
          } else if (key === 'domain_names') {
            for (const item of value) {
              data.append(underscored_key + this.dataService.CONSTANTS.BRACKETS, item);
            }
          } else if (key == "logo_attributes") {
            if (value) {
              if(vendorUser.logo){
                data.append('vendor[logo_attributes][id]', vendorUser.logo.id);
              }
              data.append(underscored_key + this.dataService.CONSTANTS.FILE_PARAM, value, value.name);
            }
          } else if (key == "address_attributes") {
            for (const item of Object.keys(value)) {
              const nesv = value[item];
              if (nesv != null) {
                data.append(underscored_key + this.dataService.underscore(item), nesv);
              }
            }
          } else if (key == "spocs_attributes") {
            for (const item of Object.keys(value)) {
              const nesv = value[item];
              for (const k of Object.keys(nesv)) {
                const v = nesv[k];
                if (v != null) {
                  data.append(underscored_key + this.dataService.CONSTANTS.BRACKETS + this.dataService.underscore(k), v);
                }
              }
            }
          }
          else if (key == "skills_attributes") {
            if (Object.keys(value) && Object.keys(value).length > 0) {
              for (const item of Object.keys(value)) {
                const nesv = value[item];
                for (const k of Object.keys(nesv)) {
                  const v = nesv[k];
                  data.append(underscored_key + this.dataService.CONSTANTS.BRACKETS + this.dataService.underscore(k), v);
                }
              }
            } else {
              data.append(underscored_key + this.dataService.CONSTANTS.BRACKETS, '[]');
            }
          } else if (key == "compliance_attributes") {
            if (value != null) {
              data.append(underscored_key + this.dataService.CONSTANTS.FILE_PARAM, value, value.name);
            }
          } else if (value != null) {
            data.append(underscored_key, value);
          }
        }
        return this.httpService.putRequest(API, data);
      }

}
