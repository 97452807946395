import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  @Input() toasterData: any;
  @Output() onCloseToaster = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    setTimeout(() =>{ 
      this.onCloseToaster.emit();
    }, 3000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.toasterData && changes.toasterData.currentValue) {
    //     console.log(changes.toasterData);
    // }
    
}

closeToaster() {
  this.onCloseToaster.emit();
}

}
