import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DataService } from '../../shared/services/data.service';
import { HttpService } from '../../shared/services/http.service';
import { TutorialService } from "../../tutorial/tutorial.service";

@Injectable()
export class RateCardService {
    setupRatesForSkillExpertise = [];
    public clickedScreen = 'migrate';
    currentRateCard;
    notificationRateCard;
    rateCardavAilabilityToastData = {
        showToaster: false,
        status: '',
        text: ''
    };

    constructor(private dataService: DataService, private httpService: HttpService, public tutorialService: TutorialService) {}

    getSkillList(): Observable<any> {
        let url = this.dataService.getServerUrl() + this.dataService.apiEndPoints.skills;
        return this.httpService.getRequest(url);
    }

    getRateCardFilters(): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.getRateCards}/filters`;
        return this.httpService.getRequest(url);
    }

    getFilteredDevelopers(skillId: string, expertise: string, country: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.getRateCards}/developers`;
        let filters = [];
        if (skillId != 'all') {
            filters.push(`skill_id=${skillId}`);
        }
        if (expertise != 'all') {
            filters.push(`expertise=${expertise}`);
        }
        if (country != 'all') {
            filters.push(`country=${country}`);
        }
        if (filters.length > 0) {
            url += `?${filters.join('&')}`;
        }
        return this.httpService.getRequest(url);
    }

    getRateCardDetails(rateCardId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.getRateCards}/${rateCardId}`;
        return this.httpService.getRequest(url);
    }

    saveRateCardPrice(devId: string, ratePriceType: string, rateCardId: string): Observable<any> {
        const url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_developer_setRateCard}`;
        let putJson = {
            id: devId,
            rate: ratePriceType,
            rate_card_id: rateCardId
        }
        return this.httpService.putRequest(url, putJson);
    }

    getSuggestedRates(devs: any[]): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.getRateCards}/suggested_rates`;
        const developers = JSON.parse(JSON.stringify(devs));
        for (let dev of developers) {
            dev.developerSkills = [];
            for (let devSkill=0; devSkill<dev.developer_skills.length; devSkill++) {
                if (dev.developer_skills[devSkill].selected === 'remove') {
                    dev.developerSkills.push(dev.developer_skills[devSkill]);
                }
            }
            dev.developer_skills = dev.developerSkills;
        }
        return this.httpService.postRequest(url, {developers});
    }

    createRateCard(currentRateCard: any, spotRate: number, demandRate: number, suggestedRates: any, developer_skills: any, is_last_rate_card: boolean, isCustomRateCard): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.getRateCards}`;
        let body = {
            rate_card: {
                expertise: currentRateCard.expertise,
                skill_id: currentRateCard.skill.id,
                bench_price: spotRate,
                on_demand_price: demandRate,
                developer_skills: developer_skills,
                is_last_rate_card: is_last_rate_card
            }
        }
        if(isCustomRateCard){
            delete body.rate_card.developer_skills;
            body.rate_card['temporary'] = true;
            body.rate_card['developer_skill_id'] = developer_skills;
        }
        // if (suggestedRates) {
        //     body.rate_card['suggested_bench_price'] = suggestedRates.spot_price;
        //     body.rate_card['suggested_on_demand_price'] = suggestedRates.on_demand;
        // }
        return this.httpService.postRequest(url, body);
    }

    updateRateCard(currentRateCard: any, spotRate: number, demandRate: number, suggestedRates: any): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.getRateCards}/${currentRateCard.id}`;
        let body = {
            rate_card: {
                bench_price: spotRate,
                on_demand_price: demandRate
            }
        } as any;
        if(currentRateCard.on_demand_price < demandRate){
            delete body.rate_card.on_demand_price;
            body.rate_card['increased_on_demand_price'] = demandRate;
            if(currentRateCard.on_demand_price < spotRate){
                delete body.rate_card.bench_price;
                body.rate_card['increased_bench_price'] = spotRate;
            }
        }
        // if (suggestedRates) {
        //     body.rate_card['suggested_bench_price'] = suggestedRates.spot_price;
        //     body.rate_card['suggested_on_demand_price'] = suggestedRates.on_demand;
        // }
        return this.httpService.putRequest(url, body);
    }

    cancelIncreasedRateCard(currentRateCard: any): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.cancelIncreasedRates}`;
        let body = {
            id: currentRateCard.id
        } as any;
        return this.httpService.putRequest(url, body);
    }

    deleteRateCard(rateCardId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.getRateCards}/${rateCardId}`;
        return this.httpService.deleteRequest(url);
    }

    unlinkDeveloper(skillId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.removeDeveloperFromRateCard}`;
        return this.httpService.postRequest(url, {developer_skill_id: skillId});
    }

    getRateCardsForDeveloperSkill(devSkillId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.getRateCards}?developer_skill_id=${devSkillId}`;
        return this.httpService.getRequest(url);
    }

    linkRateCardToDev(rateCardId: string, selectedSkillId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.getRateCards}/${rateCardId}/link`;
        const body = {
            developer_skill_id: selectedSkillId,
            rate: 'spot'
        };
        return this.httpService.postRequest(url, body);
    }

    getDevelopers(status, page, per_page) {
        const API = this.dataService.getServerUrl() + this.dataService.apiEndPoints.vendor_developers + this.dataService.CONSTANTS.QUESTION_MARK + this.dataService.CONSTANTS.STATUS + status +
          this.dataService.CONSTANTS.AND + this.dataService.CONSTANTS.PER_PAGE + per_page + this.dataService.CONSTANTS.AND + this.dataService.CONSTANTS.PAGE + page;
        let headers = this.dataService.getHeaders('application/x-www-form-urlencoded');
        return this.httpService.getRequest(API, headers);
    }

    updateTutorials(tutorialScreen: string): void {
        const user = this.dataService.getUser();
        if (user.tutorials.indexOf(tutorialScreen) >= 0) return;
        user.tutorials.push(tutorialScreen);
        this.tutorialService.gotIt(user.tutorials)
        .subscribe(res => {
            this.dataService.setUser(res.data);
        }, err => {
            console.log(err);
        });
    }

    reviewLater(): void{
        this.dataService.firstMigrationPopup = false;
        this.dataService.isNewRateCardsReady = true;
        this.updateTutorials('migratePopup');
      }

    getExpertisefromTitle(title: string){
        let skillWithExpertise = title.split(": ");
        let expertise = skillWithExpertise[1].split(" ");
        if(expertise) return expertise[1];
    }

}
