import {Pipe, PipeTransform, Injectable} from "@angular/core";

@Pipe({
  name: "filter",
  pure: false
})
@Injectable()
export class Ng2SearchPipe implements PipeTransform {

  public transform(items: any, term: any): any {
    if (term === undefined) {
      return items;
    }
    else if (term.toString().trim() === "") {
      return items;
    }
    return items.filter((item) => {
      let result = false;
      if ((item.name != null ? item.name.toLowerCase().includes(term.toLowerCase()) : false)) {
        result = true;
      }
      else if ((item.email != null ? item.email.toLowerCase().includes(term.toLowerCase()) : false)) {
        result = true;
      }
      return result;
    });
  }
}
