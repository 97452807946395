import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { NdaContractComponent } from './nda-contract.component';

import { LoaderModule } from '../loader/loader.module';
import { PartnerAgreementModule } from '../partner-agreement/partner-agreement.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        BrowserModule,
        FormsModule,
        LoaderModule,
        PartnerAgreementModule
    ],
    declarations: [
        NdaContractComponent
    ]
})

export class NdaContractModule { }
