import { Component, OnInit } from '@angular/core';

import { InvoiceService } from '../../../shared/services/invoice.service';
import { DataService } from '../../../shared/services/data.service';

@Component({
    selector: 'invoice-message',
    templateUrl: './invoice-message.component.html',
    styleUrls: ['./invoice-message.component.scss']
})

export class InvoiceMessageComponent implements OnInit {
    isShowInfo: boolean;

    constructor(
        private invoiceService: InvoiceService,
        private dataService: DataService
    ) { }

    ngOnInit() {
        this.getUserProfile();
    }

    getUserProfile(): void {
        this.dataService.getUserProfile()
        .subscribe((res: any) => {
            this.invoiceService.isShowInvoiceInfo = res.data.show_invoice_info;
            this.isShowInfo = this.invoiceService.isShowInvoiceInfo;
        });
    }

    closeInvoiceInfoPupUp() {
        this.hideInvoiceMessage();
        this.dataService.patchUserProfile({show_invoice_info: false})
        .subscribe(() => {}, err => {
            this.invoiceService.isShowInvoiceInfo = true;
            this.isShowInfo = true;
        });
    }

    hideInvoiceMessage(): void {
        this.invoiceService.isShowInvoiceInfo = false;
        this.isShowInfo = false;
    }

}
