import { Component, OnInit } from '@angular/core';

import { PhaseService } from '../../shared/services/phase.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Angulartics2Segment } from 'angulartics2/segment';
import { DataService } from '../../shared/services/data.service';
import { InvoiceService } from '../../shared/services/invoice.service';

@Component({
    selector: 'billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss']
})

export class BillingComponent implements OnInit {
    tabActive = '';
    invoiceService;
    dataService: any;

    constructor(
        private phaseService: PhaseService,
        private router: Router,
        private route: ActivatedRoute,
        dataService: DataService,
        private analyticsSegment: Angulartics2Segment,
        invoiceService: InvoiceService
    ) {
        this.dataService = dataService;
        this.phaseService.leftMenuActive = 'billing';
        this.invoiceService = invoiceService;
    }

    ngOnInit(): void {
        if (this.router.url.indexOf('invoices') >= 0) {
            this.tabActive = 'invoices';
        } else if (this.router.url.indexOf('rate-cards') >= 0) {
            this.tabActive = 'rate-cards';
        }
    }

    selectTab(tabType: string): void {
        const vendorUser = this.dataService.getUser();
        this.tabActive = tabType;
        if (tabType === 'rate-cards') {
            this.analyticsSegment.eventTrack('Rate cards', {email: vendorUser ? vendorUser.email : ''});
        }
        this.router.navigate([`${tabType}`], {relativeTo: this.route});
    }

    openSubmitHours(){
        this.invoiceService.initializeSubmitHours = true;
        setTimeout(() => {
            this.invoiceService.showSubmitHours = true;
        }, 300);
    }

}
