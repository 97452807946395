import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { NeedHelpComponent } from './need-help.component';
import { NeedHelpRoutingModule } from './need-help-routing.module';
import { NeedHelpService } from './need-help.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NeedHelpRoutingModule,
        InfiniteScrollModule
    ],
    declarations: [
        NeedHelpComponent,
    ],
    providers: [NeedHelpService]
})

export class NeedHelpModule { }
