import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";

import { DataService } from "../../../shared/services/data.service";
import { InvoiceService } from '../../../shared/services/invoice.service';
import { TutorialService } from '../../../tutorial/tutorial.service';

@Component({
  selector: 'app-invoice-listing',
  templateUrl: './invoice-listing.component.html',
  styleUrls: ['./invoice-listing.component.scss']
})
export class InvoiceListingComponent implements OnInit, OnDestroy {

  invoices = [];
  private readonly PER_PAGE = 10;
  page = 1;
  isScrolled = false;
  meta: any;
  interval: any;
  quickTip = {
              'title': '',
              'image_url': ''
            };
  dataService:any;
  subscription: any;
  newUpInvoicesIds: Array<any> = [];
  quickSubscription: any;
  invoiceCycle: any;
  showInvoiceAlreadyGeneratedPopup = false;
  invoicePopupError = '';

  constructor(dataService: DataService, private router: Router, private invoiceService: InvoiceService, private tutorialService: TutorialService) {
    this.dataService = dataService;
  }

  ngOnInit() {
    this.dataService.afterLoginRedirectTo = '';
    this.isPaymentDetailsFound();
  }

  getSubmitHoursInfo(): void {
      this.dataService.showHideLoader(true);
      this.invoiceService.getSubmitHoursInfo(this.dataService.submitHoursData.start_date, this.dataService.submitHoursData.end_date)
      .subscribe(res => {
        this.dataService.submitHoursData = null;
        this.invoiceService.initializeSubmitHours = true;
        setTimeout(() => {
          this.invoiceService.showSubmitHours = true;
        }, 300);
        this.showInvoiceAlreadyGeneratedPopup = false;
        this.invoicePopupError = '';
        this.dataService.showHideLoader(false);
      }, err => {
          const error = err.error;
          this.dataService.showHideLoader(false);
          this.dataService.submitHoursData = null;
          this.showInvoiceAlreadyGeneratedPopup = true;
          this.invoicePopupError = error.message;
          this.isPaymentDetailsFound();
      });
  }

  onOkay(): void {
    this.showInvoiceAlreadyGeneratedPopup = false;
  }

  isPaymentDetailsFound(){
    const vendorUser = this.dataService.getUser();
      if (vendorUser && !vendorUser.payment_detail && !this.dataService.isReadOnlyUser()){
          this.router.navigate(['/vendor/billing/invoices/payment-detail']);
      }
      else {
          this.getAndRenderInvoices();
      }
  }

  onScroll(): void {
    if (this.meta && this.meta.next_page !== null && !this.isScrolled) {
        this.isScrolled = true;
        this.page++;
        this.getAndRenderInvoices();
    }
}
  getAndRenderInvoices(): void {
    this.dataService.showHideLoader(true);
    this.invoiceService.getInvoices(this.page, this.PER_PAGE)
      .subscribe(res => {
          this.dataService.showHideLoader(false);
          this.invoiceService.submitButtonData = res.meta;
          if (this.isScrolled) {
            this.isScrolled = false;
            this.invoices = this.invoices.concat(res.data);
          } else {
              this.invoices = res.data;
          }
          if (this.invoices.length > 0) {
            this.quickTips();
            this.getQuickTips('invoice');
          } else {
            this.invoiceCycle = res.meta.invoice_cycle;
          }
          if(res.data.length > 0){
            this.newUpInvoicesIds = res.data.filter(e => (e.status === 'raised') && (e.new_up === true)).map(e => e.id);
            this.markNewUpInterval();
          }
          this.meta = res.meta;
          if (this.dataService.submitHoursData) {
            this.getSubmitHoursInfo();
          }
      }, err => {
        console.log(err);
        this.dataService.showHideLoader(false);
      });
  }

  quickTips(){
    this.interval = setInterval(() => {
      this.getQuickTips('invoice');
    }, 30000);
  }

  getQuickTips(type: string){
    this.quickSubscription = this.dataService.getQuickTips(type)
    .subscribe(quickTip => {
      this.quickTip = quickTip.data;
    }, error => {
      console.log(error);
    });
  }

  updateTutorials(tutorialScreen: string): void {
    const user = this.dataService.getUser();
    if (user.tutorials.indexOf(tutorialScreen) >= 0) return;
    user.tutorials.push(tutorialScreen);
    this.tutorialService.gotIt(user.tutorials)
    .subscribe(res => {
        this.dataService.setUser(res.data);
    }, err => {
        console.log(err);
    });
  }

  markAsNewUp(){
    if(this.newUpInvoicesIds.length > 0){
      let IDS = this.newUpInvoicesIds.join(",");
        this.dataService.markAsNewUpInvoices(IDS)
        .subscribe(res => {
          for (let invoice of this.invoices) {
            for (let id of this.newUpInvoicesIds) {
              if (id === invoice.id) {
                invoice.new_up = false;
              }
            }
          }
          this.newUpInvoicesIds = [];
        }, error => {
          console.log(error);
        });
    }
  }

  markNewUpInterval(){
    setTimeout(() => {
      this.markAsNewUp();
    }, 3000);
  }

  ngOnDestroy(){
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.quickSubscription) {
      this.quickSubscription.unsubscribe();
    }
  }

}
