import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { DataService } from '../../shared/services/data.service';
import { PhaseService } from '../../shared/services/phase.service';
import { TutorialService } from '../../tutorial/tutorial.service';
import { RateCardService } from './rate-card.service';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
  selector: 'rate-card',
  templateUrl: 'rate-card.component.html',
  styleUrls: ['rate-card.component.scss']
})

export class RateCardComponent implements OnInit, OnDestroy{
  preventClick = false;
  rateCardsList = [];
  newRateCards = [];
  oldRateCards = [];
  customRateCards = [];
  search = '';
  isNewRateCardCreatedObservable: Subscription;
  showDeactivatePopup = false;
  selectedRateCard: any;
  isShowAddRatecart: boolean;
  dataService;
  phaseService;
  rateCardService;
  toUpdateRateCard = {
    rate_card: {
      title: '',
      description: '',
      bench_price: 0,
      on_demand_price: 0,
      rate_card_discounts_attributes: []
    }
  };
  noFilteredRateCards = false;
  noDevelopers = false;
  noRateCard = false;
  tutorialService;
  showRateCardTutorial = false;
  showLinkRateCardPopup = false;
  notificationData: any;
  notificationTitle = '';
  rateCardsForDevSkill = [];
  selectedRateCardForPopup: any;
  rateCardLinkPopupInfo = '';
  devAlreadyLinkedToRateCard = false;
  showRateCardAddToPopup = false;
  filteredRateCardObservable: Subscription;

  constructor(dataService: DataService, phaseService: PhaseService, private router: Router, private analyticsSegment: Angulartics2Segment,
    private cdRef: ChangeDetectorRef, tutorialService: TutorialService, rateCardService: RateCardService
  ) {
    this.dataService = dataService;
    this.phaseService = phaseService;
    this.tutorialService = tutorialService;
    this.rateCardService = rateCardService;
    this.phaseService.leftMenuActive = 'billing';
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
    };
    this.isNewRateCardCreatedObservable = this.dataService.isNewRateCardCreated.subscribe(() => {
      this.getRateCards();
  });
  }

  ngOnInit() {
    this.getRateCards();
  }

  ngOnDestroy() {
    if (this.isNewRateCardCreatedObservable) this.isNewRateCardCreatedObservable.unsubscribe();
  }

  oldRateCardView(id): void{
    this.dataService.showHideLoader(true);
    this.rateCardService.getRateCardDetails(id)
    .subscribe(res => {
      this.dataService.showHideLoader(false);
      this.rateCardService.clickedScreen = "oldRateCardView";
      this.rateCardService.currentRateCard = res.data;
      this.dataService.rateCardPopup = true;
    });
  }

  NewRateCardView(id) {
    this.router.navigate(['vendor/rate-cards/' + id]);
  }

  getRateCards(): void{
      this.newRateCards = [];
      this.oldRateCards = [];
      this.customRateCards = [];
      this.dataService.showHideLoader(true);
      this.dataService.getRateCards()
      .subscribe(res => {
        if(res.data){
          for(let rateCard of res.data){
            if(rateCard.custom){
              this.customRateCards.push(rateCard);
            } else if(!rateCard.expertise){
              this.oldRateCards.push(rateCard);
            } else if(rateCard.expertise){
              rateCard = this.setDevProfilePics(rateCard);
              this.newRateCards.push(rateCard);
            }
          }
          this.dataService.showHideLoader(false);
        }
      });
  }
  
  setDevProfilePics(rateCard){
    if(rateCard.developer_profile_pics.length > 3){
      rateCard.developer_profile_pics = rateCard.developer_profile_pics.slice(0, 3);
    } else{
        let remainPics = rateCard.developer_count - rateCard.developer_profile_pics.length;
        for(let i=0; i< remainPics; i++){
          rateCard.developer_profile_pics.push('../../../assets/images/icon-profile-default.svg');
        }
    }
    return rateCard;
  } 

  getFilteredRateCards(search?: string): void {
    if (this.filteredRateCardObservable) this.filteredRateCardObservable.unsubscribe();
    this.filteredRateCardObservable = this.dataService.getRateCards(search)
    .subscribe(res => {
      this.rateCardsList = res.data;
      if (this.rateCardsList.length === 0) {
        this.noFilteredRateCards = true;
      } else {
        this.noFilteredRateCards = false;
      }
    }, err => {
      console.log(err);
    })
  }

  stopPropagation(e, i?): void {
    if (i === this.phaseService.selectedIndex) {
      e.stopPropagation();
    }
  }

  stopPropagationFromPopup(e): void {
    e.stopPropagation();
  }

  onSearch(event: any): void {
    const key = event.keyCode || event.charCode;
    if ((key === 8 || key === 46) && this.search.length === 0) {
        this.getFilteredRateCards();
    }
    if (this.search.length < 1) return;
    this.getFilteredRateCards(this.search);
  }

  closeToaster(){
    this.rateCardService.rateCardavAilabilityToastData.showToaster = false;
  }


}
