import {Directive, ElementRef, EventEmitter, Output} from "@angular/core";
import {NgModel} from "@angular/forms";

declare let google: any;

@Directive({
  selector: "[googleplace]",
  providers: [NgModel]
})
export class GoogleplaceDirective {
  @Output() private setAddress: EventEmitter<any> = new EventEmitter();
  private modelValue: any;
  private autocomplete: any;
  private _el: HTMLElement;


  constructor(el: ElementRef, private model: NgModel) {
    this._el = el.nativeElement;
    this.modelValue = this.model;
    const input = this._el;
    this.autocomplete = new google.maps.places.Autocomplete(input, {});
    google.maps.event.addListener(this.autocomplete, "place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  private geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        this.autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  private invokeEvent(place: any) {
    this.setAddress.emit(place);
  }

}
