import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NouisliderModule } from 'ng2-nouislider';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { IntercomModule } from 'ng-intercom';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MultipleDatePickerModule } from 'multiple-date-picker-a2'
import { MyDatePickerModule } from 'mydatepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Segment } from 'angulartics2/segment';
import * as Sentry from "@sentry/browser";
import { ServiceWorkerModule } from '@angular/service-worker';

import { rootRouterConfig } from './app.route';
import { DataService } from './shared/services/data.service';
import { ResendConfirmationEmailModule } from './resend-confirmation-email/resend-confirmation-email.module';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { RegisterModule } from './register/register.module';
import { AppComponent } from './app.component';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { AddADeveloper } from './add-a-developer/add-a-developer.component';
import { ConfirmingEmailComponent } from './confirming-email/confirming-email.component';
import { NdaContractModule } from './nda-contract/nda-contract.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';

const isDevelop = ((window.location.origin.indexOf("localhost") >= 0) || (window.location.origin.indexOf("develop-partner.engineer.ai") >= 0)) ? true : false;

Sentry.init({
  dsn: "https://eac7ca3637714af2b61d14dd954758cf@sentry.io/1507160"
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if(!isDevelop){
      const eventId = Sentry.captureException(error.originalError || error);
    }
  }
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    InfiniteScrollModule,
    IntercomModule.forRoot({
      appId: window.location.origin.indexOf("partner.engineer.ai") >= 0 ? " tk8uw9aj" : "ob3az46u",
      updateOnRouterChange: true
    }),
    SlickCarouselModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(rootRouterConfig,
      { 
        preloadingStrategy: PreloadAllModules,
        useHash: false,
        onSameUrlNavigation: 'reload'
      }
    ),
    Angulartics2Module.forRoot([ Angulartics2Segment ]),
    DragToSelectModule.forRoot(),
    NgIdleModule,
    NgIdleKeepaliveModule.forRoot(),
    NouisliderModule,
    MultipleDatePickerModule,
    MyDatePickerModule,
    CoreModule,
    LoginModule,
    RegisterModule,
    ForgotPasswordModule,
    ResendConfirmationEmailModule,
    NdaContractModule,
    ResetPasswordModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true })
  ],
  declarations: [AppComponent, AddADeveloper, ConfirmingEmailComponent],
  providers: [DataService, { provide: ErrorHandler, useClass: SentryErrorHandler }, {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ResponseInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private dataService: DataService) {
    this.dataService.setIncomingUrlParameters(this.getJsonFromUrl());
  }

  private getJsonFromUrl() {
    const path = location.href;
    let query;
    if (path.indexOf('?') != -1) {
      query = path.substr(path.indexOf('?') + 1);
      const result = {};
      query.split('&').forEach((part) => {
        const item = part.split('=');
        if (item[0] != '') {
          result[item[0]] = decodeURIComponent(item[1]);
        }
      });
      return result;
    }
    return null;
  }

}
