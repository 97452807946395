import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { PhaseService } from '../shared/services/phase.service';
import { DataService } from '../shared/services/data.service';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
    @ViewChild('passwordEl', {static: true}) passwordEl: any;
    password = '';
    passwordError = '';
    inputType = 'password';
    dataService;

    constructor(
        dataService: DataService,
        private router: Router,
        private phaseService: PhaseService
    ) {
        this.dataService = dataService;
    }

    ngOnInit(): void {
        this.passwordEl.nativeElement.focus();
    }

    checkForError(type: string, e: any): void {
        if (e.which === 13) return;
        if (type === 'password') {
            if (!this.password) {
                this.passwordError = 'Please enter password';
            } else if (!this.isValidPassword()) {
                this.passwordError = 'Please enter atleast 8 characters';
            } else {
                this.passwordError = '';
            }
        }
    }

    redirectTo(page: string): void {
        this.router.navigate([page]);
    }

    resetPassword(): void {
        if (this.formHasError()) return;
        if (!this.phaseService.resetPasswordToken) {
            this.passwordError = 'Reset password token is not valid';
            return;
        }
        this.dataService.showHideLoader(true);
        this.passwordError = '';
        const postJson = {
            reset_password_token: this.phaseService.resetPasswordToken,
            password: this.password,
            password_confirmation: this.password
        };
        this.dataService.resetPassword(postJson)
        .subscribe(() => {
            this.dataService.showHideLoader(false);
            this.router.navigate(['login']);
        }, error => {
            this.dataService.showHideLoader(false);
            this.passwordError = error.error.message ? error.error.message : null;
        });
    }

    formHasError(): boolean {
        let formHasError = false;
        this.passwordError = '';
        if (!this.password) {
            this.passwordError = 'Please enter password';
            formHasError = true;
        } else if (!this.isValidPassword()) {
            this.passwordError = 'Please enter atleast 8 characters';
            formHasError = true;
        }
        return formHasError;
    }

    private isValidPassword(): boolean {
        return this.password.length >= 8;
    }

}
