import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { sha256 } from 'js-sha256';
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import "rxjs/add/operator/mergeMap";
import { Router } from "@angular/router";
import { HttpService } from "../services/http.service";
import { Angulartics2Segment } from "angulartics2/segment";

import * as PDFJS from 'pdfjs-dist/build/pdf';
import { Intercom } from "ng-intercom";
import { environment } from '../../../environments/environment';

@Injectable()
export class DataService {
  isNewRateCardCreated = new Subject<any>();
  vendorProfileData: any;
  cancelPaymentDetails: boolean = false;;
  playerId = '';
  env: any;
  isOneSignalInitialized = false;
  oneSignalNotification = false;
  showSuccessfulRegistrationMessage = false;
  successfulRegistrationMessage = '';
  showAccentureSupportEmail = false;
  rejectedDevs = [];
  userSkills = [];
  showInviteMembers = false;
  isFormFlow = false;
  vendor_id = null;
  urlParameters;
  openingLiveChat;
  existingLead = { id: null };
  docuSignUrl;
  docuSignUrlNew;
  docuSignSuccess = false;
  ndaSigned;
  classInviteMembers = false;
  signature_request_guid;
  signature_request_status;
  emailConfirmationToken;
  username;
  settings;
  migrationAlSetPopup: boolean = false;
  migrationAlSetPopupData;
  setupRatesCardsCount = 0;
  rateCardPopup: boolean = false;
  firstMigrationPopup: boolean = false;
  isNewRateCardsReady: boolean = false;
  showContractCopy = false;
  isShowRateCardPopUp = false;
  afterLoginRedirectTo = '';
  sharedSecretToken = '1e3be7c5e5ee1f1ad8af0343d433a44f8qwyeuiqwjasd7as677633f9724d86251d0b2fcaksdkjasgdjkjagsdkasgjd70d4571955f45e985b02d091f3a55b642c0a7d8f45552';
  developerCount = {
    "all": 0,
    "approved_count": 0,
    "pending_count": 0,
    "rejected_count": 0
  };
  allocationId = '';
  anonymousId = '';
  notificationCount = 0;
  isMaintenanceOn = '';
  isProduction = false;
  isStaging = false;
  isDev = false;
  isLiquidStudio = false;
  isLiquidStudioStaging = false;
  stopTutorialForAWhile = false;
  showNdaLoader = false;
  previousCurrency;
  unsavedChanges = false;
  privacyPage = false;
  tncPage = false;
  enableEventTracking = false;// false for staging and true for beta
  showPageLoader = false;
  showLoader = false;
  showFullPageLoader = false;
  developerProfile;
  projectsList = [];
  accessToken = null;
  buildCardData;
  firstPage = "login";
  areServicesRunning =  true;
  sharedServiceType = '';
  resetData = { email: "", token: "" };
  removeDropDown = new EventEmitter<any>();
  apiEndPoints = {
    vendor_signup: "vendor/profile",
    vendor_signin: "vendor/login",
    vendor_developers: "vendor/developers",
    vendor_developer_profile: "vendor/developers/{id}",
    vendor_developer_setRateCard: 'vendor/developers/set_rate_card',
    vendor_projectList: "vendor/projects",
    vendor_projectData: "vendor/projects/{id}",
    vendor_signout: "vendor/logout",
    vendor_mydevelopers: "vendor/mydevelopers",
    vendor_raise_request: "vendor/requests",
    skills: "skills",
    checkServices: 'services',
    rate_cards_migrate: 'vendor/rate_cards/migrate',
    getRateCards: "vendor/rate_cards",
    cancelIncreasedRates: "vendor/rate_cards/cancel_increased_rates",
    updateRateCard: "vendor/rate_cards/{id}",
    removeDeveloperFromRateCard: 'vendor/rate_cards/unlink',
    removeDevelopers: "vendor/rate_cards/{id}/remove_developers",
    deactivateGroup: "vendor/rate_cards/{id}/deactivate",
    addDevelopersToGroup: "vendor/rate_cards/{id}/add_developers",
    notices: "vendor/notices",
    noticeIssue: "notice/{notice_id}/contact",
    vacanctDevelopers: "vendor/developers/vacant",
    devFilters: "vendor/developers/filters ",
    productsList: "product_list",
    projectManagers: "vendor/developers/project_managers",
    emailValidator: "validators/email?email=[email]",
    user_signup: "users",
    vendorprofile: "vendor/profile",
    maintenance: 'maintenance',
    user_forgot: "users/forgot_password",
    user_reset: "users/reset_password",
    add_new_feature: "features",
    add_new_category: "categories",
    features: "features",
    build_card: "build_cards",
    docusign_url: "vendor/hellosign_url",
    change_password: "vendor/change_password",
    forgot_password: "vendor/forgot_password",
    reset_password: "vendor/reset_password",
    vendor_forgot: "",
    vendor_invoice: 'vendor/invoices',
    mark_leaves: "vendor/set_availability",
    add_a_developer: 'vendor/developers/add_dev',
    tracker_activities: 'vendor/task_trackers',
    team_planner: 'vendor/availabilities',
    notification_count: 'vendor/notifications/notification_count',
    notofication_get_all: 'vendor/notifications',
    notification_mark_read: 'vendor/notifications/read',
    read_new_up: 'vendor/invoices/read_new_up',
    currencies: 'currencies',
    vendor_allocations: 'vendor/allocations',
    vendor_allocations_allowed: 'vendor/allocations/allowed',
    vendor_rate_card_preferences: 'vendor/rate_card_preferences',
    hot_skills: 'hot_skills',
    vendor_rating: 'vendor/ratings',
    demand_skills: 'demand_skills',
    blocked_domains: 'config_keys/public_domains',
    read_only_user: 'vendor/member'
  };
  CONSTANTS = {
    BUILD_CARD_ID: "?bc_id=",
    DECIMAL: ".",
    PROMOTION: "?promotion=",
    KEYWORD: "?keyword=",
    PRODUCT_ID: "?product_id=",
    CURRENCY_ID: "&currency_id=",
    USER: "user",
    QUESTION_MARK: "?",
    BRACKETS: "[]",
    SLASH: "/",
    DATA: "data?",
    AND: "&",
    CLOSE_BRACKET: "]",
    SHARE: "/share",
    INVITE: "/invite",
    EMAIL_BRACKET: "emails[]",
    AUTHTOKEN: "?authtoken=",
    PER_PAGE: "per_page=",
    PAGE: "page=",
    VENDOR_BRACKET: "vendor",
    FILE_PARAM: "[file]",
    OPEN_BRACKET: "[",
    DOCUMENT_PARAM: "[document_scope]",
    DEVELOPER_BRACKET: "developer",
    REQUEST: "request",
    STATUS: "status="
  };
  submitHoursData;
  show_upskill_rate_cards:boolean;
  createRateCardThroughAutoAddPopup:boolean = false;
  createRateCardThroughAutoAddPopupHaveDone:boolean = false;
  goToRateCardFromAutoAddPopup:boolean = false;
  closeRateCardPopupAfterComingFromAutoAdd:boolean = false;
  allocationRequestData;
  goToAutoMatchPreferencesFromNotifications:boolean = false;

  constructor(
    public http: HttpClient,
    private router: Router,
    private analyticsSegment: Angulartics2Segment,
    private httpService: HttpService,
    private intercom: Intercom
  ) {
    this.env = environment;
    this.urlParameters = {};
    this.openingLiveChat = false;
    this.showAccentureSupportEmail = window.location.origin.indexOf('accenture') >= 0 ? true : false;
  }

  showHideLoader(showHide: boolean) {
    setTimeout(() => {
      this.showLoader = showHide;
    });
  }

  getCountryList() {
    return this.httpService.getRequest('/assets/country-list.json');
  }

  getCountryCodes() {
    return this.httpService.getRequest('/assets/country-codes.json');
  }

  getNonceString(): string {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 65; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  getHashedString(nonceString: string, currentTimestamp: string): string {
    let hashedString = `${this.sharedSecretToken}-${nonceString}-${currentTimestamp}`;
    return sha256.create().update(hashedString).hex();
  }

  getSecurityParams(): {nonceString: string, currentTimestamp: string, hashedString: string} {
    let nonceString = this.getNonceString();
    let currentTimestamp = Math.floor(new Date().getTime()/1000) + '';
    let hashedString = this.getHashedString(nonceString, currentTimestamp);
    return {nonceString, currentTimestamp, hashedString};
  }

  getHeaders(contentType?: string): any {
    return contentType ? new HttpHeaders({'Content-Type': contentType}) : new HttpHeaders();
  }

  intercomShowHide(value): void {
    let intercomJson = {
      app_id: window.location.origin.indexOf("partner.engineer.ai") >= 0 ? "tk8uw9aj" : "ob3az46u",
      widget: {
        "activator": "#intercom"
      }
    } as any;
    if (this.getUser()) {
      intercomJson.user_id = this.getUser().id;
    }
    if(value === 'activate'){
      this.intercom.boot(intercomJson);
    }else if(value === 'deactivate'){
      this.intercom.shutdown();
    }
  }

  public setIncomingUrlParameters(urlParameters) {
    if (urlParameters) {
      if (urlParameters.email) {
        this.resetData.email = urlParameters.email;
        this.resetData.token = urlParameters.token;
      } else {
        this.urlParameters = urlParameters;
        this.ndaSigned = this.urlParameters.event;
        this.signature_request_guid = this.urlParameters.signature_request_guid;
        this.signature_request_status = this.urlParameters.signature_request_status;
        this.username = this.urlParameters.user;
        this.vendor_id = this.urlParameters.vendor_id;
        this.accessToken = this.urlParameters.access_token;
        this.emailConfirmationToken = this.urlParameters.token;
      }

      if (this.vendor_id || this.accessToken) {
        this.isFormFlow = true;
        this.docuSignSuccess = true;
      }
    }
  }

  isReadOnlyUser(): boolean {
    return this.getUser().role === 'reader';
  }

  confirmEmail(token: string): Observable<any> {
    let headers = this.getHeaders('application/x-www-form-urlencoded');
    const API = `${this.getServerUrl()}vendor/profile/confirmation?confirmation_token=${token}`;
    return this.httpService.getRequest(API, headers);
  }

  resendConfirmationEmail(email: string): Observable<any> {
    const API = `${this.getServerUrl()}vendor/profile/send_confirmation`;
    let postJson = {
      email: email
    }
    return this.httpService.postRequest(API, postJson);
  }

  underscore(string) {
    return string.replace(/([A-Z])/g, ($1) => {
      return "_" + $1.toLowerCase();
    });
  }

  login(email: string, password: string) {
    let API = this.getServerUrl() + this.apiEndPoints.vendor_signin;
    const body = {
      vendor: {email, password}
    }
    return this.httpService.postRequest(API, body);
  }

  getRateCards(search?: string) {
    let API = this.getServerUrl() + this.apiEndPoints.getRateCards;
    if (search) {
      API += `?search=${search}`;
    }
    return this.httpService.getRequest(API);
  }

  getRateCardsMigrate(skillExpertiseData = []) {
    let query = this.generateQuery(skillExpertiseData);
    const API = this.getServerUrl() + this.apiEndPoints.rate_cards_migrate + query;
    return this.httpService.getRequest(API);
  }

  generateQuery(skillExpertiseData){
    let queryString = '';
    let strArray = [];
    if(skillExpertiseData.length > 0){
     for(let data of skillExpertiseData){
      strArray.push(`skill_expertise[][skill_id]=${data.skill.id}`);
      strArray.push(`skill_expertise[][expertise]=${data.expertise}`);
     }
     queryString = `?${strArray.join('&')}`;
    }
    return queryString;
  }

  getUserProfile() {
    const API = this.getServerUrl() + this.apiEndPoints.vendorprofile;
    return this.httpService.getRequest(API);
  }

  patchUserProfile(jsonToBeUpdated): Observable<any> {
    const API = this.getServerUrl() + this.apiEndPoints.vendorprofile;
    let data = {
      vendor: jsonToBeUpdated
    };
    return this.httpService.putRequest(API, data);
  }

  getMaintenanceReport() {
    const API = this.getServerUrl() + this.apiEndPoints.maintenance;
    return this.httpService.getRequest(API);
  }

  updateUserWithHelloSign(data): void {
    const API = this.getServerUrl() + this.apiEndPoints.vendorprofile;
    this.httpService.putRequest(API, data)
      .subscribe((res: any) => {
        if (this.getUser()) {
          this.getUser().nda_signed = true;
        }
        if (this.getUser()) {
          this.getUser().nda_signed = true;
        }
        this.setUser(res.data);
        this.analyticsSegment.eventTrack('Signed up', {
          email: this.getUser() ? this.getUser().email : '',
          name: this.getUser() ? this.getUser().name : '',
          address: this.getUser() ? this.getUser().address.address : '',
          userId: this.getUser() ? this.getUser().id : '',
          phone: this.getUser() ? this.getUser().phone : '',
          logo: this.getUser() && this.getUser().logo ? this.getUser().logo.file_url : ''
        });
        this.analyticsSegment.setUserProperties({
          email: this.getUser() ? this.getUser().email : '',
          name: this.getUser() ? this.getUser().name : '',
          address: this.getUser() ? this.getUser().address.address : '',
          userId: this.getUser() ? this.getUser().id : '',
          phone: this.getUser() ? this.getUser().phone : '',
          logo: this.getUser() && this.getUser().logo ? this.getUser().logo.file_url : ''
        });
        this.showNdaLoader = false;
        this.router.navigate(['vendor/dashboard']);
      });
  }

  signout_user() {
    const API = `${this.getServerUrl()}${this.apiEndPoints.vendor_signout}${this.CONSTANTS.AUTHTOKEN}${this.getUser().authtoken}&onesignal_player_id=${this.playerId}`;
    return this.httpService.deleteRequest(API);
  }

  initializeOneSignalNotification(): void {
    const OneSignal = window['OneSignal'] || [];
    this.isOneSignalInitialized = true;
    this.setOneSignalAppId(OneSignal);

    OneSignal.push(() => {
      OneSignal.getUserId()
      .then(playerId => {
        this.playerId = playerId;
        this.setPlayerId(playerId)
        .subscribe()
      });
    });
  }

  setOneSignalAppId(OneSignal): void {
    const configJson = {
      autoRegister: true
    } as any;
    if (this.isLiquidStudio) {
      if (window.location.origin.indexOf('staging') >= 0) {
        configJson.appId = '884235bd-c8c9-4e3a-a37f-9a74cc59ed02';
      } else {
        configJson.appId = '69eb4dcd-3b87-45ac-ac5f-3717b5ae040e';
      }
    } else if (this.isProduction) {
      configJson.appId = 'd32ed716-a8d2-4bc2-a512-a497a3301b55';
    } else if (this.isStaging) {
      configJson.appId = '4ffc6d01-e6ae-4d2e-b213-2e72a14c0d91';
    } else {
      configJson.appId = '49acb7dc-7d96-43c7-ba5a-0bfa160bb933';
      configJson.allowLocalhostAsSecureOrigin = true;
    }
    OneSignal.push(() => {
      OneSignal.init(configJson);
    });
  }

  setPlayerId(playerId: string): Observable<any> {
    const API = `${this.getServerUrl()}${this.apiEndPoints.notofication_get_all}/player_id`;
    const data = {
      vendor: {
        onesignal_player_id: playerId
      }
    };
    return this.httpService.putRequest(API, data);
  }

  getStatisticsForMixpanel(): Observable<any> {
    const API = `${this.getServerUrl()}vendor/profile/statistics?authtoken=${this.getAuthToken()}`;
    return this.httpService.getRequest(API);
  }

  public getServerUrl(isPmDashboard?: boolean) {
    if (isPmDashboard) {
        return this.env.API_BASE_URL_FOR_PM;
    } else {
        return this.env.API_BASE_URL;
    }
  }

  getUser(): any {
    return localStorage.getItem('vendor_user') ? JSON.parse(localStorage.getItem('vendor_user')) : '';
  }

  setUser(user: any): void {
    localStorage.setItem('vendor_user', JSON.stringify(user));
  }

  getAuthToken(): string {
    return localStorage.getItem('vendor_a');
  }

  setAuthToken(authToken: string): void {
    localStorage.setItem('vendor_a', authToken);
  }

  getTeamStructures() {
    const API = `${this.getServerUrl()}vendor/team_structures`;
    return this.httpService.getRequest(API);
  }

  forgotPassword(data) {
    const API = this.getServerUrl() + this.apiEndPoints.forgot_password;
    let headers = this.getHeaders('application/json');
    const email = { vendor: {email: data} }
    return this.httpService.postRequest(API, email, headers);
  }

  resetPassword(data) {
    const API = this.getServerUrl() + this.apiEndPoints.reset_password;
    let headers = this.getHeaders('application/json');
    return this.httpService.postRequest(API, data, headers);
  }

  resendDevInvitation(developersIds: string[]): Observable<any> {
    const url = `${this.getServerUrl()}vendor/developers/${developersIds}/resend_invitation`;
    return this.httpService.postRequest(url, { });
  }

  deleteDevelopers(developersIds: string[]): Observable<any> {
    const url = `${this.getServerUrl()}vendor/destroy`;
    return this.httpService.postRequest(url, {
      developer_ids: developersIds
    });
  }

  getPendingDevelopers(perPage: number, currentPage: number): Observable<any> {
        let url = `${this.getServerUrl()}${this.apiEndPoints.vendor_developers}?status=pending&per_page=${perPage}&page=${currentPage}`;
        return this.httpService.getRequest(url);
  }

  inviteMembers(devs: any[]): Observable<any> {
      let url = `${this.getServerUrl()}${this.apiEndPoints.vendor_developers}/multiple_registration`;
      const postJson = {
          developers: devs
      }
      return this.httpService.postRequest(url, postJson);
  }

  addDeveloper(data, uniq_code: string): Observable<any> {
    const url = this.getServerUrl() + this.apiEndPoints.add_a_developer;
    let headers = this.getHeaders();
    headers = headers.append('uniq-code', uniq_code);
    return this.httpService.postRequest(url, data, headers);
  }

  getNotificationCount() {
    const url = this.getServerUrl() + this.apiEndPoints.notification_count;
    return this.httpService.getRequest(url);
  }

  getAllNotifications(page) {
      const url = this.getServerUrl() + this.apiEndPoints.notofication_get_all + '?page=' + page;
      return this.httpService.getRequest(url);
  }

  markReadNotification(dataForRead) {
      let data;
      if (dataForRead === true) {
          data = {mark_all_read: dataForRead};
      } else {
          data = {id: dataForRead};
      }
      const url = this.getServerUrl() + this.apiEndPoints.notification_mark_read;
      return this.httpService.postRequest(url, data);
  }

  updatePaymentDetails(vendor, type=""): Observable<any>{
    const API = this.getServerUrl() + this.apiEndPoints.vendorprofile;
    const body = new FormData();
    if(type === ''){
        body.append('vendor[name]', vendor.name);
        body.append('vendor[company_name]', vendor.company_name);
        body.append('vendor[phone]', vendor.phone);
        body.append('vendor[company_reg_number]', vendor.company_reg_number);

        if (vendor.logo.file) {
            if(vendor.logo.id){
              body.append('vendor[logo_attributes]id', vendor.logo.id);
            }
            body.append('vendor[logo_attributes][file]', vendor.logo.file);
        }
        if (vendor.address.id) {
            body.append('vendor[address_attributes]id', vendor.address.id);
        }
        body.append('vendor[address_attributes]address', vendor.address.address);
        body.append('vendor[address_attributes]city', vendor.address.city);
        body.append('vendor[address_attributes]state', vendor.address.state);
        body.append('vendor[address_attributes]country', vendor.address.country);
        body.append('vendor[address_attributes]pincode', vendor.address.pincode);
  }
  if(type === '' || type === 'payment'){
        if (vendor.payment_detail.id) {
          body.append('vendor[payment_detail_attributes]id', vendor.payment_detail.id);
        }
        body.append('vendor[payment_detail_attributes]name_of_account', vendor.payment_detail.name_of_account);
        body.append('vendor[payment_detail_attributes]account_number', vendor.payment_detail.account_number);
        body.append('vendor[payment_detail_attributes]ifsc_code', vendor.payment_detail.ifsc_code);
        body.append('vendor[payment_detail_attributes]name_of_bank_branch', vendor.payment_detail.name_of_bank_branch);
        body.append('vendor[payment_detail_attributes]gst_number', vendor.payment_detail.gst_number);
        body.append('vendor[payment_detail_attributes]pan_number', vendor.payment_detail.pan_number);
        body.append('vendor[payment_detail_attributes]swift_code', vendor.payment_detail.swift_code);
        body.append('vendor[payment_detail_attributes]currency_id', vendor.payment_detail.currency.id);
  }
    return this.httpService.putRequest(API, body);
  }

  getQuickTips(type: string): Observable<any>{
    const API = `${this.getServerUrl()}quick_tips?section=${type}`;
    return this.httpService.getRequest(API);
  }

  markAsNewUpInvoices(ids: string){
    const API = `${this.getServerUrl()}/${this.apiEndPoints.read_new_up}`;
    const data = {invoice_ids: ids};
    return this.httpService.postRequest(API, data);
  }

  getCurrencies(): Observable<any>{
    const API = `${this.getServerUrl()}/${this.apiEndPoints.currencies}`;
    return this.httpService.getRequest(API);
  }

  getRateCardsForDeveloperSkill(devSkillId: string): Observable<any> {
    let url = `${this.getServerUrl()}${this.apiEndPoints.getRateCards}?developer_skill_id=${devSkillId}`;
    return this.httpService.getRequest(url);
  }
  
  getBlockedDomains(email?: string): Observable<any> {
    let url = `${this.getServerUrl()}${this.apiEndPoints.blocked_domains}`;
    if (email) url += `?email=${email}`;
    return this.httpService.getRequest(url);
  }

  rateCardLinkThroughSuggestiveRateCard(rateCardId: string, developerSkillId: string, createPreferenceValue: boolean){
    const API = `${this.getServerUrl()}${this.apiEndPoints.getRateCards}/${rateCardId}/link`;
    const data = {
      developer_skill_id: developerSkillId,
      rate: 'spot',
      create_preference: createPreferenceValue ? 'true' : 'false'
    }
    return this.httpService.postRequest(API, data);
  }

  getContractPdf(){
    const API = `${this.getServerUrl()}config_keys/contract_agreement`;
    return this.httpService.getRequest(API);
  }

  renderPDF(url, canvasContainer) {
    let options = { scale: 2 };

    //PDFJS.disableWorker = true;
    const pdfjsWorker = "../../assets/pdf.worker.min.js";
    PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    PDFJS.getDocument(url, canvasContainer, options).promise.then(res => {
      this.renderPages(res, canvasContainer, options);
    });

}

renderPage(page, canvasContainer, options) {
  var viewport = page.getViewport({scale: options.scale});
  var wrapper = document.createElement("div");
  wrapper.className = "canvas-wrapper";
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  var renderContext = {
    canvasContext: ctx,
    viewport: viewport
  };

  canvas.height = viewport.height;
  canvas.width = 1360;
  wrapper.appendChild(canvas)
  canvasContainer.appendChild(wrapper);

  page.render(renderContext);
}

renderPages(pdfDoc, canvasContainer, options) {
  var promises = [];
  for(var num = 1; num <= pdfDoc.numPages; num++){
      promises.push(pdfDoc.getPage(num));
  }
  Promise.all(promises).then(res => {
      for(var r of res){
          this.renderPage(r, canvasContainer, options);
      }
  });
  this.showHideLoader(false);
}

mixpanel_events_track(type='event', eventName='', jsonData={}){
  if(type === 'event'){
      this.analyticsSegment.eventTrack(`${eventName}`, jsonData);
  } else if(type === 'people' || type === 'super_property'){
      this.analyticsSegment.setUserProperties(jsonData);
  }
}

showRCMigrateStrip(): void{
  this.isNewRateCardsReady = false;
  this.getRateCardsMigrate()
    .subscribe(res => {
      if(res.data && res.data.length > 0){
        this.isNewRateCardsReady = true;
      }
    });
}

splitPhoneNumber(phoneNo){
  const phoneNumberWithCountryCode = phoneNo.split('-');
  const countryCodeSplit = phoneNumberWithCountryCode[0].split('+');
  const countryCode = countryCodeSplit[1];
  const phoneNumber = phoneNumberWithCountryCode[1];
  return {
    'countryCode': countryCode,
    'phoneNumber': phoneNumber
  }
}

mixpanel_invite_members(sent_via: string){
  const vendorUser = this.getUser();
  if(vendorUser && vendorUser.skills){
      this.userSkills = vendorUser.skills.map(devSkill => {
          return devSkill.title;
      });
  }
  this.analyticsSegment.eventTrack('Invite members', {'sent via': sent_via, 'Skills available': this.userSkills});
  this.mixpanel_events_track('super_property', '', {'Skills available': this.userSkills});
}

}
