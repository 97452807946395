
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { DataService } from '../../shared/services/data.service';
import { HttpService } from '../../shared/services/http.service';

@Injectable()
export class ScheduleService {

    constructor(private dataService: DataService, private httpService: HttpService) { }

    getSchedule(queryParams: any, perPage: number): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_developers}?schedule=true&year=${queryParams.selectedYear}&month=${queryParams.selectedMonth}&type=month&per_page=${perPage}&page=${queryParams.page}`;
        if (queryParams.search) {
            url += `&search=${queryParams.search}`;
        }
        if (queryParams.sortBy) {
            url += `&sort_by=${queryParams.sortBy}`;
        }
        return this.httpService.getRequest(url);
    }

    postTimeOff(popupData: any, timeOffType: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.team_planner}`;
        let availabilityJson = {
            developer_id: popupData.devId,
            start_date: popupData.from,
            end_date: popupData.to,
            status: timeOffType,
            comment: popupData.comment
        };
        return this.httpService.postRequest(url, {availability: availabilityJson});
    }

    putTimeOff(popupData: any, timeOffType: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.team_planner}/${popupData.timeoffId}`;
        let availabilityJson = {
            developer_id: popupData.devId,
            start_date: popupData.from,
            end_date: popupData.to,
            status: timeOffType,
            comment: popupData.comment
        };
        return this.httpService.putRequest(url, {availability: availabilityJson});
    }

    deleteTimeOff(timeoffId: string): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.team_planner}/${timeoffId}`;
        return this.httpService.deleteRequest(url);
    }

    getDays(year: number, month: number): any[] {
        let numberOfDays = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
        let days = [];
        for (let i=1;i<=numberOfDays;i++) {
            days.push({
                dayNumber: moment(`${year}-${month}-${i}`, 'YYYY-MM-DD').format('D'),
                dayInitial:  moment(`${year}-${month}-${i}`, 'YYYY-MM-DD').format('dd'),
                date: `${year}-${month}-${i}`
            });
        }
        return days;
    }

    createUnavailability(from: string, to: string, devId: string, selectedYear: any, selectedMonth: any): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.team_planner}`;
        const postJson = {
            availability: {
                developer_id: devId,
                start_date: from,
                end_date: to,
                status: 'unavailable',
                from_allocation: 'true'
            },
            year: selectedYear,
            month: selectedMonth,
            type: 'month'
        };
        return this.httpService.postRequest(url, postJson);
    }

    getMonths(year: number): {label: string, value: number, year: number}[] {
        return [{
            label: 'Jan',
            value: 1,
            year: year
        }, {
            label: 'Feb',
            value: 2,
            year: year
        }, {
            label: 'Mar',
            value: 3,
            year: year
        }, {
            label: 'Apr',
            value: 4,
            year: year
        }, {
            label: 'May',
            value: 5,
            year: year
        },{
            label: 'Jun',
            value: 6,
            year: year
        }, {
            label: 'July',
            value: 7,
            year: year
        }, {
            label: 'Aug',
            value: 8,
            year: year
        }, {
            label: 'Sept',
            value: 9,
            year: year
        }, {
            label: 'Oct',
            value: 10,
            year: year
        }, {
            label: 'Nov',
            value: 11,
            year: year
        }, {
            label: 'Dec',
            value: 12,
            year: year
        }];
    }

}
