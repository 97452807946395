import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from "../../../shared/services/data.service";
import { RateCardService } from "./../../rate-card/rate-card.service";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  migratedData;
  rateCardService;
  router;
  headingText;
  constructor(public dataService: DataService, rateCardService: RateCardService,  router: Router) {
    this.router = router;
    this.rateCardService = rateCardService;
   }

  ngOnInit() {
    this.migratedData = this.dataService.migrationAlSetPopupData;
    this.headingText = (this.dataService.setupRatesCardsCount > 0) ? this.dataService.setupRatesCardsCount+" Rate card(s) set!" : "You’re all set!";
  }

  ngOnDestroy() {
    this.dataService.setupRatesCardsCount = 0;
  }

  closeAllSetPopup(): void{
    this.dataService.migrationAlSetPopup = false;
    if(this.dataService.setupRatesCardsCount === 0){
      this.dataService.mixpanel_events_track('event', 'Finish migration');
    }
    this.dataService.isNewRateCardCreated.next();
  }

}
