import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InvoiceService } from '../../../shared/services/invoice.service';
import { DataService } from '../../../shared/services/data.service';
import { PhaseService } from '../../../shared/services/phase.service';
import { TutorialService } from '../../../tutorial/tutorial.service';

@Component({
    selector: 'app-invoice-details',
    templateUrl: './invoice-details.component.html',
    styleUrls: ['./invoice-details.component.scss']
})

export class InvoiceDetailsComponent implements OnInit {
    invoice: any;
    selectedListItem: any;
    showConcernPopup = false;
    concerns = [];
    otherReason = '';
    concernSuccess = false;
    invoiceId = '';
    invoiceNumber = '';
    subTotalApproved = false;
    showLoader = false;
    dataService: any;
    showInvoiceDetailTutorial = false;
    approveLeftPosition:number;
    approveTopPosition:number;
    hasVerticalScrollbar:boolean = false;
    showToaster = false;
    toasterData:any = {};

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        dataService: DataService,
        private phaseService: PhaseService,
        private invoiceService: InvoiceService,
        private tutorialService: TutorialService
    ) {
        this.dataService = dataService;
        this.phaseService.leftMenuActive = 'billing';

    }

    ngOnInit(): void {
        this.dataService.afterLoginRedirectTo = '';
        this.getConcerns();
        this.route.params.subscribe(params => {
            this.invoiceId = params.invoiceId;
            this.getAndRenderInvoice(params.invoiceId);
        });
    }

    ngAfterContentChecked(){}

    getConcerns(): void {
        this.invoiceService.getConcerns()
        .subscribe(res => {
            this.concerns = res.data;
        }, err => {
            console.log(err);
        });
    }

    getAndRenderInvoice(invoiceId: string): void {
        this.dataService.showHideLoader(true);
        this.invoiceService.getInvoiceDetail(invoiceId)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            this.invoice = this.getFormattedInvoice(res.data);
            this.showInvoiceDetailTutorial = true;
            setTimeout(() => {
                var scrolableBodyId = document.getElementById('table-body');
                this.hasVerticalScrollbar = scrolableBodyId.scrollHeight > scrolableBodyId.clientHeight;
                if(this.hasVerticalScrollbar){
                    scrolableBodyId.scrollTo(0,scrolableBodyId.scrollHeight);
                }
            }, 300);

        }, err => {
            this.dataService.showHideLoader(false);
            this.router.navigate(['vendor/billing/invoices/invoice-listing']);
        });
    }

    getFormattedInvoice(data: any): any {
        data.fromShown = new Date(data.from);
        data.toShown = new Date(data.to);
        return data;
    }

    onOptionClick(option){
        this.selectedListItem = option;
    }

    onRaiseConcern(): void {
        this.showConcernPopup = true;
    }

    onCancelConcern(): void {
        this.selectedListItem = undefined;
        this.otherReason = '';
        this.showConcernPopup = false;
    }

    submitConcern(): void {
        if (!this.selectedListItem) return;
        let concernRaised = '';
        if (this.selectedListItem === 'other' && !this.otherReason) return;
        if (this.selectedListItem === 'other' && this.otherReason) {
            concernRaised = this.otherReason;
        } else {
            concernRaised = this.concerns.find((data, index) => index === (this.selectedListItem-1));
        }
        this.invoiceService.submitConcern(this.invoiceId, concernRaised)
        .subscribe(res => {
            const invoiceDetails = this.invoice.invoice_details;
            this.invoice = this.getFormattedInvoice(res.data);
            this.invoice.invoice_details = invoiceDetails;
            this.concernSuccess = true;
        }, err => {
            console.log(err);
        });
    }

    approveInvoice(): void {
        if (!this.invoiceNumber) return;
        if (!this.subTotalApproved) return;
        this.showLoader = true;
        this.invoiceService.approveInvoice(this.invoiceId, this.invoiceNumber)
        .subscribe(res => {
            const invoiceDetails = this.invoice.invoice_details;
            this.invoice = this.getFormattedInvoice(res.data);
            this.invoice.invoice_details = invoiceDetails;
            this.showLoader = false;
            this.showToaster = true;
            this.toasterData.text = 'Invoice sent';
            this.toasterData.status = 'success';
            // setTimeout(function(){
            //     this.showToaster = false;
            // },2000);
        }, err => {
            console.log(err);
            this.showLoader = false;
        });
    }

    downloadPdf(invoice: any): void {
        let anchor = document.createElement('a');
        anchor.setAttribute('href', invoice.pdf_url);
        anchor.setAttribute('target', '_blank');
        anchor.setAttribute('download', invoice.name);
        anchor.click();
    }

    updateTutorials(tutorialScreen: string): void {
        const user = this.dataService.getUser();
        if (user.tutorials.indexOf(tutorialScreen) >= 0) return;
        user.tutorials.push(tutorialScreen);
        this.tutorialService.gotIt(user.tutorials)
        .subscribe(res => {
            this.dataService.setUser(res.data);
        }, err => {
            console.log(err);
        });
    }

    onAlrightClicked(): void {
        this.concernSuccess = false;
        this.selectedListItem = undefined;
        this.otherReason = '';
        this.showConcernPopup = false;
    }

    closeToaster(){
        this.showToaster = false;
    }

}
