import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../shared/services/data.service';

@Component({
    selector: 'forgot-password',
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['forgot.component.scss']
})

export class ForgotPasswordComponent implements AfterViewInit {
    emailError = '';
    email = '';
    dataService: any;
    linkSent = false;
    @ViewChild('emailEl', {static: false}) emailEl: any;
    private readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    constructor(
        private router: Router,
        dataService: DataService
    ) {
        this.dataService = dataService;
        if (this.dataService.getUser()) this.router.navigate(['dashboard']);
    }

    ngAfterViewInit(): void {
        this.emailEl.nativeElement.focus();
    }

    checkForError(type: string, e: any): void {
        if (e.which === 13) return;
        if (type === 'email') {
            if (!this.email) {
                this.emailError = 'Please enter email address';
            } else if (!this.EMAIL_REGEX.test(this.email.toLowerCase())) {
                this.emailError = 'Please enter valid email address';
            } else {
                this.emailError = '';
            }
        }
    }

    isEmailValid(email: string): boolean {
        return this.EMAIL_REGEX.test(email.toLowerCase());
    }

    formHasError(): boolean {
        let formHasError = false;
        this.emailError = '';
        if (!this.email) {
            this.emailError = 'Please enter email address';
            formHasError = true;
        } else if (!this.EMAIL_REGEX.test(this.email.toLowerCase())) {
            this.emailError = 'Please enter valid email address';
            formHasError = true;
        }
        return formHasError;
    }

    onSubmit(): void {
        if (this.formHasError()) return;
        this.dataService.showHideLoader(true);
        this.dataService.forgotPassword(this.email)
            .subscribe(() => {
                this.dataService.showHideLoader(false);
                this.linkSent = true;
            },
            error => {
                this.dataService.showHideLoader(false);
                this.linkSent = false;
                this.emailError = error.error.message ? error.error.message : null;
            });
    }

    redirectTo(page: string): void {
        this.router.navigate([page]);
    }

}
