import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DataService } from '../../../shared/services/data.service';
import { RegisterService } from '../../register.service';

@Component({
    selector: 'spoc-details',
    templateUrl: './spoc-details.component.html',
    styleUrls: ['./spoc-details.component.scss']
})

export class SpocDetailsComponent implements OnInit {
    @ViewChild('spocNameEl', {static: true}) spocNameEl: any;
    name = '';
    email = '';
    phone = '';
    nameErr = '';
    emailErr = '';
    phoneErr = '';
    user;
    spocId = '';
    countryCode = '1';
    countryCodes = [];
    dataService;
    sameAs = false;
    signatorySpoc;
    spocType;
    spocCat;
    blockedDomains = [];

    constructor(
        dataService: DataService,
        private router: Router,
        private registerService: RegisterService,
        private route: ActivatedRoute
    ) {
        this.dataService = dataService;
        this.spocType = this.route.snapshot.data.spocType;
    }

    ngOnInit() {
        this.spocCat = (this.spocType === 'signatory') ? 'Spoc1' : 'Spoc2';
        this.dataService.mixpanel_events_track('event', `${this.spocCat} view`);
        this.getBlockedDomains();
    }

    getBlockedDomains(): void {
        this.dataService.showHideLoader(true);
        this.dataService.getBlockedDomains().subscribe(res => {
            this.blockedDomains = res.data;
            this.spocNameEl.nativeElement.focus();
            this.setDataForms();
            this.signatorySpoc = this.dataService.getUser().spocs.find(x => x.spoc_type === 'signatory');
        }, err => {}, () => this.dataService.showHideLoader(false));
    }

    setDataForms() {
        this.getUserProfile();
      }

    getUserProfile(): void {
        this.user = this.dataService.getUser()
        if (this.user.spocs && this.user.spocs.length > 0) {
            let editMode = false;
            for(let spoc of this.user.spocs) {
                if (spoc.spoc_type === this.spocType) {
                    editMode = true;
                }
            }
            if (editMode) {
                this.preFillData();
            }
        }
    }

    preFillData(): void {
        for(let spoc of this.user.spocs) {
            if (spoc.spoc_type === this.spocType) {
                this.name = spoc.name;
                this.email = spoc.email;
                const phoneInfo = this.dataService.splitPhoneNumber(spoc.phone);
                this.phone = phoneInfo.phoneNumber;
                this.countryCode = phoneInfo.countryCode;
                this.spocId = spoc.id;
            }
        }
    }

    goBack(): void {
        const vendorUser = this.dataService.getUser();
        vendorUser.present_state = (this.spocType === 'signatory') ? 'organisation' : 'signatory_spoc';
        this.dataService.setUser(vendorUser);
        const navigateBack = (this.spocType === 'signatory') ? 1 : 2;
        this.router.navigate([`register/confirmed/${navigateBack}`]);
    }

    submitForm(): void {
        if (this.formHasErrors()) return;
        this.dataService.showHideLoader(true);
        this.registerService.updateUserWithSpoc(this.spocId, this.name, this.email, this.phone, this.spocType, this.countryCode)
        .subscribe(res => {
            this.dataService.showHideLoader(false);
            this.dataService.setUser(res.data);
            const navigateTo = (this.spocType === 'signatory') ? 3 : 4;
            this.router.navigate([`register/confirmed/${navigateTo}`]);
        }, err => {
            this.dataService.showHideLoader(false);
        });
    }

    formHasErrors(): boolean {
        let formHasError = false;
        this.nameErr = '';
        this.emailErr = '';
        this.phoneErr = '';
        if (!this.name) {
            this.nameErr = 'Please enter name';
            formHasError = true;
        }
        if (!this.email) {
            this.emailErr = 'Please enter email address';
            formHasError = true;
        } else if (this.email && !this.isEmailValid(this.email)) {
            this.emailErr = 'Please enter valid email address';
            formHasError = true;
        } else if (this.blockedDomains.indexOf(this.email.toLowerCase().split('@')[1]) >= 0) {
            this.emailErr = 'Public domains are not allowed';
            formHasError = true;
        }
        if (!this.phone) {
            this.phoneErr = 'Please enter phone number';
            formHasError = true;
        } else if (this.phone && !this.isPhoneNumberValid(this.phone) ) {
            this.phoneErr = 'Please enter valid phone number';
            formHasError = true;
        }
        return formHasError;
    }

    checkForError(type: string, e: any): void {
        if (e.which === 13) return;
        if (type === 'name') {
            if (!this.name) {
                this.nameErr = 'Please enter name';
            } else {
                this.nameErr = '';
            }
        } else if (type === 'email') {
            if (!this.email) {
                this.emailErr = 'Please enter email address';
            } else if (this.email && !this.isEmailValid(this.email)) {
                this.emailErr = 'Please enter valid email address';
            } else if (this.blockedDomains.indexOf(this.email.toLowerCase().split('@')[1]) >= 0) {
                this.emailErr = 'Public domains are not allowed';
            } else {
                this.emailErr = '';
            }
        } else if (type === 'phone') {
            if (!this.phone) {
                this.phoneErr = 'Please enter phone number';
            } else if (this.phone && !this.isPhoneNumberValid(this.phone) ) {
                this.phoneErr = 'Please enter valid phone number';
            } else {
                this.phoneErr = '';
            }
        }
    }

    private isPhoneNumberValid(phoneNumber: string): boolean {
        const phoneNoRegex = /^(\d+-?)+\d+$/;
        const isValid =  phoneNumber.match(phoneNoRegex) ? true : false;
        const numberWithoutHyphens = phoneNumber.split('-').join('');
        return (isValid && numberWithoutHyphens.length >= 5 && numberWithoutHyphens.length <= 10) ? true : false;
    }

    private isEmailValid(email: string): boolean {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email.toLowerCase());
    }

    onCheckboxChange(): void {
        if (this.sameAs) {
            this.name = this.signatorySpoc.name;
            this.email = this.signatorySpoc.email;
            const phoneInfo = this.dataService.splitPhoneNumber(this.signatorySpoc.phone);
            this.phone = phoneInfo.phoneNumber;
            this.countryCode = phoneInfo.countryCode;
            this.dataService.mixpanel_events_track('event', 'Same spoc');
        }
        this.formHasErrors();
    }

}
