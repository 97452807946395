import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { LoaderModule } from '../loader/loader.module';
import { AfterEmailConfirmationProcessModule } from './after-email-confirmation-process/after-email-confirmation-process.module';
import { RegisterComponent } from './register.component';
import { OtpComponent } from './otp/otp.component';
import { EmailPasswordComponent } from './email-password/email-password.component';
import { RegisterService } from './register.service';
import { UICarouselModule } from "ng-carousel-iuno";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule,
        AfterEmailConfirmationProcessModule,
        LoaderModule,
        UICarouselModule
    ],
    declarations: [
        RegisterComponent,
        OtpComponent,
        EmailPasswordComponent
    ],
    providers: [RegisterService]
})

export class RegisterModule { }
