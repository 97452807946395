
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DataService } from '../../shared/services/data.service';
import { HttpService } from '../../shared/services/http.service';

@Injectable()
export class DashboardService {
    
    constructor(private dataService: DataService, private httpService: HttpService) { }

    getRevenue(duration: number): Observable<any> {
        let url = `${this.dataService.getServerUrl()}vendor/dashboard/revenue`;
        if (duration) {
            url += `?duration=${duration}`;
        }
        return this.httpService.getRequest(url);
    }

    getVendorRating(): Observable<any> {
        const url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.vendor_rating}`;
        return this.httpService.getRequest(url);
    }

    getDemandSkills(currentMonth): Observable<any> {
        let url = `${this.dataService.getServerUrl()}${this.dataService.apiEndPoints.demand_skills}`;
        if (currentMonth){
            url += `?cycle=${currentMonth}`;
        }
        return this.httpService.getRequest(url);
    }

}
