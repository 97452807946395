import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'register',
    templateUrl: 'register.component.html',
    styleUrls: ['register.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class RegisterComponent {}
