import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { DataService } from '../shared/services/data.service';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
    selector: 'app.add-a-developer',
    templateUrl: 'add-a-developer.component.html'
})
export class AddADeveloper implements OnInit {
    public uniq_code;
    public emailErrorFromBackend: boolean = false;
    public isSuccessEmailAddition: boolean = false;
    public errorMessage: string = '';
    public successMessage: string = '';
    addDeveloperForm: FormGroup;
    disableSubmitBtn = false;

    constructor(
        public route: ActivatedRoute,
        private dataService: DataService,
        private analyticsSegment: Angulartics2Segment
    ) { }

    ngOnInit() {
        this.addDeveloperForm = new FormGroup({
            firstName: new FormControl(null, [Validators.required]),
            lastName: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email])
        });
        this.route.params.subscribe(
            (params: Params) => {
                this.uniq_code = params['id'];
            }
        );
        this.addDeveloperForm.statusChanges.subscribe(
            (status) => {
                if (this.isSuccessEmailAddition !== false) {
                    this.isSuccessEmailAddition = false;
                }
                if (status === 'INVALID' && this.emailErrorFromBackend !== false) {
                    this.emailErrorFromBackend = false;
                }
            }
        );
    }

    addADeveloper() {
        const postData = new FormData();
        postData.append('developer[first_name]', this.addDeveloperForm.get('firstName').value);
        postData.append('developer[last_name]', this.addDeveloperForm.get('lastName').value);
        postData.append('developer[email]', this.addDeveloperForm.get('email').value);
        this.disableSubmitBtn = true;
        const vendorUser = this.dataService.getUser();
        this.dataService.addDeveloper(postData, this.uniq_code).subscribe(res => {
            this.successMessage = res.message;
            this.analyticsSegment.eventTrack('Send Invites', {
                dev_invited: 1,
                email: vendorUser ? vendorUser.email : ''
            });
            this.setMixPanelEvents(res);
            this.addDeveloperForm.reset();
            this.isSuccessEmailAddition = true;
            this.dataService.showHideLoader(false);
            this.disableSubmitBtn = false;
        }, err => {
            const error = err.error;
            this.errorMessage = error.message;
            this.dataService.showHideLoader(false);
            this.emailErrorFromBackend = true;
            this.disableSubmitBtn = false;
        });
    }

    setMixPanelEvents(res: any): void {
        const vendorUser = this.dataService.getUser();
        this.analyticsSegment.setUserProperties({
            userId: vendorUser ? vendorUser.id : '',
            total_invited: res.statistics.total_invited,
            dev_count: res.statistics.developer_count
        });
    }
    
}
