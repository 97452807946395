import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as shape from 'd3-shape';
import { Angulartics2Segment } from 'angulartics2/segment';

import { PhaseService } from '../../shared/services/phase.service';
import { DataService } from '../../shared/services/data.service';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
    skillInDemandResponse: boolean = false;
    ChartResponse: boolean = false;
    userSkills = [];
    uiShown = '';
    revenueData;
    showXAxis = true;
    showYAxis = true;
    showLegend = false;
    colorScheme = {
        domain: ['#FAFAFA', '#7DB0C3']
    };
    multi = [];
    curve = shape.curveCatmullRom;
    series = [];
    filterSelected = 0;
    // hotSkills = [];
    showLoader = false;
    type = 'line';
    labels;
    data2;
    data;
    options;
    total_revenue;
    last_month_revenue;
    avg_monthly_revenue;
    showRatingMembers = false;
    vendorRatingData;
    demandSkills;
    currentMonth:string = "";
    demandSkillsName = [];
    currentInvoiceCycle:number = 1;
    nextButtonDisabled:boolean = false;
    prevButtonDisabled:boolean = false;
    skillsInDemand = [];
    totalAnimationTime = 690; // 1000 means "1 second"
    animationDelayTime: number;
    dataService: any;


    chartStrokeColor= ["#016CE9", "#2086FD", "#66ADFF", "#BEDCFF", "#DAEBFF", "#EEF6FF"];
    strokeDashArray(d: any) {
        return `${d} ${(100 - d)}`;
    }
    strokeColor(i: number) {
        return `${this.chartStrokeColor[i]}`;
    }
    circleRadius(d: any, i: number) {
        return 13.4 + this.strokeWidth(d, i) / 2;
    }
    strokeWidth(d: any, i: number) {
        return 5 - (i * 0.85);
    }
    strokeDashOffset(d: any, index: number) {
        let offset = 0;
        for (let i = 0; i < index; i++) {
            offset += this.demandSkillsName[i].value;
        }
        const r = this.circleRadius(d, index);
        const p = 2 * 3.14 * r;
        const o = (p * offset) / 100.0;
        return (((p * 25) / 100) - o);
    }

    constructor(
        dataService: DataService,
        private router: Router,
        private phaseService: PhaseService,
        private dashboardService: DashboardService,
        private analyticsSegment: Angulartics2Segment
    ) {
        this.dataService = dataService;
        this.phaseService.leftMenuActive = 'dashboard';
        if (!this.dataService.getUser()) {
            this.router.navigate([this.dataService.firstPage]);
        }
    }

    ngOnInit(): void {
        this.getAndRenderRevenue();
        this.dataService.mixpanel_events_track('event', 'Dashboard view');
        // this.getHotSkills();
        this.getVendorRating();
        this.dataService.intercomShowHide('deactivate');
        this.getDemandSkills(this.currentMonth);
    }

    getVendorRating(): void {
        this.dashboardService.getVendorRating()
        .subscribe(res => {
            this.vendorRatingData = res.data;
            this.vendorRatingData.acceptanceRatePercentage = Math.abs(this.vendorRatingData.acceptance_rate_percentage);
            if (!this.vendorRatingData.acceptance_rate) {
                this.vendorRatingData.acceptance_rate = 0;
            }
        }, err => {
            this.vendorRatingData = '';
        })
    }

    getAndRenderRevenue(): void {
        this.dataService.showHideLoader(true);
        this.dashboardService.getRevenue(this.filterSelected)
        .subscribe(res => {
            this.ChartResponse = true;
            this.revenueData = res.data;
            if(this.revenueData.currency_symbol === '$'){
                this.total_revenue = this.usdConversion(this.revenueData.total_revenue);
                this.last_month_revenue = this.usdConversion(this.revenueData.last_month_revenue);
                this.avg_monthly_revenue = this.usdConversion(this.revenueData.avg_monthly_revenue);
            } else{
                this.total_revenue = this.inrConversion(this.revenueData.total_revenue);
                this.last_month_revenue = this.inrConversion(this.revenueData.last_month_revenue);
                this.avg_monthly_revenue = this.inrConversion(this.revenueData.avg_monthly_revenue);
            }
            const series = [];
            const xseries = [];
            for (let i in this.revenueData.chart) {
                series.push(this.revenueData.chart[i]);
                xseries.push(i);
            }

            this.revenueData.lastMonthPercentage = Math.abs(this.revenueData.last_month_percentage);
            this.revenueData.avgMonthPercentage = Math.abs(this.revenueData.avg_monthly_percentage);
            // this.multi = [...this.multi];
            // this.multi = [{
            //     name: 'Payments',
            //     series: series
            // }];
            this.data = {
                labels: xseries,
                datasets: [
                  {
                    label: "Payments",
                    data: series,
                    backgroundColor: [
                        'rgba(32, 134, 253, .5)',
                        'rgba(250,250,250,1)'
                    ],
                    borderWidth: 2,
                    borderColor: 'rgb(32, 134, 253)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 0,
                    pointBorderWidth: 2,
                    pointBorderColor: 'rgb(32, 134, 253)',
                    pointBackgroundColor: 'rgb(225,244,251)',
                    pointHoverBackgroundColor: 'rgb(225,244,251)',
                    pointHoverBorderColor: 'rgb(32, 134, 253)',
                    currenycode: this.revenueData.currency_symbol
                  }
                ],
              };
              this.options= {
                maintainAspectRatio: false,
                                    legend: {
                                        display: false
                                      },
                                      scales: {
                                        xAxes: [{
                                          display: true
                                        }],
                                        yAxes: [{
                                          display: true,
                                        }],
                                      },
                                      hover: {
                                        intersect: false
                                      },
                                      tooltips: {
                                        callbacks: {
                                            label: (tooltipItem, data) => {
                                                let cc = data.datasets[0]['currenycode'];
                                                if(cc === '$'){
                                                    return this.usdConversion(tooltipItem.yLabel);
                                                } else{
                                                    return this.inrConversion(tooltipItem.yLabel);
                                                }
                                            }
                                        }
                                    }

                                };

            this.uiShown = 'chart';
            if(this.skillInDemandResponse) {this.dataService.showHideLoader(false);}
        }, err => {
            this.ChartResponse = true;
            this.uiShown = 'no-chart';
            if(this.skillInDemandResponse) {this.dataService.showHideLoader(false);}
        });
    }

    usdConversion(value){
        let thousand = 1000;
        let million = 1000000;
        let billion = 1000000000;
        let trillion = 1000000000000;
        let convertedVal;
        let identifier='';
        if (value < thousand) {
            convertedVal = value;
        }
        else if (value >= thousand && value <= 1000000) {
            convertedVal = Math.abs(Number(value/thousand));
            identifier = 'k';
        }
        else if (value >= million && value <= billion) {
            convertedVal = Math.abs(Number(value/million));
            identifier = 'M';
        }
        else if (value >= billion && value <= trillion) {
            convertedVal = Math.abs(Number(value/billion));
            identifier = 'B';
        }
        else {
            convertedVal = Math.abs(Number(value/trillion));
            identifier = 'T';
        }
        let convertedArr = convertedVal.toFixed(1).split('.');
        return convertedArr[1]==0 ? convertedArr[0]+identifier : convertedVal.toFixed(1)+identifier;
    }

    inrConversion(val){
        let convertedVal=val;
        let identifier='';
        if(val >= 10000000) {
            convertedVal = (val/10000000);
            identifier = ' Cr';
        }
        else if(val >= 100000) {
            convertedVal = (val/100000);
            identifier = ' Lac';
        }
        else if(val >= 1000) {
            convertedVal = (val/1000);
            identifier = 'k';
        }
        let convertedArr = convertedVal.toFixed(1).split('.');
        return convertedArr[1]==0 ? convertedArr[0]+identifier : convertedVal.toFixed(1)+identifier;
    }

    // getHotSkills(): void {
    //     this.dataService.showHideLoader(true);
    //     this.dashboardService.getHotSkills()
    //     .subscribe(res => {
    //         this.hotSkills = res.data;
    //         this.dataService.showHideLoader(false);
    //     }, err => {
    //         this.dataService.showHideLoader(false);
    //     });
    // }

    getDemandSkills(currentMonth): void {
        this.dataService.showHideLoader(true);
        this.dashboardService.getDemandSkills(currentMonth)
        .subscribe(res => {
            this.skillInDemandResponse = true;
            if(this.demandSkills){
                this.demandSkills.monthly_skill_data = {...res.data.monthly_skill_data, ...this.demandSkills.monthly_skill_data};
            }else{
                this.demandSkills = res.data;
            }
            if(this.ChartResponse){this.dataService.showHideLoader(false);}
            this.updateMonthData(this.currentInvoiceCycle);
        }, err => {
            if(this.ChartResponse){this.dataService.showHideLoader(false);}
        });
    }

    updateMonthData(lengthValue:number){
        if(this.demandSkills.invoice_cycles.length <= lengthValue){
            this.prevButtonDisabled = true;
        }else if(lengthValue <= 1){
            this.nextButtonDisabled = true;
        }
        if((this.demandSkills.invoice_cycles.length > lengthValue) && (lengthValue > 1)){
            this.nextButtonDisabled = false;
            this.prevButtonDisabled = false;
        }

        this.currentMonth = this.demandSkills.invoice_cycles[this.demandSkills.invoice_cycles.length - lengthValue];
        let currentMonthData = this.demandSkills.monthly_skill_data[this.currentMonth];
        if(!currentMonthData){
            this.getDemandSkills(this.currentMonth);
            return;
        }
        this.demandSkillsName = [];
        for(let monthlyData in  currentMonthData){
            this.demandSkillsName.push({name: monthlyData, value: currentMonthData[monthlyData]});
        }
        this.skillsInDemand = [];
        this.animationDelayTime = 0;
        for(let i=0 ; i < this.demandSkillsName.length; i++){
            let demandSkill = this.demandSkillsName[i].value;
            this.skillsInDemand.push({
                dashArray: this.strokeDashArray(demandSkill),
                color: this.strokeColor(i),
                radius: this.circleRadius(demandSkill, i),
                width: this.strokeWidth(demandSkill, i),
                dashOffset: this.strokeDashOffset(demandSkill, i),
                animationTime: this.animationTime(demandSkill),
                animationDelay: this.animationDelay(i, this.demandSkillsName[(i == 0) ? 0 : (i - 1)].value)
            });
        }
        this.skillsInDemand.reverse();
    }

    animationTime(value){
        let animationTime = (this.totalAnimationTime * value)/100;
        return animationTime;
    }

    animationDelay(index, value){
        this.animationDelayTime = (this.animationDelayTime + (this.totalAnimationTime * value)/100);
        if(index == 0){
            this.animationDelayTime = 0;
        }
        return this.animationDelayTime;
    }

    prevSkill(){
        this.dataService.mixpanel_events_track('event', 'Skill demand month');
        this.updateMonthData(this.currentInvoiceCycle += 1);
    }
    nextSkill(){
        this.dataService.mixpanel_events_track('event', 'Skill demand month');
        this.updateMonthData(this.currentInvoiceCycle -= 1);
    }

    filterRevenue(month: number): void {
        this.dataService.mixpanel_events_track('event', 'Earning monthly');
        this.filterSelected = month;
        this.getAndRenderRevenue();
    }

    openInviteMembers(): void {
        this.dataService.showInviteMembers = true;
        this.dataService.mixpanel_invite_members("hotskills");
        setTimeout(() => {
            this.dataService.classInviteMembers = true;
        }, 500);
    }

    flagAnalytics(): void {
        this.dataService.mixpanel_events_track('event', 'Flag Tooltip');
    }

    accptncAnalytics(): void{
        this.dataService.mixpanel_events_track('event', 'Accprate Tooltip');
    }

}
