import { Component, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../shared/services/data.service';
import { PhaseService } from '../shared/services/phase.service';

@Component({
    selector: 'loader',
    templateUrl: 'loader.component.html'
})

export class LoaderComponent {
    dataService: any;
    constructor(
        dataService: DataService,
        private phaseService: PhaseService,
        router: Router,
        private cdRef: ChangeDetectorRef
    ) { 
        this.dataService = dataService;
    }

}
