import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../../shared/shared.module';
import { LoaderModule } from '../../loader/loader.module';
import { AfterEmailConfirmationProcessComponent } from './after-email-confirmation-process.component';
import { OrganizationComponent } from './organization/organization.component';
import { SpocDetailsComponent } from './spoc-details/spoc-details.component';
import { TeamStructureComponent } from './team-structure/team-structure.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TagInputModule, 
        BrowserAnimationsModule,
        SharedModule,
        LoaderModule
    ],
    declarations: [
        AfterEmailConfirmationProcessComponent,
        OrganizationComponent,
        SpocDetailsComponent,
        TeamStructureComponent
    ]
})

export class AfterEmailConfirmationProcessModule { }
