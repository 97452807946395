import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TutorialService } from './tutorial.service';
import { DataService } from '../shared/services/data.service';

@Component({
    selector: 'tutorial',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss']
})

export class TutorialComponent implements OnInit {
    @Input() imageUrl: string;
    @Input() headerText: string;
    @Input() bodyText: string;
    @Input() buttons: string[];
    @Input() fromScreen: any;
    @Output() animationDone: EventEmitter<any> = new EventEmitter<any>();
    @Output() onGotItOver: EventEmitter<any> = new EventEmitter<any>();
    showCloseOptions = false;
    activateAnimation = false;

    constructor(
        private tutorialService: TutorialService,
        private dataService: DataService
    ) { }

    ngOnInit() {
        setTimeout(() => {
            this.activateAnimation = true;
            if(this.animationDone)
            {
                this.animationDone.emit();
            }
        }, 200);
    }

    closeOptions(){
        this.showCloseOptions = true;
    }

    hideCloseOptions(){
        this.showCloseOptions = false;
    }

    gotIt(): void {
        let vendorUser = this.dataService.getUser();
        this.activateAnimation = false;
        setTimeout(() => {
            vendorUser.tutorials.push(this.fromScreen.screenName + this.fromScreen.popupName);
            if(this.animationDone)
            {
                this.animationDone.emit();
            }
            this.onGotItOver.emit();
            this.tutorialService.gotIt(vendorUser.tutorials)
            .subscribe(res => {
            }, err => {
                console.log(err);
                this.activateAnimation = true;
            });
        }, 300);
    }

}
